export default (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12 7.95391 10.599 9.58061 8.74681 9.93041C8.74892 9.95332 8.75 9.97653 8.75 10V11.25H10C10.4142 11.25 10.75 11.5858 10.75 12C10.75 12.4142 10.4142 12.75 10 12.75H8.75V14C8.75 14.4142 8.41421 14.75 8 14.75C7.58579 14.75 7.25 14.4142 7.25 14V12.75H6C5.58579 12.75 5.25 12.4142 5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H7.25V10C7.25 9.97653 7.25108 9.95332 7.25319 9.93041C5.40096 9.58061 4 7.95391 4 6C4 3.79086 5.79086 2 8 2C10.2091 2 12 3.79086 12 6ZM8 8.5C9.38071 8.5 10.5 7.38071 10.5 6C10.5 4.61929 9.38071 3.5 8 3.5C6.61929 3.5 5.5 4.61929 5.5 6C5.5 7.38071 6.61929 8.5 8 8.5Z"
        fill="#201552"
      />
    </svg>
  );
};
