export default (props: any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 3.38601C13.3334 2.98891 13.0115 2.66699 12.6144 2.66699H12.5647H9.38577C8.98867 2.66699 8.66675 2.98891 8.66675 3.38601C8.66675 3.78312 8.98867 4.10504 9.38577 4.10504L10.8082 4.10504L9.12208 5.79119C9.10507 5.80819 9.08903 5.8258 9.07395 5.84394C7.47802 4.75503 5.28369 4.91864 3.86757 6.33476C2.26647 7.93585 2.26647 10.5317 3.86757 12.1328C5.46867 13.7339 8.06455 13.7339 9.66565 12.1328C11.0818 10.7167 11.2454 8.52239 10.1565 6.92646C10.1746 6.91138 10.1922 6.89533 10.2092 6.87833L11.8954 5.19217V6.61464C11.8954 7.01174 12.2173 7.33366 12.6144 7.33366C13.0115 7.33366 13.3334 7.01174 13.3334 6.61464V3.38601ZM4.95471 7.4219C5.95539 6.42121 7.57783 6.42121 8.57851 7.4219C9.5792 8.42258 9.5792 10.045 8.57851 11.0457C7.57783 12.0464 5.95539 12.0464 4.95471 11.0457C3.95402 10.045 3.95402 8.42258 4.95471 7.4219Z"
        fill="#201552"
      />
    </svg>
  );
};
