export default (props: any) => (
  <svg
    width="175"
    height="118"
    viewBox="0 0 175 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2369_24741)">
      <path
        d="M149.608 0.5H83.8924C51.5838 0.5 25.3925 26.6913 25.3925 58.9999V117.5H91.1079C123.417 117.5 149.608 91.3085 149.608 58.9999V0.5Z"
        fill="white"
      />
      <path
        d="M130.823 28.5898L129.052 33.3594C128.967 33.5268 128.883 33.6104 128.714 33.6941L125.088 34.6982C124.666 34.8656 124.582 35.4513 125.088 35.6187L129.052 37.1249C129.22 37.2086 129.305 37.2923 129.305 37.3759L130.992 42.0619C131.16 42.4803 131.835 42.4803 131.919 41.9782L133.016 37.0412C133.016 36.8739 133.184 36.7065 133.353 36.7065L137.064 35.4513C137.486 35.284 137.486 34.6146 137.064 34.5309L133.522 33.5268C133.353 33.4431 133.269 33.3594 133.184 33.192L131.666 28.5061C131.666 28.1714 130.992 28.1714 130.823 28.5898Z"
        fill="#90A4AE"
      />
      <circle cx="118.109" cy="20.0237" r="2.74148" fill="#E0E0E0" />
      <circle cx="132.423" cy="55.0923" r="1.3638" fill="#E0E0E0" />
      <rect
        width="4.1192"
        height="4.1192"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 37.3843 109.259)"
        fill="#E0E0E0"
      />
      <path
        d="M55.7383 95.9553V52.7719C55.7383 51.0862 57.1048 49.7197 58.7905 49.7197H67.8731C68.7884 49.7197 69.677 50.0283 70.3954 50.5956L72.2619 52.0698C73.3267 52.9107 74.6413 53.3729 75.9981 53.3834L113.153 53.6709L111.612 95.9553H55.7383Z"
        fill="#546E7A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.357 16.0577L97.7555 23.6776C97.556 23.5571 97.3542 23.4404 97.1501 23.3273L101.463 15.5405C101.764 15.7075 102.062 15.8799 102.357 16.0577ZM88.6218 12.1044L88.458 21.0042C88.3402 21.002 88.222 21.0009 88.1035 21.0009C87.985 21.0009 87.8668 21.002 87.749 21.0042L87.5852 12.1044C87.7576 12.1012 87.9304 12.0996 88.1035 12.0996C88.2766 12.0996 88.4494 12.1012 88.6218 12.1044ZM74.7442 15.5405L79.0569 23.3273C78.8528 23.4404 78.651 23.5571 78.4515 23.6776L73.8504 16.0577C74.1448 15.8799 74.4428 15.7075 74.7442 15.5405ZM64.4483 25.4598L72.0682 30.0609C71.9478 30.2604 71.831 30.4622 71.7179 30.6663L63.9312 26.3536C64.0981 26.0522 64.2705 25.7542 64.4483 25.4598ZM60.495 39.1946C60.4918 39.367 60.4902 39.5397 60.4902 39.7129C60.4902 39.886 60.4918 40.0588 60.495 40.2311L69.3948 40.0673C69.3926 39.9495 69.3916 39.8314 69.3916 39.7129C69.3916 39.5944 69.3926 39.4762 69.3948 39.3584L60.495 39.1946ZM87.5852 67.3214L87.749 58.4216C87.8668 58.4237 87.985 58.4248 88.1035 58.4248C88.222 58.4248 88.3402 58.4237 88.458 58.4216L88.6218 67.3214C88.4494 67.3245 88.2766 67.3261 88.1035 67.3261C87.9304 67.3261 87.7576 67.3245 87.5852 67.3214ZM101.463 63.8852L97.1501 56.0984C97.3542 55.9854 97.556 55.8686 97.7555 55.7482L102.357 63.3681C102.062 63.5458 101.764 63.7183 101.463 63.8852ZM111.759 53.966L104.139 49.3648C104.259 49.1654 104.376 48.9635 104.489 48.7594L112.276 53.0722C112.109 53.3736 111.936 53.6716 111.759 53.966Z"
        fill="#90A4AE"
      />
      <path
        d="M82.3091 32.9531H109.069L115.484 39.7339V79.398H82.3091V32.9531Z"
        fill="#ECEFF1"
      />
      <path
        d="M115.486 39.7123H109.066L109.066 32.9531L115.486 39.7123Z"
        fill="#E0E0E0"
      />
      <path
        d="M55.7383 95.955L65.0558 62.8418L124.349 62.8418L111.642 95.955H55.7383Z"
        fill="#90A4AE"
      />
      <path
        d="M95.5439 41.4443H102.325L101.022 53.9397H96.8463L95.5439 41.4443Z"
        fill="#90A4AE"
        fillOpacity="0.6"
      />
      <rect
        x="96.7715"
        y="55.4658"
        width="4.2505"
        height="4.2505"
        fill="#90A4AE"
        fillOpacity="0.6"
      />
      <path
        d="M43.2719 98.7406C43.392 99.0386 43.7524 99.0982 43.9326 98.8002L44.8935 97.3105L46.2749 98.4427C46.5151 98.6214 46.8755 98.4427 46.8755 98.1447V96.3571L48.6172 96.5358C48.9175 96.5954 49.1578 96.2379 48.9776 95.9995L48.0166 94.5098L49.6382 93.7351C49.9385 93.616 49.8785 93.1988 49.6382 93.0796L48.0166 92.3646L48.9175 90.8748C49.0977 90.6365 48.8575 90.279 48.5572 90.3385L46.8154 90.5769L46.7554 88.8488C46.7554 88.5509 46.395 88.3721 46.1548 88.6105L45.1338 89.5043C44.9536 89.6235 44.7133 89.6235 44.5932 89.4447L43.8124 88.3125C43.6323 88.0742 43.2719 88.1337 43.1518 88.4317L42.6713 90.1002L41.0497 89.3851C40.7494 89.2659 40.4491 89.5043 40.5692 89.8022L41.1098 91.4707L39.368 91.7687C39.0677 91.8283 38.9476 92.1858 39.1879 92.3646L40.5092 93.4968L39.1879 94.6886C38.9476 94.8673 39.0677 95.2844 39.368 95.2844L41.1098 95.5228L40.6293 97.2509C40.5692 97.5488 40.8695 97.7872 41.1098 97.668L42.7314 96.9529L43.2719 98.7406Z"
        fill="#90A4AE"
      />
    </g>
    <defs>
      <clipPath id="clip0_2369_24741">
        <rect
          width="174.482"
          height="117"
          fill="white"
          transform="translate(0.258789 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
