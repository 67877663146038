import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { MediQuoAPI } from '../../api';
import MediQuoLogo from '../../assets/images/AppLogo';
import { createSession } from '../../utils/useSession';
import TrackerSdk from '../../utils/TrackingSdk';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../i18n/namespaces';

const Login = ({ type }: { type: string }) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { id, apiKey, type: appointmentType } = useParams();
  const [retried, setRetried] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(Namespace.Login);

  useEffect(() => {
    TrackerSdk.pageView('access your consultation pageview');
  }, []);

  const onSubmit = ({ code }: any) => {
    TrackerSdk.event('access your consultation validate button click');

    MediQuoAPI.authenticate({ id: id!, apiKey: apiKey!, code, type })
      .then(({ data }: any) => {
        if (type === 'appointments') {
          navigate(`/${appointmentType}/${id}/${apiKey}/${data.access_token}`);
        } else {
          createSession({ id: id!, accessToken: data.access_token });
          navigate(0);
        }
      })
      .catch(() => {
        TrackerSdk.pageView('access your consultation error pageview');
        setError('code', { type: 'custom' });
      });
  };

  const onRetry = () =>
    MediQuoAPI.forgotCode({ id: id!, apiKey: apiKey! }).then(() =>
      setRetried(true),
    );

  return (
    <div className="bg-background">
      <div className="max-h-screen h-screen px-6 grid content-center max-w-[450px] mx-auto">
        <MediQuoLogo />
        <h1 className="text-3xl mt-8 mb-4 text-title text-center sm:text-start font-medium">
          {t('title')}
        </h1>
        <p className="text-lg text-text text-center sm:text-start">
          {t('code_sent')}
        </p>
        <form className="flex flex-col my-8" onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register('code', { required: true })}
            required
            maxLength={6}
            className={classNames(
              'text-xl p-4 border text-center rounded-lg bg-gray-light focus:outline-primary',
              !!errors.code ? 'border-negative' : 'border-transparent',
            )}
            placeholder={t('code_placeholder')}
            type="text"
          />
          {errors.code && (
            <div className="text-center text-xs mt-1">
              <p className="text-negative">{t('code_invalid')}</p>
            </div>
          )}
          <button
            type="submit"
            className="mt-4 p-4 rounded-lg bg-primary font-medium text-white uppercase tracking-widest hover:bg-primary-dark"
          >
            {t('submit')}
          </button>
        </form>
        <p className="text-center text-text">
          {retried ? (
            <>{t('code_resent')}</>
          ) : (
            <>
              {t('code_resend_header')}{' '}
              <button onClick={onRetry} className="text-title font-medium">
                {t('code_resend')}
              </button>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default Login;
