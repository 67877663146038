import { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';

export default (callStartedAt) => {
  const intervalTimerRef = useRef();
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!intervalTimerRef?.current) {
      if (callStartedAt) {
        const diff = dayjs().diff(dayjs(callStartedAt), 'second');
        setSeconds(diff);
      }
      intervalTimerRef.current = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }
    return () => {
      intervalTimerRef.current && clearInterval(intervalTimerRef.current);
    };
  }, [callStartedAt]);

  return seconds;
};
