import { useAppointment } from '../../../containers/Appointment/useAppointment';
import { AppointmentStates, DocumentationStates } from '../../../utils/states';
import Loading from '../../Loading';
import ErrorView from '../../Error';
import DocumentationContainer from '../../../containers/Appointment/Documentation';
import getAppointmentState from '../../../utils/getAppointmentState';
import getDocumentationState from '../../../utils/getDocumentationState';
import useWidget from '../../../containers/VideoCall/useWidget';

function Documentation() {
  const data = useAppointment();
  const { toggleChat } = useWidget();

  switch (getAppointmentState(data)) {
    case AppointmentStates.errorNotAvailable:
    case AppointmentStates.errorExpired:
    case AppointmentStates.errorGeneric:
      const { error }: { error: any } = data;
      const state =
        error?.status === 400
          ? 'notAvailable'
          : error?.status === 401
            ? 'expired'
            : 'generic';
      return <ErrorView state={state} />;
    case AppointmentStates.loading:
      return <Loading />;
    case AppointmentStates.reporting:
      return (
        <DocumentationContainer
          state={AppointmentStates.reporting}
          data={data}
          toggleChat={toggleChat}
          documents={[]}
        />
      );
    case AppointmentStates.finished:
      switch (getDocumentationState(data.documents)) {
        case DocumentationStates.loading:
          return <Loading />;
        case DocumentationStates.empty:
          return (
            <DocumentationContainer
              documents={data.documents}
              state={DocumentationStates.empty}
              data={data}
              toggleChat={toggleChat}
            />
          );
        case DocumentationStates.completed:
          return (
            <DocumentationContainer
              documents={data.documents}
              state={DocumentationStates.completed}
              data={data}
              toggleChat={toggleChat}
            />
          );
      }

    default:
      return <ErrorView />;
  }
}

export default Documentation;
