import { useTranslation } from 'react-i18next';
import AppLogo from '../../assets/images/AppLogo';
import { Namespace } from '../../i18n/namespaces';

const Expired = ({ retry }: any) => {
  const { t } = useTranslation(Namespace.Consultation);

  return (
    <div className="bg-background">
      <div className="relative max-h-screen h-screen px-6 grid content-center max-w-[800px] mx-auto">
        <div>
          <AppLogo />
          <h1 className="text-3xl mt-0 mb-4 text-title text-center sm:text-start">
            {t('expired.title')}
          </h1>
          <p className="text-lg mb-12 text-text text-center sm:text-start">
            {t('expired.subtitle')}
          </p>
          <button
            onClick={retry}
            type="submit"
            className="mt-4 p-4 rounded-lg bg-primary text-white uppercase tracking-widest hover:bg-primary-dark justify-center flex m-auto sm:ml-0"
          >
            {t('expired.button')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Expired;
