export default (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.667 9.33341V19.3334C12.667 19.7754 12.8426 20.1994 13.1551 20.5119C13.4677 20.8245 13.8916 21.0001 14.3337 21.0001H21.0003C21.4424 21.0001 21.8663 20.8245 22.1788 20.5119C22.4914 20.1994 22.667 19.7754 22.667 19.3334V12.0351C22.667 11.813 22.6226 11.5933 22.5364 11.3886C22.4503 11.184 22.3241 10.9986 22.1653 10.8434L19.4028 8.14175C19.0915 7.8373 18.6733 7.6668 18.2378 7.66675H14.3337C13.8916 7.66675 13.4677 7.84234 13.1551 8.1549C12.8426 8.46746 12.667 8.89139 12.667 9.33341V9.33341Z"
      stroke="#546E7A"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3335 21.0002V22.6668C19.3335 23.1089 19.1579 23.5328 18.8453 23.8453C18.5328 24.1579 18.1089 24.3335 17.6668 24.3335H11.0002C10.5581 24.3335 10.1342 24.1579 9.82165 23.8453C9.50909 23.5328 9.3335 23.1089 9.3335 22.6668V13.5002C9.3335 13.0581 9.50909 12.6342 9.82165 12.3217C10.1342 12.0091 10.5581 11.8335 11.0002 11.8335H12.6668"
      stroke="#546E7A"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
