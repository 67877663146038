import classNames from 'classnames';
import ChatBubble from '../assets/icons/ChatBubble';
import Mic from '../assets/icons/Mic';
import MicMuted from '../assets/icons/MicMuted';
import Video from '../assets/icons/Video';
import VideoMuted from '../assets/icons/VideoMuted';

const CallControls = ({
  isVideoEnabled,
  isAudioEnabled,
  isPendingMessage,
  onAudioToggle,
  onVideoToggle,
  onChatToggle,
  isInVideoCall,
  isStreamReady,
}: any) => {
  return (
    <div
      className={classNames(
        'absolute max-w-full left-0  w-full flex justify-center gap-5 text-white z-10',
        {
          'bottom-6': !isInVideoCall,
          'bottom-16': isInVideoCall,
        },
      )}
    >
      {onChatToggle && isInVideoCall && (
        <Control
          onClick={onChatToggle}
          className="bg-white text-dark"
          isStreamReady
        >
          <ChatBubble className="w-6 h-6" />
          {isPendingMessage && <Badge />}
        </Control>
      )}
      <Control
        onClick={onAudioToggle}
        className={classNames(isStreamReady ? 'bg-white' : 'bg-white/70', {
          'text-gray-dark': !isStreamReady,
          'text-dark': isAudioEnabled,
          'text-negative': !isAudioEnabled,
        })}
        isStreamReady={isStreamReady}
      >
        {isAudioEnabled ? (
          <Mic className="w-6 h-6" />
        ) : (
          <MicMuted className="w-6 h-6" />
        )}
      </Control>
      <Control
        onClick={onVideoToggle}
        className={classNames(isStreamReady ? 'bg-white' : 'bg-white/70', {
          'text-gray-dark': !isStreamReady,
          'text-dark': isVideoEnabled,
          'text-negative ': !isVideoEnabled,
        })}
        isStreamReady={isStreamReady}
      >
        {isVideoEnabled ? (
          <Video className="w-6 h-6" />
        ) : (
          <VideoMuted className="w-6 h-6" />
        )}
      </Control>
    </div>
  );
};

const Control = ({ children, onClick, className, isStreamReady }: any) => (
  <button
    onClick={onClick}
    className={classNames(
      'w-10 h-10 rounded-xl flex items-center justify-center',
      { 'bg-white text-black': !className },
      className,
    )}
    disabled={!isStreamReady}
  >
    {children}
  </button>
);

const Badge = () => (
  <span
    className={classNames(
      'box-content absolute flex items-center justify-center h-3 rounded-full w-3 bg-[#3C50EC] ml-8 mb-9',
    )}
  ></span>
);

export default CallControls;
