import DoublePaper from '../../../assets/icons/DoublePaper';
import Safari from '../../../assets/icons/Safari';
import Chrome from '../../../assets/icons/Chrome';
import HeaderView from '../../../components/HeaderView';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../i18n/namespaces';

function Browser({ isIOS, onClick }: any) {
  const { type } = useParams();
  const { t } = useTranslation(Namespace.Appointment);

  return (
    <div className="bg-background">
      <div className="relative max-h-screen h-screen px-6 max-w-[800px] mx-auto">
        <HeaderView
          title={t('browser.title')}
          subtitle={t('browser.subtitle')}
          isWithLineSeparator={false}
          showLogo={type !== 'i'}
          withLanguageSelector
        />
        <div className="flex flex-col min-w-0">
          <div className="flex flex-col bg-bg gap-2 justify-center center p-4 rounded-lg my-4">
            <div className="flex flex-row center ">
              <div className="flex rounded-full bg-secundary font-medium w-6 h-6 justify-center center">
                <p className="text-primary font-lighter">1</p>
              </div>
              <p className="text-dark font-lighter pl-2 mb-2">
                {t('browser.steps.one')}
              </p>
            </div>

            <button
              onClick={() =>
                navigator.clipboard.writeText(window.location.href)
              }
              className="flex flex-row center rounded-lg bg-white text-gray-dark justify-between p-3 grow"
            >
              <p className="min-w-0 mr-4 truncate font-lighter">
                {window.location.hostname + window.location.pathname}
              </p>

              <DoublePaper className="min-w-fit" />
            </button>
          </div>
          <div className="flex flex-col bg-bg gap-2 justify-center center p-4">
            <div className="flex flex-row center">
              <div className="flex rounded-full bg-secundary font-medium w-6 h-6 justify-center center">
                <p className="text-primary font-lighter">2</p>
              </div>
              <p className="text-dark font-lighter pl-2 mb-2">
                {t('browser.steps.two')}
              </p>
            </div>

            <div className="flex flex-row gap-2 center justify-center">
              {isIOS && (
                <div className=" bg-white rounded-lg py-3 flex flex-col justify-center center grow">
                  <Safari className="m-auto mb-2" />
                  <p className="text-xs uppercase m-auto text-gray-dark">
                    {t('browser.safari')}
                  </p>
                </div>
              )}
              <div className="bg-white rounded-lg py-3 flex flex-col justify-center center grow">
                <Chrome className="m-auto mb-2" />
                <p className="text-xs uppercase m-auto text-gray-dark">
                  {t('browser.chrome')}
                </p>
              </div>
            </div>
          </div>
          <button
            className="text-gray-dark underline-offset-4 font-normal m-7 text-xs p-5"
            style={{ textDecoration: 'underline' }}
            onClick={onClick}
          >
            {t('browser.continue')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Browser;
