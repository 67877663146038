export default (props: any) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.9997 25C22.7663 25 24.9997 22.7667 24.9997 20V10C24.9997 7.23333 22.7663 5 19.9997 5C17.233 5 14.9997 7.23333 14.9997 10V20C14.9997 22.7667 17.233 25 19.9997 25ZM18.333 10C18.333 9.08333 19.083 8.33333 19.9997 8.33333C20.9163 8.33333 21.6663 9.08333 21.6663 10V20C21.6663 20.9167 20.9163 21.6667 19.9997 21.6667C19.083 21.6667 18.333 20.9167 18.333 20V10ZM29.8497 20C29.033 20 28.3497 20.6 28.2163 21.4167C27.533 25.3333 24.1163 28.3333 19.9997 28.3333C15.883 28.3333 12.4663 25.3333 11.783 21.4167C11.6497 20.6 10.9663 20 10.1497 20C9.13299 20 8.33299 20.9 8.48299 21.9C9.29965 26.9 13.2997 30.8167 18.333 31.5333V35C18.333 35.9167 19.083 36.6667 19.9997 36.6667C20.9163 36.6667 21.6663 35.9167 21.6663 35V31.5333C26.6997 30.8167 30.6997 26.9 31.5163 21.9C31.683 20.9 30.8663 20 29.8497 20Z"
      fill="currentColor"
    />
  </svg>
);
