import AppLogo from '../../../assets/images/AppLogo';
import Button from '../../../components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppointment } from '../useAppointment';
import ClockIcon from '../../../assets/icons/Clock';
import Clock from '../../../assets/illustrations/Clock';
import { MediQuoAPI } from '../../../api';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../i18n/namespaces';

type TriageData = {
  estimated_time: number;
  position: number;
};

dayjs.extend(duration);

function TriageContainer() {
  const { id, type, apiKey, jwt } = useParams();
  const {
    appointment,
    cancelVideoCall,
  }: { appointment: any; cancelVideoCall: () => void } = useAppointment();
  const navigate = useNavigate();
  const [triageData, setTriageData] = useState<TriageData | any>({});
  const { t } = useTranslation(Namespace.Appointment);

  useEffect(() => {
    getPosition();
    const interval = setInterval(() => {
      if (!appointment?.from) {
        if (id && apiKey && jwt)
          MediQuoAPI.appointments.get({ id, apiKey, jwt }).then(({ data }) => {
            if (data?.data?.from) {
              navigate(0);
            }
          });
        getPosition();
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [appointment]);

  const getPosition = () => {
    if (id && apiKey && jwt)
      MediQuoAPI.consultations
        .triage({ id, apiKey, jwt })
        .then(({ data }: any) => {
          if (data) {
            setTriageData({
              position: data.data.position,
              estimated_time: dayjs
                .duration(data.data?.estimated_time * 1000)
                .format('m'),
            });
          }
        });
  };

  useEffect(() => {
    if (type === 'i') {
      if (triageData?.position - 1 > 0) {
        window.parent.postMessage({ command: 'videocall_triage_waiting' }, '*');
      } else {
        window.parent.postMessage({ command: 'videocall_triage_next' }, '*');
      }
    }
  }, [triageData]);

  const hasEstimatedTime = triageData?.estimated_time;

  return (
    <div className="min-h-[100vh] h-full bg-bg p-6 md:grid md:place-items-center">
      <div className="max-w-[800px]">
        {type !== 'i' && (
          <div className="py-6 mb-6 sm:mb-0">
            <AppLogo />
          </div>
        )}
        <div>
          <div className="rounded-xl bg-white p-6 shadow-md">
            <h1 className="text-title mb-4 text-center font-lighter ">
              {t('triage.in_line')}
            </h1>
            <p className="text-title text-3xl mb-4 text-center font-lighter capitalize">
              {appointment?.to?.name}
            </p>
            <div className="my-4">
              <div className="m-auto w-fit rounded-full p-4 bg-primary">
                <Clock />
              </div>
              <div className="bg-black text-white font-medium rounded-lg px-3 m-auto w-fit -translate-y-2">
                #{triageData?.position}
              </div>
            </div>
            <p className="text-text text-xl text-center font-lighter">
              {triageData?.position - 1 > 0
                ? t('triage.soon_will_be_your_turn')
                : t('triage.you_are_next')}
            </p>
            {hasEstimatedTime ? (
              <>
                <div className="w-full border border-separators my-4" />
                <div className="flex flex-row items-center justify-center text-text text-center font-lighter sm:text-start gap-1">
                  <ClockIcon />
                  {t('triage.mins_approx', {
                    mins: triageData.estimated_time,
                  })}
                </div>
              </>
            ) : null}
          </div>
          {hasEstimatedTime ? (
            <div className="bg-white/50 h-4 rounded-b-lg shadow-md mx-7" />
          ) : null}
          <div className="flex flex-row items-center justify-center text-text text-center font-lighter my-4">
            {t('triage.notify_me')}
          </div>
          <div className="flex justify-center">
            <Button
              variant="custom"
              onClick={cancelVideoCall}
              className="w-fit rounded-none flex flex-col mb-4 underline-offset-4 normal-case text-gray-dark tracking-normal font-medium"
            >
              <p className="border-b-2 border-b-gray-dark">
                {t('triage.cancel')}
              </p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TriageContainer;
