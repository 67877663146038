import { useTranslation } from 'react-i18next';
import AppLogo from '../../../assets/images/AppLogo';
import { Namespace } from '../../../i18n/namespaces';

const Expired = () => {
  const { t } = useTranslation(Namespace.Appointment);

  return (
    <div className="bg-background">
      <div className="relative max-h-screen h-screen px-6 grid content-center max-w-[800px] mx-auto">
        <div>
          <AppLogo />
          <h1 className="mt-5 text-3xl mb-4 text-primary font-light text-center">
            {t('expired.title')}
          </h1>
          <p className="text-lg mb-12 text-gray-dark font-light text-center">
            {t('expired.subtitle')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Expired;
