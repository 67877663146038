export default (props: any) => (
  <svg
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.03754 7.10594L6.20453 7.25339L23.9129 24.9618L26.5793 27.6027C26.6727 27.6952 26.761 27.7918 26.8442 27.8922L33.4756 34.5245C34.1678 35.2167 34.1678 36.339 33.4756 37.0312C32.8367 37.6701 31.8313 37.7193 31.136 37.1786L30.969 37.0312L28.3823 34.4445L9.55564 34.4445L9.35086 34.4386L9.14353 34.4266L8.9381 34.4088C6.36612 34.1101 4.38626 32.0045 4.23199 29.4347L4.2223 29.1112V14.3539C4.2223 13.1825 4.68479 12.0584 5.50919 11.2262L5.34227 11.4045L3.69786 9.76005C3.05891 9.1211 3.00976 8.11569 3.55041 7.42037L3.69786 7.25339C4.33681 6.61443 5.34222 6.56528 6.03754 7.10594ZM27.3334 9.55561C29.2971 9.55561 30.889 11.1475 30.889 13.1112V16.9014L33.438 14.2433C34.71 12.9161 36.7959 12.7435 38.2771 13.7906L38.5181 13.9764C39.2343 14.5773 39.6818 15.425 39.7641 16.3401L39.7779 16.6474V27.3527C39.7779 29.3331 38.115 30.8889 36.1207 30.8889C35.2196 30.8889 34.3501 30.5676 33.6797 29.9867L33.438 29.7568L28.3079 24.4042C27.648 23.7156 27.3232 22.8359 27.3337 21.9592L27.3334 14.8889C27.3334 13.9071 26.5375 13.1112 25.5556 13.1112H16.6667L13.1112 9.55561H27.3334ZM8.10621 14.1679C7.9408 14.4003 7.8299 14.6743 7.79216 14.971L7.77786 15.1976V29.1112L7.78982 29.3185C7.87671 30.0666 8.42808 30.6731 9.14801 30.842L9.34831 30.877L9.55564 30.8889L24.8267 30.8889L8.10621 14.1679ZM36.0507 16.6772L36.005 16.7035L30.9281 22L36.005 27.2966L36.0308 27.3143L36.0705 27.328L36.1207 27.3334C36.1819 27.3334 36.2084 27.3228 36.2179 27.3336L36.2223 27.3527V16.6952L36.179 16.674C36.1376 16.663 36.0885 16.6647 36.0507 16.6772Z"
      fill="currentColor"
    />
  </svg>
);
