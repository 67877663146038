import { useEffect } from 'react';

export interface Props {
  listener: string;
  dependencies: unknown[];
  onEvent: (event: any) => void;
}

const useListener = ({ listener, dependencies, onEvent }: Props) => {
  useEffect(() => {
    window.addEventListener(listener, onEvent);
    return () => {
      window.removeEventListener(listener, onEvent);
    };
  }, dependencies);
};

export default useListener;
