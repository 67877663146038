import Loading from "../../Loading";
import ProfileForm from "../../../containers/Appointment/ProfileForm";
import ProfileDetail from "../ProfileDetail";
import { useTranslation } from "react-i18next";
import { Namespace } from "../../../i18n/namespaces";

const Profile = ({ profile, onUpdate, appointment }: any) => {
	const { t } = useTranslation(Namespace.Appointment);

	if (!profile) {
		return <Loading />;
	}

	if (!hasAllParams(profile)) {
		return (
			<ProfileForm
				profile={profile}
				title={t('profile_details.complete')}
				onUpdate={onUpdate}
				isUpdate={false}
				appointment={appointment}
			/>
		);
	}

  return <ProfileDetail profile={profile} appointment={appointment} />;
};

function hasAllParams(profile: any): boolean {
  return !(
    !profile.first_name ||
    !profile.last_name ||
    !profile.tax_id ||
    !profile.birth_date ||
    !profile.gender ||
    !profile.phone ||
    !profile.email
  );
}

export default Profile;
