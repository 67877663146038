import { useEffect, useCallback } from 'react';

type useKeypressProps = {
  key: string;
  keys?: string[];
  onPress: (event: any) => void;
};
export default ({ key, keys, onPress }: useKeypressProps) => {
  const handleKeydown = useCallback(
    (event) => {
      if (key === event.key || keys?.includes(event.key)) {
        onPress && onPress(event);
      }
    },
    [key, keys, onPress],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);
};
