import { useEffect, useState } from 'react';

export default (onOutside = (event?: any) => {}) => {
  const [element, setElement] = useState<any | HTMLElement>();

  const ref = (element: any) => {
    setElement(element);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const { target } = event;
      if (!element?.contains(target)) {
        onOutside(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [element, onOutside]);

  return ref;
};
