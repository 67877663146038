export default (props: any) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.94014 4.75329C5.45929 4.23415 6.30099 4.23415 6.82014 4.75329L27.2735 25.2066C27.7926 25.7258 27.7926 26.5675 27.2735 27.0866C26.7543 27.6058 25.9126 27.6058 25.3935 27.0866L20.866 22.5606C19.896 23.1699 18.8056 23.6059 17.6387 23.8255L17.228 23.8933V26.6666C17.228 27.4 16.628 28 15.8947 28C15.2137 28 14.6477 27.4826 14.5703 26.8216L14.5613 26.6666V23.8933C10.5347 23.32 7.33466 20.1866 6.68132 16.1866C6.56132 15.3866 7.20132 14.6666 8.01465 14.6666C8.61773 14.6666 9.12992 15.0756 9.28905 15.6524L9.32132 15.8C9.86799 18.9333 12.6013 21.3333 15.8947 21.3333C16.9819 21.3333 18.0082 21.0717 18.9148 20.6085L16.8668 18.56C16.5868 18.6266 16.3068 18.6666 16.0001 18.6666C13.7868 18.6666 12.0001 16.88 12.0001 14.6666V13.6933L4.94014 6.63329C4.42099 6.11415 4.42099 5.27244 4.94014 4.75329ZM24.2494 14.6666C25.0627 14.6666 25.7161 15.3866 25.5827 16.1866C25.4262 17.145 25.1235 18.0536 24.6982 18.8892L22.6668 16.858C22.7865 16.5169 22.8793 16.1634 22.9427 15.8C23.0494 15.1466 23.5961 14.6666 24.2494 14.6666ZM16.0001 2.66663C18.2135 2.66663 20.0001 4.45329 20.0001 6.66663V14.1333L17.5868 11.7466L17.6001 6.53329C17.6001 5.65329 16.8801 4.93329 16.0001 4.93329C15.1201 4.93329 14.4001 5.65329 14.4001 6.53329V8.54663L12.0535 6.19996C12.2801 4.21329 13.9468 2.66663 16.0001 2.66663Z"
      fill="currentColor"
    />
  </svg>
);
