import Axios from 'axios';
import { v4 } from 'uuid';
import { browserName, browserVersion } from 'react-device-detect';
import * as Sentry from '@sentry/browser';
import { config } from '../config';

class TrackingSdk {
  constructor() {
    this.deviceOs = navigator.platform;
    this.deviceOsVersion = browserVersion;
    this.deviceModel = browserName;
    this.deviceId = MediaDeviceInfo.deviceId;
    this.environment = config.app.env;
    this.url = config.services.mediquo.tracking_sdk_url;
  }

  setConsultation({ callId, userHash }) {
    this.callId = callId;
    this.userHash = userHash;
  }

  setParams({ consultationId, consultationType, apiKey }) {
    this.apiKey = apiKey;
    this.consultationId = consultationId;
    this.consultationType = consultationType;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new TrackingSdk();
    }
    return this.instance;
  }

  pageView(eventName, payload) {
    this.sendRequest({
      ...payload,
      event_type: eventName,
      action_type: 'pageview',
    });
  }

  event(eventName, payload) {
    this.sendRequest({
      ...payload,
      event_type: eventName,
      action_type: 'event',
    });
  }

  debug(eventName, payload) {
    this.sendRequest({
      ...payload,
      event_type: eventName,
      action_type: 'debug',
    });
  }

  sendRequest(payload) {
    const body = {
      ...payload,
      env: this.environment,
      user_hash: this.userHash,
      api_key: this.apiKey,
      user_agent: navigator.userAgent,
      unique_id: v4(),
      url: window.location.href,
      platform: 'web',
      device_id: this.deviceId,
      device_os: this.deviceOs,
      device_os_version: this.deviceOsVersion,
      device_model: this.deviceModel,
      consultation_id: this.consultationId,
      consultation_type: this.consultationType,
      sdk_version: '1.0.0',
      integration_type: 'flash',
    };

    Object.keys(body).forEach((key) => {
      if (!body[key]) {
        delete body[key];
      }
    });

    Axios.post(this.url, body).catch((error) =>
      Sentry.captureException(error, { extra: body }),
    );
  }
}

const TrackerSdk = TrackingSdk.getInstance();
export default TrackerSdk;
