import { useEffect, useState } from 'react';
import { MediQuoAPI } from '../api';

export const getTemplate = ({
  apiKey,
  appointmentId,
}: {
  apiKey?: string;
  appointmentId?: string;
}) => {
  const [template, setTemplate] = useState();

  useEffect(() => {
    if (apiKey) {
      MediQuoAPI.setup({ apiKey, appointmentId }).then((res: any) => {
        setTemplate(res.data?.data.template);

        document.documentElement.style.setProperty(
          `--primary-color`,
          res.data?.data.template.primary_color,
        );
      });
    }
  }, []);

  return {
    template,
    setTemplate,
  };
};
