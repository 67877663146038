export default (props: any) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 12.8C9.77304 12.8 10.9939 12.2943 11.8941 11.3941C12.7943 10.4939 13.3 9.27304 13.3 8C13.3 6.72696 12.7943 5.50606 11.8941 4.60589C10.9939 3.70571 9.77304 3.2 8.5 3.2C7.22696 3.2 6.00606 3.70571 5.10589 4.60589C4.20571 5.50606 3.7 6.72696 3.7 8C3.7 9.27304 4.20571 10.4939 5.10589 11.3941C6.00606 12.2943 7.22696 12.8 8.5 12.8ZM8.5 2C9.28793 2 10.0681 2.15519 10.7961 2.45672C11.5241 2.75825 12.1855 3.20021 12.7426 3.75736C13.2998 4.31451 13.7417 4.97595 14.0433 5.7039C14.3448 6.43185 14.5 7.21207 14.5 8C14.5 9.5913 13.8679 11.1174 12.7426 12.2426C11.6174 13.3679 10.0913 14 8.5 14C5.182 14 2.5 11.3 2.5 8C2.5 6.4087 3.13214 4.88258 4.25736 3.75736C5.38258 2.63214 6.9087 2 8.5 2ZM8.35 5C8.59853 5 8.8 5.20147 8.8 5.45V8.15L11.1247 9.52935C11.3313 9.65188 11.3979 9.91952 11.2728 10.1245C11.1495 10.3268 10.8861 10.3917 10.683 10.2698L7.9 8.6V5.45C7.9 5.20147 8.10147 5 8.35 5Z"
      fill="currentColor"
      fillOpacity="0.5"
    />
  </svg>
);
