export default (props: any) => (
  <svg
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_2429_35673)">
      <path
        d="M16.5 33C25.3366 33 32.5 25.8366 32.5 17C32.5 8.16344 25.3366 1 16.5 1C7.66344 1 0.5 8.16344 0.5 17C0.5 25.8366 7.66344 33 16.5 33Z"
        fill="url(#paint0_linear_2429_35673)"
      />
    </g>
    <path
      d="M16.5002 31.7999C24.674 31.7999 31.3002 25.1738 31.3002 16.9999C31.3002 8.82614 24.674 2.19995 16.5002 2.19995C8.32638 2.19995 1.7002 8.82614 1.7002 16.9999C1.7002 25.1738 8.32638 31.7999 16.5002 31.7999Z"
      fill="url(#paint1_radial_2429_35673)"
    />
    <path
      d="M16.5249 5.8001C16.4249 5.8001 16.3374 5.72513 16.3374 5.62513V3.01261C16.3374 2.91261 16.4249 2.83765 16.5249 2.83765C16.6249 2.83765 16.7124 2.91261 16.7124 3.01261V5.62513C16.6999 5.72513 16.6249 5.8001 16.5249 5.8001Z"
      fill="#F3F3F3"
    />
    <path
      d="M16.5252 5.81243C16.4127 5.81243 16.3252 5.72495 16.3252 5.62495V3.01243C16.3252 2.91243 16.4127 2.82495 16.5252 2.82495C16.6377 2.82495 16.7252 2.91243 16.7252 3.01243V5.62495C16.7252 5.72495 16.6252 5.81243 16.5252 5.81243ZM16.5252 2.84985C16.4377 2.84985 16.3502 2.92495 16.3502 3.01243V5.62495C16.3502 5.71244 16.4252 5.78753 16.5252 5.78753C16.6127 5.78753 16.7002 5.71244 16.7002 5.62495V3.01243C16.6878 2.92495 16.6127 2.84985 16.5252 2.84985V2.84985Z"
      fill="white"
    />
    <path
      d="M17.7756 4.40011C17.6756 4.3876 17.6006 4.31263 17.6006 4.21263L17.6881 3.05005C17.7005 2.95018 17.7881 2.87508 17.8881 2.8876C17.9881 2.90011 18.063 2.97521 18.063 3.07521L17.9756 4.23766C17.963 4.33766 17.8756 4.41263 17.7756 4.40024V4.40011Z"
      fill="#F3F3F3"
    />
    <path
      d="M17.7749 4.4125C17.6624 4.39999 17.5874 4.3125 17.5874 4.2125L17.6749 3.04992C17.6874 2.95005 17.7749 2.86257 17.8874 2.87495C17.9998 2.88747 18.0749 2.97508 18.0749 3.07495L17.9874 4.23753C17.9749 4.35005 17.8874 4.42502 17.7749 4.4125ZM17.8874 2.89999C17.7999 2.89999 17.7123 2.96257 17.7123 3.05005L17.6248 4.21263C17.6248 4.29999 17.6874 4.37508 17.7874 4.38747C17.8749 4.38747 17.9624 4.32502 17.9624 4.23753L18.0499 3.07495C18.0499 2.98747 17.9749 2.9125 17.8873 2.89999H17.8874Z"
      fill="white"
    />
    <path
      d="M18.7132 6.02521C18.613 6.00018 18.5506 5.9127 18.5631 5.8127L19.0756 3.25012C19.1007 3.15025 19.1881 3.08767 19.2881 3.1127C19.3881 3.13773 19.4506 3.22521 19.4381 3.32521L18.9256 5.8878C18.913 5.97515 18.813 6.03773 18.713 6.02521H18.7132Z"
      fill="#F3F3F3"
    />
    <path
      d="M18.7135 6.03762C18.6008 6.01258 18.5384 5.91258 18.5509 5.81258L19.0634 3.25C19.0884 3.15013 19.1884 3.07503 19.3008 3.10007C19.4133 3.1251 19.4759 3.2251 19.4634 3.3251L18.9509 5.88768C18.9259 5.98755 18.8259 6.05013 18.7135 6.03762ZM19.3008 3.1251C19.2133 3.11258 19.1259 3.16252 19.1008 3.25013L18.5884 5.81271C18.5759 5.90007 18.6259 5.98755 18.7259 6.01271C18.8134 6.0251 18.9008 5.97503 18.9259 5.88755L19.4384 3.3251C19.4509 3.2251 19.3884 3.15013 19.3008 3.1251V3.1251Z"
      fill="white"
    />
    <path
      d="M20.2125 4.8998C20.1887 4.89407 20.1662 4.88369 20.1464 4.86927C20.1266 4.85485 20.1099 4.83668 20.0971 4.81578C20.0844 4.79488 20.0758 4.77166 20.0721 4.74747C20.0683 4.72328 20.0693 4.69857 20.0751 4.67477L20.3876 3.54987C20.4125 3.44987 20.5125 3.3998 20.6125 3.42484C20.7125 3.44987 20.7751 3.54987 20.75 3.64987L20.4375 4.7749C20.4125 4.87477 20.3125 4.92484 20.2125 4.8998Z"
      fill="#F3F3F3"
    />
    <path
      d="M20.2127 4.9125C20.1127 4.88747 20.0377 4.77495 20.0753 4.67508L20.3878 3.54992C20.4127 3.45005 20.5252 3.38747 20.6252 3.4125C20.7252 3.43754 20.8002 3.55005 20.7628 3.64992L20.4502 4.77508C20.4252 4.87495 20.3252 4.93754 20.2128 4.9125H20.2127ZM20.6252 3.45005C20.5377 3.42502 20.4377 3.47508 20.4127 3.56257L20.1003 4.68747C20.0753 4.77495 20.1252 4.86257 20.2252 4.88747C20.3127 4.9125 20.4127 4.86257 20.4377 4.77508L20.7502 3.64992C20.7628 3.56257 20.7128 3.47508 20.6252 3.44992V3.45005Z"
      fill="white"
    />
    <path
      d="M20.8252 6.66247C20.7252 6.62492 20.6877 6.5124 20.7252 6.42492L21.7252 4.0124C21.7626 3.92492 21.8752 3.87486 21.9626 3.92492C22.0627 3.96234 22.1003 4.07499 22.0627 4.16234L21.0627 6.57486C21.0253 6.66247 20.9127 6.7124 20.8253 6.66247H20.8252Z"
      fill="#F3F3F3"
    />
    <path
      d="M20.8252 6.67506C20.7772 6.65675 20.7384 6.62015 20.7173 6.57328C20.6962 6.5264 20.6946 6.47308 20.7127 6.42499L21.7127 4.01248C21.7503 3.91248 21.8628 3.87493 21.9753 3.91248C22.0233 3.93078 22.0621 3.96738 22.0832 4.01426C22.1043 4.06113 22.106 4.11446 22.0878 4.16254L21.0878 6.57506C21.0377 6.67506 20.9252 6.72499 20.8252 6.67506ZM21.9628 3.93751C21.8753 3.90009 21.7753 3.93751 21.7377 4.02499L20.7377 6.43751C20.7003 6.52499 20.7503 6.61248 20.8377 6.65003C20.9252 6.68745 21.0252 6.65003 21.0628 6.56254L22.0628 4.15003C22.0878 4.07493 22.0503 3.97493 21.9628 3.93751V3.93751Z"
      fill="white"
    />
    <path
      d="M22.5123 5.86272C22.4247 5.81266 22.3871 5.71266 22.4247 5.62517L22.9497 4.58775C22.9996 4.50014 23.0996 4.46259 23.1998 4.51266C23.2872 4.56272 23.3247 4.66272 23.2872 4.75008L22.7621 5.78763C22.7121 5.87511 22.6121 5.91279 22.5121 5.86272H22.5123Z"
      fill="#F3F3F3"
    />
    <path
      d="M22.513 5.87497C22.4128 5.8249 22.3754 5.71239 22.4253 5.61239L22.9504 4.57497C23.0003 4.47484 23.113 4.43742 23.2128 4.48735C23.3128 4.53742 23.3504 4.64993 23.3003 4.74993L22.7754 5.78735C22.7253 5.88735 22.6128 5.9249 22.5128 5.87484L22.513 5.87497ZM23.2004 4.51239C23.1128 4.47497 23.0128 4.49987 22.9754 4.58735L22.4504 5.6249C22.413 5.69987 22.4504 5.79987 22.5253 5.84993C22.6128 5.88735 22.7128 5.86245 22.7504 5.77497L23.2754 4.73742C23.3128 4.66245 23.2754 4.56245 23.2004 4.51239Z"
      fill="white"
    />
    <path
      d="M22.763 7.71244C22.6755 7.64999 22.6504 7.53748 22.7129 7.46251L24.163 5.29993C24.2129 5.21244 24.3255 5.19993 24.4129 5.24999C24.5005 5.31244 24.5254 5.42496 24.463 5.49993L23.0129 7.66251C22.963 7.74999 22.8504 7.77503 22.763 7.71244Z"
      fill="#F3F3F3"
    />
    <path
      d="M22.7628 7.72513C22.6753 7.66268 22.6503 7.53765 22.7003 7.45017L24.1503 5.28759C24.2127 5.2001 24.3252 5.17507 24.4252 5.23765C24.5127 5.3001 24.5377 5.42513 24.4878 5.51262L23.0377 7.6752C22.9753 7.76268 22.8503 7.78759 22.7628 7.72513ZM24.4127 5.26268C24.3379 5.21262 24.2252 5.22513 24.1753 5.3001L22.7252 7.46268C22.6753 7.53752 22.7002 7.63765 22.7753 7.68759C22.8503 7.73765 22.9628 7.72513 23.0127 7.65017L24.4628 5.5001C24.5127 5.42513 24.4878 5.31262 24.4127 5.26268V5.26268Z"
      fill="white"
    />
    <path
      d="M24.5879 7.25023C24.5131 7.18765 24.4881 7.07513 24.5505 7.00016L25.2756 6.08765C25.338 6.01281 25.4505 6.00016 25.5255 6.06274C25.6006 6.1252 25.6255 6.23771 25.5629 6.31268L24.838 7.2252C24.7756 7.30016 24.663 7.31268 24.5881 7.25023H24.5879Z"
      fill="#F3F3F3"
    />
    <path
      d="M24.5751 7.26256C24.4876 7.19998 24.4751 7.07495 24.5377 6.98746L25.2626 6.07507C25.325 5.98746 25.45 5.97495 25.5375 6.05004C25.625 6.11249 25.6375 6.23753 25.5749 6.32501L24.85 7.23753C24.7876 7.32501 24.6626 7.33753 24.5751 7.26256V7.26256ZM25.5125 6.07495C25.4377 6.01249 25.3375 6.02501 25.2751 6.09998L24.55 7.01249C24.5001 7.08746 24.5126 7.18746 24.5875 7.24991C24.6626 7.31249 24.7626 7.29998 24.825 7.22501L25.55 6.31249C25.6001 6.23753 25.5875 6.12501 25.5126 6.07507L25.5125 6.07495Z"
      fill="white"
    />
    <path
      d="M24.463 9.12501C24.388 9.05004 24.388 8.93752 24.463 8.87494L26.3129 7.03752C26.388 6.96256 26.5004 6.97494 26.563 7.03752C26.6254 7.09998 26.6379 7.22501 26.563 7.28746L24.7255 9.12501C24.6504 9.19998 24.5379 9.19998 24.463 9.12501V9.12501Z"
      fill="#F3F3F3"
    />
    <path
      d="M24.4503 9.13758C24.4142 9.10091 24.394 9.05153 24.394 9.0001C24.394 8.94867 24.4142 8.8993 24.4503 8.86262L26.3002 7.02507C26.3369 6.98898 26.3863 6.96875 26.4378 6.96875C26.4892 6.96875 26.5386 6.98898 26.5753 7.02507C26.6114 7.06175 26.6316 7.11112 26.6316 7.16255C26.6316 7.21398 26.6114 7.26336 26.5753 7.30004L24.7253 9.13746C24.6886 9.17351 24.6392 9.19371 24.5878 9.19371C24.5363 9.19371 24.487 9.17351 24.4503 9.13746V9.13758ZM26.5628 7.03758C26.5004 6.975 26.3878 6.975 26.3254 7.03758L24.4753 8.875C24.4127 8.93758 24.4127 9.0501 24.4753 9.11255C24.5378 9.17513 24.6503 9.17513 24.7127 9.11255L26.5628 7.27513C26.6253 7.21255 26.6253 7.11255 26.5628 7.03758Z"
      fill="white"
    />
    <path
      d="M26.3379 9.02488C26.2754 8.94991 26.2754 8.83739 26.3504 8.77481L27.2379 8.02488C27.3128 7.96243 27.4253 7.97481 27.4879 8.04991C27.5504 8.12488 27.5504 8.23739 27.4754 8.29985L26.5879 9.04991C26.5128 9.11236 26.4003 9.09985 26.3379 9.02488Z"
      fill="#F3F3F3"
    />
    <path
      d="M26.3253 9.03763C26.2503 8.95014 26.2628 8.82511 26.3378 8.76266L27.2253 8.01259C27.3002 7.93763 27.4253 7.95014 27.5002 8.03763C27.5753 8.12511 27.5628 8.25014 27.4878 8.31259L26.6002 9.06266C26.5254 9.1375 26.4002 9.12511 26.3254 9.03763H26.3253ZM27.4753 8.05014C27.4127 7.97517 27.3127 7.97517 27.2379 8.02511L26.3503 8.77517C26.2878 8.83763 26.2753 8.93763 26.3378 9.01259C26.4002 9.08756 26.5004 9.08756 26.5752 9.03763L27.4628 8.28756C27.5378 8.22511 27.5378 8.12511 27.4753 8.05014Z"
      fill="white"
    />
    <path
      d="M25.8379 10.8249C25.7754 10.7374 25.8005 10.6249 25.888 10.5749L28.0629 9.12492C28.1504 9.07486 28.2629 9.09989 28.3129 9.17499C28.3754 9.26247 28.3504 9.37499 28.2629 9.42492L26.088 10.8749C26.0129 10.9249 25.9003 10.8999 25.8379 10.8249V10.8249Z"
      fill="#F3F3F3"
    />
    <path
      d="M25.8247 10.8252C25.7623 10.7377 25.7873 10.6127 25.8748 10.5503L28.0497 9.10019C28.1372 9.03774 28.2623 9.06277 28.3247 9.16277C28.3873 9.25026 28.3621 9.37529 28.2748 9.43761L26.0998 10.8878C26.0122 10.9504 25.8873 10.9254 25.8247 10.8254V10.8252ZM28.2997 9.1878C28.2497 9.11271 28.1497 9.08767 28.0748 9.13774L25.8997 10.5877C25.8248 10.6377 25.7997 10.7377 25.8623 10.8252C25.9122 10.9002 26.0122 10.9252 26.0873 10.8753L28.2623 9.42522C28.3372 9.36277 28.3497 9.26264 28.2997 9.1878Z"
      fill="white"
    />
    <path
      d="M27.7003 11.0877C27.6503 11.0001 27.6753 10.8877 27.7628 10.8376L28.7753 10.2627C28.8628 10.2126 28.9753 10.2501 29.0252 10.3375C29.0753 10.4251 29.0503 10.5375 28.9628 10.5876L27.9503 11.1627C27.8628 11.2126 27.7503 11.1752 27.7002 11.0876L27.7003 11.0877Z"
      fill="#F3F3F3"
    />
    <path
      d="M27.6881 11.1001C27.6381 11.0001 27.6631 10.8876 27.7506 10.8376L28.7631 10.2627C28.8506 10.2126 28.9756 10.2501 29.0255 10.3375C29.0756 10.4376 29.0506 10.5501 28.9631 10.6001L27.9506 11.1752C27.8631 11.2251 27.7381 11.1876 27.6881 11.1001V11.1001ZM29.013 10.3501C28.9631 10.2627 28.8631 10.2376 28.7881 10.2876L27.7756 10.8627C27.7006 10.9126 27.6756 11.0126 27.713 11.0876C27.7631 11.1751 27.8631 11.2001 27.9381 11.1501L28.9506 10.5751C29.0255 10.5376 29.0506 10.4376 29.013 10.3501V10.3501Z"
      fill="white"
    />
    <path
      d="M26.8629 12.7501C26.8254 12.6501 26.8629 12.5501 26.9629 12.5126L29.3754 11.5126C29.4629 11.4751 29.5754 11.525 29.6128 11.6125C29.6503 11.7125 29.6128 11.8125 29.5128 11.8499L27.1004 12.8499C27.0128 12.8875 26.9004 12.8499 26.8629 12.7501Z"
      fill="#F3F3F3"
    />
    <path
      d="M26.8507 12.7625C26.8132 12.6626 26.8507 12.5501 26.9507 12.5L29.3632 11.5C29.4112 11.4819 29.4645 11.4836 29.5114 11.5047C29.5582 11.5258 29.5948 11.5645 29.6131 11.6125C29.6507 11.7125 29.6131 11.8251 29.5131 11.875L27.1007 12.875C27.0527 12.8932 26.9993 12.8915 26.9525 12.8705C26.9056 12.8494 26.869 12.8106 26.8507 12.7626V12.7625ZM29.5882 11.6251C29.5507 11.5376 29.4507 11.5 29.3757 11.5251L26.9632 12.5251C26.8757 12.5625 26.8381 12.6626 26.8757 12.7501C26.9131 12.8376 27.0131 12.8751 27.0882 12.8501L29.5006 11.8501C29.5882 11.8127 29.6258 11.7125 29.5882 11.6251Z"
      fill="white"
    />
    <path
      d="M28.6257 13.3751C28.5883 13.275 28.6382 13.1751 28.7382 13.1376L29.8382 12.775C29.9257 12.7376 30.0382 12.8 30.0632 12.8875C30.1007 12.9875 30.0507 13.0875 29.9507 13.125L28.8507 13.4876C28.7632 13.525 28.6632 13.4751 28.6257 13.375V13.3751Z"
      fill="#F3F3F3"
    />
    <path
      d="M28.6129 13.3874C28.5754 13.2874 28.6254 13.1749 28.738 13.1375L29.8379 12.7749C29.9379 12.7375 30.0504 12.7999 30.088 12.9C30.1254 12.9999 30.0754 13.1124 29.9628 13.15L28.8629 13.5126C28.7629 13.5375 28.6504 13.4874 28.6129 13.3874ZM30.0629 12.9126C30.0379 12.8249 29.9379 12.7749 29.8504 12.7999L28.7504 13.1625C28.6629 13.1874 28.6254 13.2874 28.6504 13.375C28.6754 13.4625 28.7754 13.5124 28.8629 13.4874L29.9629 13.1249C30.0504 13.0875 30.088 12.9999 30.0629 12.9124V12.9126Z"
      fill="white"
    />
    <path
      d="M27.5006 14.8624C27.4756 14.7624 27.5381 14.6624 27.6381 14.6499L30.2006 14.1499C30.3005 14.1248 30.3881 14.1998 30.413 14.2998C30.4381 14.3998 30.3756 14.4998 30.2756 14.5123L27.713 15.0123C27.613 15.0248 27.513 14.9624 27.5005 14.8624H27.5006Z"
      fill="#F3F3F3"
    />
    <path
      d="M27.4751 14.8627C27.45 14.7502 27.525 14.6502 27.625 14.6252L30.1876 14.1252C30.2876 14.1002 30.3876 14.1752 30.4125 14.2878C30.4375 14.4002 30.3626 14.5002 30.2626 14.5252L27.7 15.0252C27.6001 15.0502 27.5 14.9752 27.4751 14.8626V14.8627ZM30.3876 14.3002C30.3751 14.2127 30.2876 14.1502 30.2001 14.1627L27.6375 14.6627C27.55 14.6752 27.4876 14.7751 27.5125 14.8627C27.525 14.9502 27.6125 15.0127 27.7001 15.0002L30.2626 14.5002C30.35 14.4751 30.4125 14.3876 30.3876 14.3002Z"
      fill="white"
    />
    <path
      d="M29.1002 15.825C29.0877 15.725 29.1502 15.6375 29.2502 15.625L30.4001 15.4874C30.5001 15.475 30.5877 15.55 30.6001 15.65C30.6126 15.75 30.5502 15.8375 30.4502 15.85L29.3001 15.9874C29.2126 15.9999 29.1126 15.925 29.1001 15.825H29.1002Z"
      fill="#F3F3F3"
    />
    <path
      d="M29.0878 15.825C29.0753 15.7125 29.1502 15.6125 29.2502 15.6L30.4002 15.4625C30.5002 15.45 30.6002 15.525 30.6127 15.6375C30.6252 15.75 30.5502 15.85 30.4502 15.8625L29.3002 16C29.2003 16.0125 29.1002 15.9375 29.0878 15.825ZM30.6002 15.65C30.5878 15.5625 30.5003 15.4874 30.4127 15.5L29.2628 15.6375C29.1753 15.65 29.1127 15.7375 29.1252 15.825C29.1377 15.9125 29.2252 15.9876 29.3127 15.9751L30.4628 15.8375C30.5502 15.825 30.6127 15.7375 30.6003 15.65H30.6002Z"
      fill="white"
    />
    <path
      d="M27.7002 17.0376C27.7002 16.9376 27.775 16.8501 27.8752 16.8501H30.4877C30.5877 16.8501 30.6626 16.9376 30.6626 17.0376C30.6626 17.1376 30.5877 17.2251 30.4877 17.2251H27.8752C27.7752 17.2251 27.7002 17.1376 27.7002 17.0376Z"
      fill="#F3F3F3"
    />
    <path
      d="M27.6875 17.0376C27.6875 16.9251 27.775 16.8376 27.875 16.8376H30.4875C30.5875 16.8376 30.675 16.9251 30.675 17.0376C30.675 17.1502 30.5875 17.2376 30.4875 17.2376H27.875C27.775 17.2376 27.6875 17.1502 27.6875 17.0376ZM30.65 17.0502C30.65 16.9627 30.575 16.8752 30.4874 16.8752H27.8751C27.7876 16.8752 27.7125 16.95 27.7125 17.0502C27.7125 17.1376 27.7876 17.2251 27.8751 17.2251H30.4876C30.5751 17.2126 30.6501 17.1376 30.6501 17.0502H30.65Z"
      fill="white"
    />
    <path
      d="M29.0875 18.3C29.0999 18.2 29.175 18.125 29.275 18.125L30.4376 18.2125C30.5375 18.225 30.6124 18.3125 30.5999 18.4125C30.5875 18.5125 30.5124 18.5875 30.4124 18.5875L29.25 18.5C29.1625 18.4875 29.0874 18.4 29.0874 18.3H29.0875Z"
      fill="#F3F3F3"
    />
    <path
      d="M29.0752 18.3C29.0877 18.1875 29.1752 18.1125 29.2752 18.1125L30.4378 18.2C30.5376 18.2125 30.6251 18.3 30.6126 18.4125C30.6002 18.5251 30.5126 18.6 30.4126 18.6L29.2502 18.5125C29.1502 18.5 29.0752 18.4 29.0752 18.3ZM30.6002 18.4125C30.6126 18.3251 30.5376 18.2376 30.4502 18.2376L29.2876 18.1501C29.2002 18.1501 29.1251 18.2125 29.1126 18.3127C29.1002 18.4 29.1752 18.4875 29.2627 18.4875L30.4253 18.575C30.5126 18.575 30.5877 18.5002 30.6002 18.4125V18.4125Z"
      fill="white"
    />
    <path
      d="M27.4752 19.2126C27.5003 19.1126 27.5877 19.05 27.6877 19.0627L30.2502 19.5752C30.3502 19.6001 30.4128 19.6876 30.3877 19.7876C30.3627 19.8876 30.2752 19.9501 30.1752 19.9376L27.6126 19.4251C27.5252 19.4126 27.4627 19.3126 27.4752 19.2126V19.2126Z"
      fill="#F3F3F3"
    />
    <path
      d="M27.463 19.2125C27.488 19.1 27.588 19.0375 27.688 19.0499L30.2505 19.5625C30.3505 19.5874 30.4255 19.6874 30.4004 19.8C30.3755 19.9125 30.2755 19.9751 30.1755 19.9625L27.613 19.45C27.513 19.425 27.4505 19.325 27.463 19.2126V19.2125ZM30.3755 19.8C30.388 19.7125 30.3381 19.625 30.2505 19.6L27.6879 19.0874C27.6006 19.0751 27.513 19.125 27.4879 19.225C27.4755 19.3125 27.5255 19.4 27.6131 19.425L30.1755 19.9375C30.2755 19.95 30.3505 19.8874 30.3755 19.8Z"
      fill="white"
    />
    <path
      d="M28.6002 20.7127C28.606 20.6889 28.6163 20.6665 28.6307 20.6467C28.6451 20.6269 28.6633 20.6102 28.6842 20.5974C28.7051 20.5846 28.7283 20.5761 28.7525 20.5723C28.7766 20.5685 28.8013 20.5695 28.8251 20.5753L29.9501 20.8877C30.0501 20.9127 30.1001 21.0128 30.0752 21.1127C30.0695 21.1365 30.0591 21.159 30.0447 21.1788C30.0303 21.1986 30.0121 21.2154 29.9912 21.2281C29.9703 21.2409 29.9471 21.2494 29.9229 21.2532C29.8987 21.257 29.874 21.256 29.8501 21.2503L28.7251 20.9377C28.6251 20.9127 28.5752 20.8127 28.6001 20.7127H28.6002Z"
      fill="#F3F3F3"
    />
    <path
      d="M28.5875 20.7125C28.6125 20.6125 28.725 20.5375 28.825 20.575L29.9501 20.8874C30.0501 20.9125 30.1126 21.025 30.0876 21.125C30.0626 21.225 29.9501 21.2999 29.8501 21.2625L28.725 20.95C28.625 20.925 28.5624 20.825 28.5876 20.7126L28.5875 20.7125ZM30.0501 21.125C30.0751 21.0375 30.025 20.9375 29.9375 20.9125L28.8125 20.5999C28.725 20.5749 28.6375 20.625 28.6125 20.725C28.5876 20.8125 28.6375 20.9125 28.725 20.9375L29.8501 21.25C29.9375 21.2625 30.025 21.2126 30.0501 21.125Z"
      fill="white"
    />
    <path
      d="M26.8375 21.3128C26.8749 21.2128 26.9876 21.1752 27.0749 21.2128L29.4876 22.2128C29.5751 22.2502 29.625 22.3628 29.5876 22.4502C29.55 22.5503 29.4375 22.5877 29.35 22.5503L26.9375 21.5503C26.8375 21.5129 26.8 21.4003 26.8375 21.3129V21.3128Z"
      fill="#F3F3F3"
    />
    <path
      d="M26.8253 21.3001C26.8436 21.2521 26.8802 21.2132 26.927 21.1921C26.9739 21.1711 27.0272 21.1694 27.0753 21.1876L29.4878 22.1876C29.5878 22.2251 29.6253 22.3376 29.5878 22.4502C29.5695 22.4982 29.5329 22.537 29.4861 22.5581C29.4392 22.5792 29.3859 22.5808 29.3378 22.5627L26.9253 21.5627C26.8253 21.5126 26.7878 21.4001 26.8253 21.3001ZM29.5628 22.4376C29.6002 22.3502 29.5628 22.2502 29.4753 22.2126L27.0628 21.2126C26.9753 21.1752 26.8878 21.2251 26.8503 21.3126C26.8129 21.4001 26.8503 21.5001 26.9378 21.5376L29.3503 22.5376C29.4378 22.5627 29.5378 22.5251 29.5628 22.4376Z"
      fill="white"
    />
    <path
      d="M27.6504 23.0002C27.7004 22.9127 27.8003 22.8751 27.8878 22.9127L28.9253 23.4377C29.0128 23.4751 29.0504 23.5877 29.0004 23.6877C28.9504 23.7751 28.8504 23.8128 28.7629 23.7751L27.7255 23.2502C27.6378 23.2002 27.6003 23.0877 27.6504 23.0002Z"
      fill="#F3F3F3"
    />
    <path
      d="M27.6382 22.9872C27.6883 22.8872 27.8008 22.8498 27.9008 22.8997L28.9382 23.4247C29.0382 23.4748 29.0758 23.5873 29.0257 23.6872C28.9758 23.7872 28.8631 23.8247 28.7633 23.7747L27.7258 23.2498C27.6257 23.2124 27.5883 23.0872 27.6382 22.9872V22.9872ZM28.9883 23.6747C29.0257 23.5872 29.0007 23.4872 28.9132 23.4498L27.8758 22.9247C27.8007 22.8873 27.7008 22.9247 27.6507 22.9997C27.6133 23.0872 27.6382 23.1872 27.7257 23.2247L28.7631 23.7498C28.8507 23.7872 28.9507 23.7623 28.9883 23.6747Z"
      fill="white"
    />
    <path
      d="M25.8125 23.2376C25.8751 23.1501 25.9751 23.1251 26.0626 23.1875L28.2375 24.6376C28.325 24.6875 28.3375 24.8001 28.2876 24.8875C28.225 24.9751 28.125 25 28.0375 24.9376L25.8626 23.4875C25.7751 23.4251 25.7501 23.3125 25.8125 23.2376Z"
      fill="#F3F3F3"
    />
    <path
      d="M25.7999 23.2249C25.8625 23.1375 25.9875 23.1124 26.075 23.1625L28.2499 24.6124C28.3374 24.6749 28.3625 24.7874 28.2999 24.8874C28.2374 24.975 28.1124 24.9999 28.025 24.95L25.8499 23.4999C25.7499 23.4375 25.7374 23.3124 25.7999 23.2249ZM28.2625 24.8749C28.3124 24.8 28.2999 24.6874 28.225 24.6375L26.0499 23.1874C25.975 23.1375 25.875 23.1625 25.8249 23.2375C25.775 23.3124 25.7875 23.4249 25.8623 23.4749L28.0374 24.9249C28.1124 24.975 28.2124 24.95 28.2625 24.875V24.8749Z"
      fill="white"
    />
    <path
      d="M26.2749 25.05C26.3374 24.9749 26.4499 24.95 26.5248 25.0126L27.4374 25.7249C27.5123 25.7874 27.5248 25.8999 27.4624 25.975C27.3999 26.0499 27.2874 26.075 27.2123 26.0124L26.2998 25.2999C26.225 25.2375 26.2123 25.125 26.2749 25.05V25.05Z"
      fill="#F3F3F3"
    />
    <path
      d="M26.2627 25.0375C26.3252 24.95 26.4502 24.9375 26.5377 25.0001L27.4502 25.7124C27.5377 25.7749 27.5502 25.8999 27.4876 25.9874C27.4252 26.075 27.3001 26.0874 27.2128 26.025L26.3001 25.3124C26.2126 25.25 26.2002 25.125 26.2627 25.0375ZM27.4627 25.975C27.5252 25.8999 27.5126 25.7999 27.4377 25.7375L26.5252 25.025C26.4502 24.975 26.3502 24.9875 26.2877 25.0624C26.2252 25.1375 26.2377 25.2375 26.3126 25.2999L27.2252 26.0124C27.3001 26.0625 27.4001 26.0499 27.4626 25.975H27.4627Z"
      fill="white"
    />
    <path
      d="M24.413 24.9251C24.4881 24.8502 24.6007 24.8502 24.6631 24.9251L26.513 26.7625C26.5881 26.8376 26.5756 26.9502 26.513 27.0126C26.4382 27.0876 26.3256 27.0876 26.2631 27.0126L24.413 25.1752C24.3506 25.1126 24.3506 24.9876 24.413 24.9251V24.9251Z"
      fill="#F3F3F3"
    />
    <path
      d="M24.4128 24.9125C24.4495 24.8764 24.4989 24.8562 24.5503 24.8562C24.6018 24.8562 24.6512 24.8764 24.6879 24.9125L26.5378 26.7499C26.5739 26.7866 26.5941 26.836 26.5941 26.8875C26.5941 26.9389 26.5739 26.9884 26.5378 27.025C26.5011 27.0611 26.4518 27.0813 26.4003 27.0813C26.3489 27.0813 26.2995 27.0611 26.2628 27.025L24.4128 25.1876C24.3253 25.1125 24.3253 24.9876 24.4128 24.9125ZM26.5128 27C26.5754 26.9376 26.5754 26.825 26.5128 26.7626L24.6628 24.925C24.6004 24.8626 24.4879 24.8626 24.4254 24.925C24.3628 24.9875 24.3628 25.1 24.4254 25.1625L26.2754 27C26.3378 27.0751 26.4379 27.0751 26.5128 27Z"
      fill="white"
    />
    <path
      d="M24.5255 26.7876C24.6006 26.7251 24.7129 26.7251 24.7755 26.8001L25.5381 27.6751C25.6004 27.7501 25.588 27.8627 25.5129 27.9251C25.4381 27.9876 25.3255 27.9876 25.263 27.9126L24.5004 27.0376C24.438 26.9752 24.4505 26.8627 24.5255 26.7876V26.7876Z"
      fill="#F3F3F3"
    />
    <path
      d="M24.5131 26.7874C24.6006 26.7125 24.7131 26.7249 24.788 26.7999L25.5505 27.6748C25.6255 27.7499 25.613 27.8748 25.5255 27.9499C25.438 28.0249 25.3255 28.0124 25.2505 27.9374L24.488 27.0625C24.4255 26.975 24.4255 26.8499 24.513 26.7874H24.5131ZM25.5131 27.9249C25.588 27.8625 25.588 27.7623 25.538 27.6875L24.7754 26.8124C24.713 26.7499 24.613 26.7374 24.538 26.7999C24.463 26.8625 24.463 26.9625 24.513 27.0373L25.2756 27.9124C25.338 27.9874 25.4381 27.9874 25.513 27.9249H25.5131Z"
      fill="white"
    />
    <path
      d="M22.725 26.3125C22.8125 26.2501 22.925 26.2751 22.9751 26.3626L24.4249 28.525C24.475 28.6125 24.4499 28.725 24.375 28.7751C24.2875 28.8375 24.175 28.8125 24.1249 28.725L22.675 26.5624C22.6249 26.4751 22.6499 26.3624 22.7249 26.3125H22.725Z"
      fill="#F3F3F3"
    />
    <path
      d="M22.7254 26.3001C22.8129 26.2376 22.9379 26.2626 23.0004 26.3501L24.4504 28.5127C24.5129 28.6001 24.4878 28.7251 24.388 28.7875C24.3004 28.8501 24.1755 28.8251 24.1129 28.7376L22.6629 26.575C22.6005 26.4751 22.6255 26.3626 22.7254 26.3002V26.3001ZM24.3755 28.7626C24.4504 28.7126 24.4755 28.6126 24.4254 28.5376L22.9755 26.375C22.9254 26.3002 22.8254 26.2875 22.738 26.3376C22.6629 26.3875 22.6379 26.4875 22.688 26.5626L24.1379 28.7251C24.188 28.7875 24.3005 28.8126 24.3753 28.7625L24.3755 28.7626Z"
      fill="white"
    />
    <path
      d="M22.463 28.1624C22.5505 28.1125 22.663 28.1375 22.7129 28.225L23.288 29.2375C23.3379 29.325 23.3004 29.4375 23.2129 29.4874C23.1254 29.5375 23.0129 29.5125 22.963 29.425L22.388 28.4125C22.3504 28.325 22.3755 28.2125 22.4628 28.1625L22.463 28.1624Z"
      fill="#F3F3F3"
    />
    <path
      d="M22.4628 28.1503C22.5628 28.1002 22.6753 28.1252 22.7253 28.2127L23.3002 29.2252C23.3503 29.3127 23.3253 29.4378 23.2254 29.4877C23.1253 29.5378 23.0127 29.5127 22.9628 29.4252L22.3878 28.4127C22.3378 28.3252 22.3628 28.2127 22.4627 28.1503H22.4628ZM23.2127 29.4753C23.2878 29.4252 23.3253 29.3252 23.2753 29.2503L22.7004 28.2378C22.6503 28.1628 22.5503 28.1378 22.4753 28.1752C22.4002 28.2252 22.3628 28.3252 22.4127 28.4002L22.9878 29.4127C23.0253 29.4877 23.1378 29.5252 23.2127 29.4752V29.4753Z"
      fill="white"
    />
    <path
      d="M20.8 27.3374C20.9 27.3 21 27.3374 21.0376 27.4249L22.0501 29.8374C22.0875 29.9249 22.0376 30.0374 21.9501 30.0749C21.8626 30.1124 21.7501 30.0749 21.7126 29.9874L20.7001 27.575C20.6626 27.4874 20.7125 27.375 20.8 27.3374Z"
      fill="#F3F3F3"
    />
    <path
      d="M20.8001 27.325C20.9001 27.2876 21.0127 27.325 21.0627 27.425L22.0752 29.8375C22.0934 29.8856 22.0917 29.9389 22.0706 29.9857C22.0495 30.0326 22.0107 30.0692 21.9627 30.0875C21.8627 30.125 21.7502 30.0875 21.7003 29.9875L20.6878 27.5751C20.6502 27.4875 20.7003 27.3751 20.8001 27.325V27.325ZM21.9377 30.0626C22.0252 30.025 22.0627 29.925 22.0252 29.8501L21.0127 27.4375C20.9752 27.3501 20.8752 27.3125 20.7878 27.3501C20.7003 27.3875 20.6626 27.4875 20.7003 27.5626L21.7127 29.9751C21.7627 30.0626 21.8502 30.1 21.9377 30.0626Z"
      fill="white"
    />
    <path
      d="M20.1873 29.1125C20.2874 29.0751 20.3873 29.125 20.4249 29.225L20.7874 30.325C20.8249 30.4125 20.7624 30.525 20.6749 30.5501C20.5749 30.5875 20.4749 30.5375 20.4375 30.4375L20.0749 29.3375C20.0375 29.2501 20.0874 29.1501 20.1874 29.1125H20.1873Z"
      fill="#F3F3F3"
    />
    <path
      d="M20.1754 29.1001C20.2234 29.082 20.2767 29.0837 20.3236 29.1048C20.3704 29.1259 20.407 29.1646 20.4253 29.2126L20.7879 30.3126C20.8253 30.4126 20.7628 30.5252 20.6627 30.5627C20.6146 30.5808 20.5614 30.5792 20.5145 30.5581C20.4677 30.537 20.4311 30.4982 20.4128 30.4502L20.0503 29.3502C20.0253 29.2502 20.0754 29.1377 20.1754 29.1001ZM20.6628 30.5375C20.7503 30.5126 20.8003 30.4126 20.7754 30.3252L20.4128 29.2252C20.3879 29.1377 20.2879 29.1001 20.2004 29.1252C20.1128 29.1502 20.0628 29.2502 20.0879 29.3377L20.4503 30.4377C20.4567 30.4589 20.4675 30.4785 20.4819 30.4953C20.4964 30.5121 20.5142 30.5256 20.5343 30.5351C20.5543 30.5445 20.5761 30.5496 20.5983 30.5501C20.6204 30.5505 20.6424 30.5463 20.6628 30.5377V30.5375Z"
      fill="white"
    />
    <path
      d="M18.7133 27.975C18.8131 27.95 18.9133 28.0124 18.9257 28.1124L19.4507 30.675C19.4757 30.7749 19.4006 30.8625 19.3006 30.8874C19.2008 30.9124 19.1006 30.85 19.0882 30.75L18.5631 28.1874C18.5507 28.0999 18.6131 27.9999 18.7131 27.9749L18.7133 27.975Z"
      fill="#F3F3F3"
    />
    <path
      d="M18.7128 27.9623C18.8251 27.9374 18.9251 28.0124 18.9502 28.1124L19.4752 30.675C19.5002 30.7748 19.4251 30.8874 19.3251 30.8999C19.2126 30.9249 19.1126 30.8499 19.0877 30.7499L18.5626 28.1874C18.5377 28.0874 18.6002 27.9874 18.7126 27.9625L18.7128 27.9623ZM19.3001 30.8748C19.3877 30.8625 19.4502 30.7748 19.4377 30.6748L18.9126 28.1124C18.9002 28.0249 18.8002 27.9625 18.7126 27.9874C18.6251 27.9999 18.5627 28.0874 18.5752 28.1874L19.1002 30.7499C19.1251 30.8374 19.2126 30.8874 19.3002 30.875L19.3001 30.8748Z"
      fill="white"
    />
    <path
      d="M17.7629 29.6C17.8629 29.5875 17.9504 29.65 17.9755 29.75L18.1254 30.9C18.1379 31 18.0629 31.0875 17.9628 31.1C17.8629 31.1125 17.7755 31.05 17.7504 30.95L17.6005 29.8C17.588 29.7 17.6629 29.6125 17.7629 29.6Z"
      fill="#F3F3F3"
    />
    <path
      d="M17.7628 29.5875C17.8753 29.5751 17.9753 29.6501 17.9878 29.7501L18.1377 30.9C18.1502 31 18.0753 31.1 17.9628 31.1126C17.8502 31.1251 17.7502 31.0501 17.7377 30.95L17.5878 29.8C17.5753 29.7 17.6502 29.6 17.7628 29.5875ZM17.9502 31.0875C18.0377 31.0751 18.1128 30.9875 18.1003 30.9L17.9502 29.7501C17.9377 29.6626 17.8502 29.6 17.7628 29.6126C17.6753 29.6251 17.6002 29.7126 17.6127 29.8L17.7628 30.9501C17.7753 31.0376 17.8628 31.1 17.9502 31.0875Z"
      fill="white"
    />
    <path
      d="M16.4756 28.2C16.5756 28.2 16.6631 28.275 16.6631 28.375V30.9874C16.6631 31.0874 16.5756 31.1625 16.4756 31.1625C16.3756 31.1625 16.2881 31.0874 16.2881 30.9874V28.375C16.3006 28.275 16.3756 28.2 16.4756 28.2Z"
      fill="#F3F3F3"
    />
    <path
      d="M16.4759 28.1875C16.5884 28.1875 16.6759 28.275 16.6759 28.375V30.9875C16.6759 31.0875 16.5884 31.175 16.4759 31.175C16.3634 31.175 16.2759 31.0875 16.2759 30.9875V28.3751C16.2759 28.2751 16.3759 28.1875 16.4759 28.1875ZM16.4759 31.1501C16.5634 31.1501 16.6508 31.0751 16.6508 30.9875V28.3751C16.6508 28.2875 16.5759 28.2125 16.4759 28.2125C16.3884 28.2125 16.3009 28.2875 16.3009 28.3751V30.9875C16.3133 31.0751 16.3884 31.1501 16.4759 31.1501Z"
      fill="white"
    />
    <path
      d="M15.225 29.6002C15.325 29.6128 15.4 29.6877 15.4 29.7877L15.3125 30.9503C15.3001 31.0503 15.2125 31.1253 15.1125 31.1129C15.0125 31.1002 14.9375 31.0253 14.9375 30.9253L15.025 29.7627C15.0375 29.6628 15.125 29.5877 15.225 29.6002Z"
      fill="#F3F3F3"
    />
    <path
      d="M15.2253 29.5875C15.3378 29.6 15.4128 29.6875 15.4128 29.7875L15.3253 30.9501C15.3128 31.0501 15.2253 31.1376 15.1128 31.1251C15.0004 31.1125 14.9253 31.0251 14.9253 30.9251L15.0128 29.7625C15.0253 29.6501 15.1128 29.5751 15.2253 29.5875ZM15.1128 31.1C15.2003 31.1 15.2879 31.0376 15.2879 30.9501L15.3754 29.7875C15.3754 29.7 15.3128 29.6251 15.2128 29.6125C15.1253 29.6125 15.0378 29.675 15.0378 29.7626L14.9503 30.9251C14.9503 31.0125 15.0253 31.0876 15.1129 31.1H15.1128Z"
      fill="white"
    />
    <path
      d="M14.2881 27.9755C14.3881 28.0004 14.4506 28.0879 14.4381 28.1879L13.9256 30.7504C13.9005 30.8504 13.813 30.913 13.713 30.8879C13.613 30.863 13.5506 30.7755 13.5631 30.6753L14.0756 28.1129C14.0881 28.0254 14.1881 27.963 14.2881 27.9755Z"
      fill="#F3F3F3"
    />
    <path
      d="M14.2878 27.9624C14.4002 27.9875 14.4627 28.0876 14.4502 28.1875L13.9377 30.75C13.9127 30.85 13.8127 30.925 13.7003 30.9C13.5878 30.8749 13.5252 30.7749 13.5377 30.6749L14.0502 28.1125C14.0753 28.0125 14.1753 27.9499 14.2876 27.9626L14.2878 27.9624ZM13.7003 30.8749C13.7878 30.8875 13.8753 30.8375 13.9003 30.75L14.4127 28.1875C14.4252 28.1 14.3753 28.0125 14.2753 27.9875C14.1878 27.9751 14.1003 28.025 14.0753 28.1126L13.5627 30.6749C13.5502 30.7749 13.6127 30.85 13.7003 30.8749V30.8749Z"
      fill="white"
    />
    <path
      d="M12.7878 29.1001C12.8879 29.1251 12.9504 29.2251 12.9253 29.3251L12.6128 30.4501C12.5879 30.5501 12.4879 30.6001 12.3879 30.5752C12.3641 30.5695 12.3416 30.5591 12.3218 30.5447C12.302 30.5303 12.2852 30.5121 12.2725 30.4912C12.2597 30.4703 12.2512 30.4471 12.2474 30.4229C12.2436 30.3987 12.2446 30.374 12.2504 30.3501L12.5629 29.2251C12.5879 29.1251 12.6879 29.0752 12.7879 29.1001H12.7878Z"
      fill="#F3F3F3"
    />
    <path
      d="M12.7878 29.0874C12.8879 29.1123 12.9629 29.2249 12.9253 29.3249L12.6128 30.4499C12.5879 30.5499 12.4754 30.6125 12.3754 30.5873C12.2754 30.5624 12.2004 30.4499 12.2378 30.3499L12.5503 29.2249C12.5754 29.1249 12.6754 29.0623 12.7878 29.0873V29.0874ZM12.3754 30.5499C12.4629 30.5749 12.5629 30.5249 12.5879 30.4374L12.9003 29.3123C12.9253 29.2249 12.8754 29.1374 12.7754 29.1123C12.6879 29.0873 12.5879 29.1374 12.5629 29.2249L12.2503 30.3499C12.2378 30.4374 12.2878 30.5249 12.3754 30.5499Z"
      fill="white"
    />
    <path
      d="M12.1755 27.3375C12.2755 27.3749 12.3129 27.4875 12.2755 27.5749L11.2755 29.9875C11.238 30.0751 11.1254 30.125 11.038 30.0751C10.9504 30.025 10.9004 29.925 10.9379 29.8375L11.9379 27.425C11.9753 27.3375 12.088 27.2875 12.1753 27.3375H12.1755Z"
      fill="#F3F3F3"
    />
    <path
      d="M12.1759 27.325C12.224 27.3433 12.2628 27.3799 12.2839 27.4268C12.305 27.4737 12.3066 27.527 12.2885 27.5751L11.2885 29.9875C11.2509 30.0875 11.1384 30.125 11.0259 30.0875C10.9779 30.0692 10.9391 30.0326 10.918 29.9857C10.8969 29.9389 10.8952 29.8856 10.9134 29.8375L11.9134 27.425C11.9634 27.325 12.0759 27.2751 12.1759 27.325V27.325ZM11.0384 30.0626C11.1259 30.1 11.2259 30.0626 11.2634 29.9751L12.2634 27.5626C12.3008 27.4751 12.2509 27.3875 12.1634 27.35C12.0759 27.3126 11.9759 27.35 11.9384 27.4375L10.9384 29.85C10.9134 29.925 10.9509 30.025 11.0384 30.0626Z"
      fill="white"
    />
    <path
      d="M10.4878 28.1377C10.5753 28.1876 10.6127 28.2876 10.5753 28.3751L10.0502 29.4126C10.0002 29.5001 9.90017 29.5377 9.80017 29.4876C9.71269 29.4377 9.67527 29.3377 9.71269 29.2502L10.2378 28.2128C10.2878 28.1251 10.3878 28.0876 10.4878 28.1377Z"
      fill="#F3F3F3"
    />
    <path
      d="M10.4878 28.125C10.5878 28.175 10.6252 28.2876 10.5753 28.3875L10.0502 29.425C10.0002 29.525 9.88766 29.5626 9.78779 29.5125C9.68779 29.4626 9.65024 29.35 9.70031 29.2501L10.2252 28.2127C10.2753 28.1125 10.3878 28.0751 10.4878 28.125ZM9.80031 29.4876C9.88779 29.525 9.98779 29.5 10.0252 29.4125L10.5502 28.3751C10.5877 28.3 10.5502 28.2 10.4753 28.1501C10.3878 28.1127 10.2878 28.1376 10.2502 28.225L9.72521 29.2625C9.68779 29.3376 9.72521 29.4376 9.80031 29.4875V29.4876Z"
      fill="white"
    />
    <path
      d="M10.2376 26.2875C10.3251 26.3499 10.3501 26.4625 10.2877 26.5374L8.8376 28.7C8.78767 28.7874 8.67502 28.7999 8.58767 28.7499C8.50018 28.6874 8.47515 28.575 8.5376 28.4999L9.98767 26.3373C10.0376 26.2499 10.1501 26.2249 10.2376 26.2874V26.2875Z"
      fill="#F3F3F3"
    />
    <path
      d="M10.2378 26.2749C10.3252 26.3375 10.3503 26.4625 10.3002 26.55L8.85028 28.7126C8.78783 28.7999 8.67531 28.825 8.57531 28.7625C8.48783 28.6999 8.4628 28.575 8.51273 28.4874L9.9628 26.325C10.0252 26.2375 10.1503 26.2125 10.2378 26.2749V26.2749ZM8.58783 28.7375C8.66267 28.7874 8.77531 28.7749 8.82525 28.7001L10.2753 26.5375C10.3252 26.4625 10.3003 26.3625 10.2252 26.3125C10.1503 26.2625 10.0378 26.275 9.98783 26.3499L8.53777 28.4999C8.48783 28.575 8.51273 28.6874 8.58783 28.7374V28.7375Z"
      fill="white"
    />
    <path
      d="M8.41266 26.7502C8.48776 26.8127 8.51266 26.9252 8.45021 27.0002L7.72518 27.9127C7.66272 27.9877 7.55021 28.0002 7.47524 27.9377C7.40027 27.8753 7.37524 27.7628 7.43782 27.6877L8.16272 26.7751C8.22518 26.7003 8.33769 26.6877 8.41266 26.7502Z"
      fill="#F3F3F3"
    />
    <path
      d="M8.42571 26.7375C8.5132 26.7999 8.52571 26.925 8.46313 27.0125L7.73823 27.925C7.67578 28.0125 7.55074 28.025 7.46326 27.95C7.37578 27.8874 7.36326 27.7625 7.42584 27.6749L8.15074 26.7625C8.2132 26.6749 8.33823 26.6625 8.42571 26.7375ZM7.48829 27.925C7.56313 27.9874 7.66326 27.975 7.72571 27.8999L8.45074 26.9874C8.50081 26.9126 8.48816 26.8125 8.41332 26.75C8.33823 26.6874 8.23823 26.6999 8.17578 26.775L7.45074 27.6874C7.40081 27.7625 7.41333 27.8749 7.48816 27.925H7.48829Z"
      fill="white"
    />
    <path
      d="M8.53787 24.8749C8.61284 24.9498 8.61284 25.0624 8.53787 25.1249L6.68793 26.9623C6.61284 27.0374 6.50045 27.0249 6.43787 26.9623C6.37542 26.8998 6.3629 26.7748 6.43787 26.7123L8.27529 24.8749C8.35039 24.7998 8.4629 24.7998 8.53787 24.8749V24.8749Z"
      fill="#F3F3F3"
    />
    <path
      d="M8.55085 24.8627C8.58686 24.8993 8.60704 24.9487 8.60704 25.0001C8.60704 25.0515 8.58686 25.1008 8.55085 25.1375L6.70079 26.9752C6.66411 27.0112 6.61477 27.0314 6.56337 27.0314C6.51197 27.0314 6.46262 27.0112 6.42595 26.9752C6.38986 26.9385 6.36963 26.8891 6.36963 26.8376C6.36963 26.7862 6.38986 26.7368 6.42595 26.7001L8.27588 24.8627C8.31256 24.8266 8.36194 24.8064 8.41337 24.8064C8.4648 24.8064 8.51417 24.8266 8.55085 24.8627V24.8627ZM6.43833 26.9625C6.50079 27.025 6.6133 27.025 6.67575 26.9625L8.52582 25.125C8.5884 25.0625 8.5884 24.95 8.52582 24.8876C8.46337 24.825 8.35085 24.825 8.2884 24.8876L6.43833 26.725C6.37588 26.7874 6.37588 26.8876 6.43833 26.9624V26.9625Z"
      fill="white"
    />
    <path
      d="M6.66322 24.975C6.72567 25.0499 6.72567 25.1625 6.6507 25.2249L5.76322 25.975C5.68825 26.0375 5.57574 26.0249 5.51315 25.95C5.4507 25.8749 5.4507 25.7625 5.52567 25.6999L6.41315 24.95C6.48825 24.8874 6.60064 24.8999 6.66322 24.975Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.67514 24.9625C6.75011 25.0502 6.73759 25.1751 6.66262 25.2376L5.77514 25.9876C5.70017 26.0627 5.57514 26.0502 5.50017 25.9627C5.42508 25.8751 5.43759 25.7502 5.51256 25.6876L6.40017 24.9376C6.47501 24.8627 6.60017 24.8752 6.67501 24.9627L6.67514 24.9625ZM5.52508 25.9502C5.58766 26.0251 5.68766 26.0251 5.7625 25.9752L6.65011 25.2251C6.71256 25.1627 6.72508 25.0625 6.66262 24.9877C6.60004 24.9126 6.50004 24.9126 6.42521 24.9627L5.53759 25.7126C5.46263 25.7751 5.46263 25.8752 5.52508 25.95V25.9502Z"
      fill="white"
    />
    <path
      d="M7.16257 23.1748C7.22502 23.2624 7.19999 23.3748 7.1125 23.4249L4.93754 24.8749C4.85005 24.9249 4.73754 24.8998 4.6876 24.8249C4.62502 24.7374 4.65005 24.6249 4.73754 24.5748L6.91263 23.1249C6.98773 23.0749 7.10012 23.0998 7.1627 23.1748H7.16257Z"
      fill="#F3F3F3"
    />
    <path
      d="M7.17487 23.1749C7.23732 23.2625 7.21229 23.3874 7.12481 23.45L4.94984 24.8999C4.86236 24.9625 4.73732 24.9373 4.67487 24.8374C4.61229 24.75 4.63745 24.6249 4.72481 24.5625L6.8999 23.1124C6.98739 23.05 7.11229 23.075 7.17474 23.1749H7.17487ZM4.6999 24.8124C4.74984 24.8874 4.84984 24.9124 4.92481 24.8625L7.09978 23.4124C7.17487 23.3625 7.19978 23.2625 7.13732 23.175C7.08739 23.0999 6.98739 23.075 6.91229 23.1249L4.73732 24.575C4.66236 24.6374 4.64984 24.7374 4.6999 24.8124V24.8124Z"
      fill="white"
    />
    <path
      d="M5.30013 22.9123C5.3502 22.9998 5.32516 23.1123 5.23768 23.1624L4.22516 23.7374C4.13768 23.7873 4.02516 23.7499 3.97523 23.6624C3.92516 23.5749 3.9502 23.4624 4.03768 23.4123L5.0502 22.8374C5.13768 22.7873 5.2502 22.8249 5.30026 22.9123H5.30013Z"
      fill="#F3F3F3"
    />
    <path
      d="M5.31278 22.9001C5.36271 23.0001 5.33768 23.1126 5.2502 23.1627L4.23768 23.7377C4.1502 23.7876 4.02516 23.7502 3.97523 23.6627C3.92516 23.5627 3.9502 23.4502 4.03768 23.4001L5.0502 22.8252C5.13768 22.7751 5.26271 22.8126 5.31265 22.9001H5.31278ZM3.98762 23.6502C4.03768 23.7377 4.13768 23.7627 4.21265 23.7126L5.22516 23.1377C5.30013 23.0876 5.32516 22.9876 5.28774 22.9126C5.23768 22.8252 5.13768 22.8001 5.06271 22.8502L4.0502 23.4252C3.97523 23.4626 3.9502 23.5627 3.98774 23.6502H3.98762Z"
      fill="white"
    />
    <path
      d="M6.13776 21.2502C6.17517 21.3502 6.13776 21.4502 6.03776 21.4876L3.62524 22.4876C3.53776 22.5252 3.42524 22.4752 3.38782 22.3876C3.35027 22.2876 3.38782 22.1876 3.48782 22.1502L5.90034 21.1502C5.98782 21.1128 6.10034 21.1502 6.13776 21.2502V21.2502Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.15027 21.2375C6.18769 21.3375 6.15027 21.4501 6.05027 21.5L3.63776 22.5C3.58968 22.5181 3.53639 22.5164 3.48955 22.4954C3.44271 22.4743 3.40613 22.4355 3.38782 22.3875C3.35027 22.2875 3.38782 22.175 3.48782 22.125L5.90034 21.125C5.94841 21.1069 6.0017 21.1086 6.04854 21.1297C6.09539 21.1508 6.13197 21.1895 6.15027 21.2375V21.2375ZM3.41272 22.375C3.45027 22.4626 3.55027 22.5001 3.62524 22.475L6.03776 21.475C6.12524 21.4375 6.16279 21.3375 6.12524 21.2501C6.08782 21.1626 5.98782 21.125 5.91272 21.1501L3.50021 22.1501C3.41272 22.1875 3.3753 22.2875 3.41272 22.3751V22.375Z"
      fill="white"
    />
    <path
      d="M4.37526 20.6252C4.41268 20.7252 4.36274 20.8252 4.26274 20.8626L3.16274 21.2252C3.07526 21.2626 2.96274 21.2001 2.93771 21.1127C2.90029 21.0127 2.95022 20.9127 3.05022 20.8752L4.15023 20.5127C4.23771 20.4752 4.33771 20.5252 4.37526 20.6252Z"
      fill="#F3F3F3"
    />
    <path
      d="M4.38812 20.6123C4.42567 20.7123 4.37574 20.8248 4.26322 20.8624L3.16322 21.2248C3.06322 21.2623 2.9507 21.1998 2.91315 21.0998C2.87574 20.9998 2.92567 20.8873 3.03832 20.8499L4.13819 20.4873C4.23819 20.4624 4.3507 20.5123 4.38825 20.6125L4.38812 20.6123ZM2.93832 21.0874C2.96335 21.1749 3.06335 21.225 3.15083 21.1999L4.25083 20.8374C4.33832 20.8125 4.37586 20.7125 4.35083 20.625C4.3258 20.5375 4.2258 20.4874 4.13832 20.5125L3.03832 20.875C2.95083 20.9125 2.91328 20.9999 2.93832 21.0874Z"
      fill="white"
    />
    <path
      d="M5.50008 19.1375C5.52498 19.2375 5.46253 19.3375 5.36253 19.35L2.79995 19.85C2.69995 19.875 2.61246 19.7999 2.58756 19.6999C2.56253 19.5999 2.62498 19.4999 2.72498 19.4874L5.28756 18.9874C5.38756 18.975 5.48756 19.0375 5.49995 19.1375H5.50008Z"
      fill="#F3F3F3"
    />
    <path
      d="M5.5248 19.1377C5.54983 19.2503 5.47486 19.3503 5.37486 19.3752L2.81228 19.8752C2.71228 19.9002 2.61228 19.8252 2.58738 19.7127C2.56235 19.6002 2.63731 19.5002 2.73731 19.4753L5.29989 18.9753C5.39976 18.9503 5.49989 19.0252 5.5248 19.1377ZM2.61228 19.7003C2.6248 19.7877 2.71228 19.8503 2.79976 19.8377L5.36235 19.3377C5.44983 19.3252 5.51228 19.2252 5.48738 19.1377C5.47486 19.0503 5.38738 18.9877 5.29976 19.0002L2.73731 19.5002C2.64983 19.5252 2.58738 19.6127 2.61228 19.7002V19.7003Z"
      fill="white"
    />
    <path
      d="M3.90052 18.1752C3.9129 18.275 3.85045 18.3627 3.75045 18.3752L2.60052 18.5126C2.50039 18.5251 2.4129 18.4501 2.40052 18.35C2.388 18.2501 2.45045 18.1627 2.55045 18.15L3.70052 18.0126C3.788 18.0001 3.888 18.075 3.90052 18.1752Z"
      fill="#F3F3F3"
    />
    <path
      d="M3.91312 18.1752C3.9255 18.2876 3.85054 18.3876 3.75054 18.4001L2.6006 18.5377C2.50047 18.5502 2.4006 18.4752 2.38809 18.3627C2.37557 18.2502 2.45054 18.1502 2.55054 18.1377L3.7006 18.0001C3.80047 17.9876 3.9006 18.0627 3.91299 18.1751L3.91312 18.1752ZM2.40047 18.3502C2.41299 18.4377 2.5006 18.5128 2.58809 18.5001L3.73802 18.3627C3.8255 18.3502 3.88809 18.2627 3.87557 18.1752C3.86305 18.0876 3.77557 18.0127 3.68809 18.0252L2.53802 18.1627C2.45054 18.1752 2.38809 18.2627 2.40047 18.3502V18.3502Z"
      fill="white"
    />
    <path
      d="M5.3007 16.9624C5.3007 17.0625 5.22586 17.15 5.12573 17.15H2.51335C2.41335 17.15 2.33838 17.0625 2.33838 16.9625C2.33838 16.8625 2.41335 16.7749 2.51335 16.7749H5.12586C5.22586 16.7749 5.30096 16.8625 5.30096 16.9625L5.3007 16.9624Z"
      fill="#F3F3F3"
    />
    <path
      d="M5.31268 16.9625C5.31268 17.0751 5.22507 17.1625 5.12507 17.1625H2.51268C2.41268 17.1625 2.3252 17.075 2.3252 16.9625C2.3252 16.8501 2.41268 16.7625 2.51268 16.7625H5.1252C5.2252 16.7625 5.31268 16.8501 5.31268 16.9625ZM2.3501 16.9501C2.3501 17.0375 2.42507 17.125 2.51268 17.125H5.12507C5.21255 17.125 5.28765 17.0501 5.28765 16.9501C5.28765 16.8626 5.21255 16.775 5.12507 16.775H2.51268C2.4252 16.7875 2.35023 16.8626 2.35023 16.9501H2.3501Z"
      fill="white"
    />
    <path
      d="M3.91291 15.7001C3.90027 15.8001 3.8253 15.875 3.7253 15.875L2.56272 15.7876C2.46285 15.7751 2.38788 15.6876 2.40027 15.5876C2.41278 15.4876 2.48788 15.4126 2.58788 15.4126L3.75033 15.5001C3.83781 15.5126 3.91291 15.6001 3.91291 15.7001Z"
      fill="#E2E2E2"
    />
    <path
      d="M3.92534 15.6999C3.91282 15.8124 3.82534 15.8874 3.72534 15.8874L2.56275 15.7999C2.46288 15.7874 2.3754 15.6999 2.38792 15.5874C2.4003 15.475 2.48792 15.3999 2.58792 15.3999L3.75037 15.4874C3.85037 15.4999 3.92534 15.5999 3.92534 15.6999ZM2.4003 15.5874C2.38792 15.6749 2.46288 15.7625 2.55037 15.7625L3.71295 15.85C3.8003 15.85 3.8754 15.7874 3.88792 15.6874C3.90043 15.5999 3.82534 15.5124 3.73785 15.5124L2.57527 15.4249C2.48792 15.4249 2.41282 15.4999 2.4003 15.5875V15.5874Z"
      fill="white"
    />
    <path
      d="M5.52497 14.7874C5.49994 14.8874 5.41245 14.95 5.31245 14.9374L2.75 14.4124C2.65 14.3874 2.58742 14.2999 2.61245 14.1999C2.63749 14.0999 2.72497 14.0373 2.82497 14.05L5.38755 14.5625C5.47503 14.5874 5.53749 14.6874 5.52497 14.7874V14.7874Z"
      fill="#F3F3F3"
    />
    <path
      d="M5.53749 14.7874C5.51246 14.8999 5.41246 14.9625 5.31246 14.95L2.75001 14.4375C2.65001 14.4124 2.57504 14.3124 2.60007 14.2001C2.62498 14.0874 2.72498 14.025 2.82498 14.0375L5.38756 14.55C5.48756 14.575 5.55001 14.675 5.53749 14.7874V14.7874ZM2.62498 14.1999C2.61246 14.2874 2.6624 14.3749 2.75001 14.3999L5.31259 14.9124C5.40007 14.925 5.48756 14.875 5.51259 14.7749C5.52498 14.6874 5.47504 14.5999 5.38743 14.5749L2.82511 14.0625C2.72511 14.05 2.65014 14.1124 2.62511 14.1999H2.62498Z"
      fill="white"
    />
    <path
      d="M4.40038 13.2877C4.39464 13.3115 4.38426 13.3339 4.36984 13.3537C4.35543 13.3735 4.33725 13.3903 4.31635 13.403C4.29545 13.4158 4.27224 13.4243 4.24804 13.4281C4.22385 13.4319 4.19914 13.4309 4.17534 13.4251L3.05031 13.1126C2.95031 13.0875 2.90038 12.9875 2.92528 12.8875C2.93102 12.8637 2.94139 12.8413 2.95581 12.8215C2.97023 12.8017 2.98841 12.7849 3.00931 12.7722C3.03021 12.7594 3.05342 12.7509 3.07761 12.7471C3.10181 12.7433 3.12651 12.7443 3.15031 12.7501L4.27534 13.0626C4.37534 13.0875 4.42528 13.1875 4.40038 13.2875V13.2877Z"
      fill="#F3F3F3"
    />
    <path
      d="M4.41226 13.2877C4.38736 13.3876 4.27484 13.4627 4.17484 13.4251L3.04981 13.1126C2.94981 13.0876 2.88723 12.9751 2.91226 12.8752C2.93729 12.7751 3.04981 12.7001 3.14981 12.7376L4.27484 13.0501C4.37484 13.0752 4.43742 13.1752 4.41226 13.2876V13.2877ZM2.94981 12.8749C2.92477 12.9625 2.97484 13.0625 3.06232 13.0874L4.18736 13.4C4.27484 13.425 4.36232 13.3749 4.38736 13.2749C4.41226 13.1874 4.36232 13.0874 4.27484 13.0625L3.14981 12.75C3.06232 12.7375 2.97484 12.7874 2.94981 12.8751V12.8749Z"
      fill="white"
    />
    <path
      d="M6.16253 12.6876C6.12511 12.7875 6.01246 12.825 5.92511 12.7875L3.51246 11.7875C3.42498 11.7501 3.37505 11.6376 3.41246 11.5501C3.45001 11.4501 3.56253 11.4125 3.65001 11.4501L6.06253 12.4501C6.16253 12.4875 6.20008 12.6 6.16253 12.6875V12.6876Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.17525 12.7C6.15694 12.7481 6.12034 12.7869 6.07347 12.808C6.02659 12.829 5.97327 12.8307 5.92518 12.8125L3.51267 11.8125C3.41267 11.7751 3.37525 11.6626 3.41267 11.5501C3.43097 11.5021 3.46757 11.4632 3.51445 11.4421C3.56132 11.4211 3.61465 11.4194 3.66273 11.4376L6.07525 12.4376C6.17525 12.4875 6.21267 12.6002 6.17525 12.7V12.7ZM3.4377 11.5626C3.40028 11.6501 3.4377 11.7501 3.52518 11.7875L5.9377 12.7875C6.02518 12.8251 6.11267 12.775 6.15022 12.6875C6.18763 12.6 6.15022 12.5 6.06273 12.4626L3.65022 11.4626C3.56273 11.4376 3.46273 11.4751 3.4377 11.5626V11.5626Z"
      fill="white"
    />
    <path
      d="M5.35039 11.0002C5.30045 11.0876 5.20045 11.1252 5.11297 11.0876L4.07542 10.5627C3.98794 10.5252 3.95039 10.4127 4.00045 10.3127C4.05039 10.2252 4.15039 10.1876 4.23787 10.2252L5.27529 10.7502C5.36291 10.8002 5.40045 10.9128 5.35039 11.0002Z"
      fill="#F3F3F3"
    />
    <path
      d="M5.36269 11.0124C5.31263 11.1124 5.20011 11.15 5.10011 11.0999L4.06269 10.5749C3.96269 10.525 3.92514 10.4124 3.97521 10.3124C4.02514 10.2124 4.13779 10.1749 4.23766 10.225L5.27508 10.75C5.37521 10.7874 5.41263 10.9126 5.36269 11.0124V11.0124ZM4.01263 10.325C3.97521 10.4124 4.00024 10.5124 4.08772 10.55L5.12514 11.075C5.20024 11.1124 5.30024 11.075 5.35018 10.9999C5.38759 10.9124 5.36269 10.8124 5.27521 10.7749L4.22501 10.2501C4.15005 10.2127 4.05005 10.2376 4.0125 10.3251L4.01263 10.325Z"
      fill="white"
    />
    <path
      d="M7.18745 10.7624C7.125 10.8501 7.025 10.8751 6.93751 10.8125L4.77493 9.37508C4.68758 9.32502 4.67506 9.2125 4.725 9.12502C4.77506 9.03754 4.88758 9.0125 4.97506 9.07496L7.15003 10.525C7.225 10.5751 7.25003 10.6876 7.18745 10.7624Z"
      fill="#F3F3F3"
    />
    <path
      d="M7.20038 10.7751C7.13793 10.8627 7.01289 10.8876 6.92554 10.8377L4.76296 9.38759C4.67548 9.32514 4.65044 9.21263 4.71289 9.11276C4.77548 9.02514 4.90051 9.00011 4.98799 9.05017L7.15044 10.5001C7.25044 10.5627 7.26296 10.6876 7.20038 10.7751ZM4.73793 9.12514C4.68799 9.20011 4.70051 9.31263 4.77535 9.36256L6.95044 10.8128C7.02541 10.8628 7.12541 10.8378 7.17548 10.7628C7.22541 10.6877 7.21289 10.5752 7.13806 10.5253L4.96283 9.07521C4.88786 9.02514 4.78786 9.05017 4.7378 9.12514H4.73793Z"
      fill="white"
    />
    <path
      d="M6.72502 8.94983C6.66256 9.0248 6.55005 9.04983 6.47508 8.98725L5.56256 8.27486C5.4876 8.21228 5.47508 8.09977 5.53753 8.0248C5.59998 7.94983 5.7125 7.9248 5.7876 7.98738L6.69998 8.69977C6.77508 8.76235 6.7876 8.87474 6.72502 8.94983V8.94983Z"
      fill="#F3F3F3"
    />
    <path
      d="M6.73766 8.96262C6.67521 9.05023 6.55017 9.06275 6.46269 9.00017L5.55017 8.28778C5.46269 8.22533 5.45017 8.1003 5.51276 8.01294C5.57521 7.92533 5.70024 7.91281 5.78759 7.97539L6.70011 8.68778C6.78772 8.75036 6.80024 8.87526 6.73766 8.96288V8.96262ZM5.53766 8.0252C5.47521 8.10017 5.48772 8.20017 5.56269 8.26262L6.47521 8.97526C6.55017 9.0252 6.65017 9.01268 6.71263 8.93771C6.77521 8.86275 6.76269 8.76275 6.68772 8.7003L5.77521 7.98765C5.70024 7.93771 5.60011 7.95023 5.53779 8.0252H5.53766Z"
      fill="white"
    />
    <path
      d="M8.58826 9.07525C8.51342 9.15035 8.40078 9.15035 8.33832 9.07525L6.476 7.23783C6.40103 7.16287 6.41342 7.05035 6.476 6.98777C6.55097 6.91293 6.66349 6.91293 6.72594 6.98777L8.576 8.82532C8.65097 8.88777 8.65097 9.0128 8.58852 9.07538L8.58826 9.07525Z"
      fill="#F3F3F3"
    />
    <path
      d="M8.5876 9.08749C8.55093 9.1235 8.50158 9.14368 8.45018 9.14368C8.39878 9.14368 8.34944 9.1235 8.31276 9.08749L6.46257 7.24981C6.42648 7.21312 6.40625 7.16372 6.40625 7.11226C6.40625 7.0608 6.42648 7.0114 6.46257 6.97471C6.49925 6.93866 6.54862 6.91846 6.60005 6.91846C6.65148 6.91846 6.70086 6.93866 6.73754 6.97471L8.5876 8.81226C8.67508 8.88723 8.67508 9.01226 8.5876 9.08723V9.08749ZM6.48786 6.99974C6.42528 7.06232 6.42528 7.17484 6.48786 7.23716L8.33779 9.07471C8.40037 9.13729 8.51276 9.13729 8.57521 9.07471C8.63779 9.01226 8.63779 8.89974 8.57521 8.83729L6.72528 6.98736C6.66283 6.92491 6.5627 6.92491 6.48786 6.99987V6.99974Z"
      fill="white"
    />
    <path
      d="M8.47492 7.21245C8.39995 7.27503 8.28743 7.27503 8.22485 7.19994L7.46227 6.32497C7.39995 6.25 7.41234 6.13748 7.48743 6.07503C7.56227 6.01245 7.67492 6.01245 7.73737 6.08742L8.49995 6.96252C8.5624 7.02497 8.54989 7.13748 8.47492 7.21245Z"
      fill="#F3F3F3"
    />
    <path
      d="M8.48764 7.21266C8.40016 7.28763 8.28764 7.27524 8.21255 7.20015L7.45009 6.32518C7.37513 6.25021 7.38764 6.12518 7.47513 6.05021C7.56261 5.97511 7.67513 5.98763 7.75009 6.06273L8.51267 6.93756C8.57513 7.02518 8.57513 7.15021 8.48764 7.21266V7.21266ZM7.48764 6.07524C7.41255 6.13769 7.41254 6.23769 7.46261 6.31266L8.22519 7.18763C8.28764 7.25021 8.38764 7.26273 8.46261 7.20015C8.53758 7.13769 8.53758 7.03756 8.48764 6.96273L7.72506 6.08763C7.66261 6.01279 7.56248 6.01279 7.48764 6.07524V6.07524Z"
      fill="white"
    />
    <path
      d="M10.2752 7.68744C10.1877 7.74989 10.0752 7.72486 10.0251 7.63737L8.56267 5.47479C8.51261 5.38731 8.53764 5.27479 8.61261 5.22486C8.70009 5.16241 8.81261 5.18744 8.86267 5.27492L10.3126 7.43737C10.3752 7.52486 10.3502 7.63737 10.2752 7.68731V7.68744Z"
      fill="#F3F3F3"
    />
    <path
      d="M10.2752 7.7001C10.1877 7.76268 10.0627 7.73752 10.0001 7.65017L8.55015 5.48759C8.4877 5.4001 8.51273 5.2752 8.6126 5.21275C8.70008 5.15017 8.82512 5.1752 8.8877 5.26268L10.3376 7.42514C10.4001 7.52514 10.3751 7.63765 10.2752 7.7001ZM8.62499 5.23765C8.55002 5.28759 8.52499 5.38759 8.57505 5.46268L10.025 7.62514C10.0751 7.7001 10.1751 7.71262 10.2624 7.66256C10.3375 7.61262 10.3625 7.51262 10.3125 7.43765L8.86266 5.28759C8.8126 5.21275 8.70008 5.18759 8.62525 5.23765H8.62499Z"
      fill="white"
    />
    <path
      d="M10.5378 5.83755C10.4503 5.88749 10.3378 5.86258 10.2879 5.7751L9.7128 4.76258C9.66286 4.6751 9.70028 4.56258 9.78789 4.51252C9.87538 4.46258 9.98789 4.48749 10.0378 4.5751L10.6128 5.58749C10.6503 5.67497 10.6253 5.78749 10.538 5.83755H10.5378Z"
      fill="#F3F3F3"
    />
    <path
      d="M10.5378 5.85007C10.4378 5.9 10.3253 5.8751 10.2753 5.78749L9.70024 4.7751C9.6503 4.68749 9.67534 4.56258 9.77534 4.51252C9.87534 4.46258 9.98785 4.48749 10.0378 4.5751L10.6128 5.58749C10.6628 5.67497 10.6378 5.78749 10.5379 5.85007H10.5378ZM9.78785 4.52504C9.71276 4.57497 9.67534 4.67497 9.72527 4.75007L10.3004 5.76258C10.3503 5.83742 10.4503 5.86258 10.5253 5.82504C10.6004 5.7751 10.6378 5.67497 10.5879 5.6L10.0128 4.58749C9.97534 4.51265 9.86282 4.47497 9.78785 4.52504V4.52504Z"
      fill="white"
    />
    <path
      d="M12.2 6.66252C12.1001 6.69994 12 6.66252 11.9626 6.57504L10.9501 4.16239C10.9126 4.07491 10.9626 3.96239 11.0501 3.92485C11.1501 3.88743 11.2501 3.92485 11.2875 4.01233L12.3 6.42485C12.3375 6.51233 12.2876 6.62485 12.2 6.66227V6.66252Z"
      fill="#F3F3F3"
    />
    <path
      d="M12.2006 6.675C12.1007 6.71242 11.9882 6.675 11.9381 6.57487L10.9256 4.16249C10.9074 4.1144 10.9091 4.06108 10.9302 4.0142C10.9513 3.96733 10.9901 3.93073 11.0381 3.91242C11.1381 3.875 11.2506 3.91242 11.3007 4.01242L12.3131 6.42494C12.3506 6.51242 12.3007 6.62494 12.2007 6.675H12.2006ZM11.0632 3.93746C10.9757 3.97487 10.9381 4.075 10.9757 4.14997L11.9881 6.56236C12.0255 6.64984 12.1255 6.68726 12.213 6.64984C12.3004 6.61242 12.3381 6.51229 12.3004 6.43733L11.2881 4.02481C11.238 3.93733 11.1505 3.89978 11.063 3.93733L11.0632 3.93746Z"
      fill="white"
    />
    <path
      d="M12.8127 4.88769C12.7127 4.92511 12.6127 4.87504 12.5753 4.77517L12.2127 3.67504C12.1753 3.58756 12.2378 3.47504 12.3252 3.45014C12.4127 3.42511 12.5252 3.46265 12.5627 3.56265L12.9252 4.66265C12.9627 4.75014 12.9127 4.85014 12.8127 4.88756V4.88769Z"
      fill="#F3F3F3"
    />
    <path
      d="M12.8251 4.89998C12.7771 4.9181 12.7238 4.91643 12.6769 4.89534C12.6301 4.87425 12.5935 4.83546 12.5752 4.78746L12.2126 3.68746C12.1752 3.58746 12.2377 3.47508 12.3378 3.43753C12.3859 3.41941 12.4391 3.42108 12.486 3.44217C12.5328 3.46325 12.5694 3.50204 12.5877 3.55004L12.9502 4.65004C12.9752 4.75004 12.9251 4.86256 12.8251 4.89998V4.89998ZM12.3377 3.46256C12.2502 3.48746 12.2001 3.58746 12.2251 3.67495L12.5877 4.77508C12.6126 4.86256 12.7126 4.89998 12.8002 4.87495C12.8877 4.85004 12.9377 4.75004 12.9126 4.66256L12.5502 3.56243C12.5438 3.54121 12.533 3.52157 12.5186 3.50479C12.5041 3.48801 12.4863 3.47446 12.4662 3.46502C12.4462 3.45559 12.4244 3.45049 12.4022 3.45004C12.3801 3.44959 12.3581 3.45381 12.3377 3.46243V3.46256Z"
      fill="white"
    />
    <path
      d="M14.2883 6.02522C14.1883 6.05025 14.0883 5.9878 14.0758 5.88767L13.5508 3.32522C13.5257 3.22522 13.6008 3.13774 13.7007 3.11271C13.8008 3.08767 13.9007 3.15012 13.9132 3.25025L14.4384 5.81283C14.4508 5.90019 14.3883 6.00019 14.2883 6.02522V6.02522Z"
      fill="#F3F3F3"
    />
    <path
      d="M14.2875 6.03762C14.1749 6.06265 14.0749 5.98755 14.0499 5.88755L13.5249 3.3251C13.4998 3.2251 13.5749 3.11258 13.6749 3.10007C13.7875 3.07503 13.8875 3.15013 13.9124 3.25013L14.4375 5.81271C14.4624 5.91258 14.4 6.01271 14.2875 6.03762V6.03762ZM13.7 3.1251C13.6124 3.13762 13.5499 3.2251 13.5624 3.3251L14.0875 5.88768C14.1 5.97503 14.1998 6.03762 14.2875 6.01258C14.3749 6.00007 14.4374 5.91258 14.4249 5.81258L13.8998 3.25C13.8749 3.16265 13.7875 3.11258 13.6998 3.1251H13.7Z"
      fill="white"
    />
    <path
      d="M15.2378 4.40002C15.1378 4.41254 15.0504 4.35009 15.0253 4.25009L14.8754 3.10002C14.8629 3.00002 14.9378 2.91254 15.038 2.90002C15.1378 2.88751 15.2253 2.95009 15.2504 3.05009L15.4003 4.20002C15.4128 4.30002 15.3378 4.38751 15.2378 4.40002V4.40002Z"
      fill="#F3F3F3"
    />
    <path
      d="M15.2381 4.4126C15.1256 4.42512 15.0256 4.35015 15.0131 4.25002L14.8632 3.10009C14.8507 3.00009 14.9256 2.90009 15.0381 2.88757C15.1507 2.87518 15.2507 2.95015 15.2632 3.05015L15.4131 4.20009C15.4256 4.30009 15.3507 4.40009 15.2381 4.4126V4.4126ZM15.0507 2.9126C14.9632 2.92512 14.8881 3.0126 14.9006 3.10009L15.0507 4.25015C15.0632 4.33764 15.1507 4.40009 15.2381 4.38757C15.3256 4.37518 15.4007 4.28757 15.3882 4.20009L15.2381 3.05015C15.2256 2.96267 15.1381 2.90009 15.0507 2.9126V2.9126Z"
      fill="white"
    />
    <path
      d="M16.5881 31.7875C24.7619 31.7875 31.3881 25.1613 31.3881 16.9875C31.3881 8.81369 24.7619 2.1875 16.5881 2.1875C8.41427 2.1875 1.78809 8.81369 1.78809 16.9875C1.78809 25.1613 8.41427 31.7875 16.5881 31.7875Z"
      fill="url(#paint2_linear_2429_35673)"
      fillOpacity="0.2"
    />
    <g filter="url(#filter1_d_2429_35673)">
      <path
        d="M26.8377 7.6001L14.9001 15.2502H14.8876V15.2625L14.8751 15.2751L7.42529 27.5627L18.3752 18.7502L18.3877 18.7378V18.7253L26.8378 7.60023L26.8377 7.6001Z"
        fill="black"
        fillOpacity="0.05"
      />
    </g>
    <path
      d="M26.6125 6.88745L14.8125 15.2749L18.3125 18.7499L26.6125 6.88745Z"
      fill="#CD151E"
    />
    <path
      d="M14.8252 15.25L16.5878 16.9875L26.6127 6.88745L14.8252 15.25Z"
      fill="#FA5153"
    />
    <path
      d="M14.8249 15.2501L18.3249 18.725L6.5249 27.1126L14.8249 15.25V15.2501Z"
      fill="#ACACAC"
    />
    <path
      d="M6.5249 27.1126L16.5874 16.9875L14.8248 15.25L6.5249 27.1126Z"
      fill="#EEEEEE"
    />
    <defs>
      <filter
        id="filter0_d_2429_35673"
        x="0.25"
        y="0.875"
        width="32.5"
        height="32.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.125" />
        <feGaussianBlur stdDeviation="0.125" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.266007 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2429_35673"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2429_35673"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2429_35673"
        x="7.17529"
        y="7.4126"
        width="19.9126"
        height="20.4626"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.0625" />
        <feGaussianBlur stdDeviation="0.125" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2429_35673"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2429_35673"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2429_35673"
        x1="16.5"
        y1="33"
        x2="16.5"
        y2="1"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.25" stopColor="#DBDBDA" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2429_35673"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.5796 13.749) scale(18.0655)"
      >
        <stop stopColor="#2ABCE1" />
        <stop offset="0.11363" stopColor="#2ABBE1" />
        <stop offset="1" stopColor="#3375F8" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_2429_35673"
        x1="16.3069"
        y1="12.7556"
        x2="9.39913"
        y2="25.1488"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
);
