import CheckOkIconGreen from '../assets/images/CheckOkIconGreen';

const AvatarView = ({ imgSrc }: any) => (
  <div className="consultation-detail-avatar-container">
    <img className="consultation-detail-avatar" src={imgSrc}></img>
    <CheckOkIconGreen></CheckOkIconGreen>
  </div>
);

export default AvatarView;
