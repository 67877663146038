import ProfileDetailContainer from "../../../containers/Appointment/ProfileDetail";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";
import { Namespace } from "../../../i18n/namespaces";

const ProfileDetail = ({ profile, appointment }: any) => {
	const { t } = useTranslation(Namespace.Appointment);

	if (!profile) {
		return <Loading />;
	}

	return (
		<ProfileDetailContainer
			profile={profile}
			title={t('profile_details.verify')}
			appointment={appointment}
		/>
	);
};

export default ProfileDetail;
