export default (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2552_40065)">
      <path
        d="M15.9738 0.00429269C15.9738 0.00429269 25.3522 -0.416564 30.3244 9.01011H15.1758C15.1758 9.01011 12.317 8.91797 9.87491 12.3862C9.1734 13.8419 8.41935 15.3414 9.26554 18.2966C8.04655 16.2314 2.79395 7.08538 2.79395 7.08538C2.79395 7.08538 6.49885 0.376962 15.9737 0.00429269H15.9738Z"
        fill="#EF3F36"
      />
      <path
        d="M29.8374 23.9351C29.8374 23.9351 25.5098 32.2701 14.8643 31.8579C16.1796 29.5823 22.4407 18.7394 22.4407 18.7394C22.4407 18.7394 23.9534 16.3104 22.1688 12.4608C21.2611 11.1235 20.336 9.72487 17.3547 8.97941C19.753 8.95762 30.2978 8.97941 30.2978 8.97941C30.2978 8.97941 34.2482 15.5431 29.8374 23.9351Z"
        fill="#FCD900"
      />
      <path
        d="M2.17551 24.0009C2.17551 24.0009 -2.87987 16.0868 2.80243 7.07227C4.11344 9.34788 10.3745 20.1908 10.3745 20.1908C10.3745 20.1908 11.725 22.7163 15.9472 23.0932C17.5564 22.9749 19.2313 22.874 21.371 20.6687C20.1914 22.7558 14.8992 31.8755 14.8992 31.8755C14.8992 31.8755 7.23512 32.0158 2.17538 24.0009H2.17551Z"
        fill="#61BC5B"
      />
      <path
        d="M14.8604 31.9368L16.9912 23.045C16.9912 23.045 19.3325 22.8607 21.2968 20.708C20.0778 22.8521 14.8604 31.9368 14.8604 31.9368Z"
        fill="#5AB055"
      />
      <path
        d="M8.83594 16.0692C8.83594 12.1495 12.0148 8.9707 15.9345 8.9707C19.8541 8.9707 23.033 12.1495 23.033 16.0692C23.033 19.989 19.8541 23.1677 15.9345 23.1677C12.0148 23.1634 8.83594 19.989 8.83594 16.0692Z"
        fill="white"
      />
      <path
        d="M10.0239 16.0692C10.0239 12.8072 12.6678 10.1589 15.9343 10.1589C19.1963 10.1589 21.8446 12.8029 21.8446 16.0692C21.8446 19.3314 19.2008 21.9796 15.9343 21.9796C12.6722 21.9796 10.0239 19.3314 10.0239 16.0692Z"
        fill="url(#paint0_linear_2552_40065)"
      />
      <path
        d="M30.2937 8.98389L21.5202 11.5576C21.5202 11.5576 20.1961 9.61518 17.3506 8.98389C19.8191 8.97069 30.2937 8.98389 30.2937 8.98389Z"
        fill="#EACA05"
      />
      <path
        d="M9.09458 17.9722C7.8624 15.8369 2.79395 7.08545 2.79395 7.08545L9.29181 13.5131C9.29181 13.5131 8.62529 14.8855 8.87531 16.8497L9.09446 17.9722H9.09458Z"
        fill="#DF3A32"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2552_40065"
        x1="15.934"
        y1="10.2424"
        x2="15.934"
        y2="21.6238"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#86BBE5" />
        <stop offset="1" stopColor="#1072BA" />
      </linearGradient>
      <clipPath id="clip0_2552_40065">
        <rect
          width="31.88"
          height="32"
          fill="white"
          transform="translate(0.0600586)"
        />
      </clipPath>
    </defs>
  </svg>
);
