import { usePatient } from '../usePatient';
import Loading from '../../../pages/Loading';
import ErrorView from '../../../pages/Error';
import TrackerCall from '../../../utils/TrackingCall';
import TrackerSdk from '../../../utils/TrackingSdk';
import { useParams } from 'react-router-dom';

const AuthorizedAppointment = ({ children }: any) => {
  const { profile, error }: { profile: any; error: any } = usePatient();
  const { id, apiKey } = useParams();

  TrackerCall.setParams({
    consultationId: id,
    consultationType: 'appointment',
    apiKey,
  });
  TrackerSdk.setParams({
    consultationId: id,
    consultationType: 'appointment',
    apiKey,
  });

  if (error) {
    const state =
      error?.status === 400
        ? 'notAvailable'
        : error?.status === 401
          ? 'expired'
          : 'generic';
    return <ErrorView state={state} />;
  }
  if (!profile) {
    return <Loading />;
  }

  return children;
};

export default AuthorizedAppointment;
