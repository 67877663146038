import { useTranslation } from "react-i18next";
import Connection from "../../assets/icons/Connection";
import SandClock from "../../assets/icons/SandClock";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { Namespace } from "../../i18n/namespaces";

const ErrorView = ({
  onClose,
  onConfirm,
  title,
  subtitle,
  button,
  isScreenBlocked,
  buttonCancel,
  onCancel,
  type = 'warning',
}: {
  onClose?: () => void;
  onConfirm: () => void;
  title: string;
  subtitle: string;
  button: string;
  isScreenBlocked: boolean;
  buttonCancel?: boolean;
  onCancel?: () => void;
  type?: string;
}) => {
	const { t } = useTranslation(Namespace.Error);

	return (
		<Modal
			onClose={onClose}
			isScreenBlocked={isScreenBlocked}
			className="rounded-xl"
		>
			<div className="flex flex-col items-center justify-center px-8 py-10">
				{
					{
						warning: (
							<div className="flex justify-center items-center bg-negative-light rounded-lg w-20 h-20">
								<Connection className="h-9 text-negative-dark" />
							</div>
						),
						waiting: (
							<div className="flex justify-center items-center bg-blue-light rounded-lg w-20 h-20">
								<SandClock className="h-9 text-[#4A1EA7]" />
							</div>
						),
					}[type]
				}
				<div className="flex flex-col justify-center align-center py-10">
					<p className="flex text-center justify-center mb-5 text-2xl text-primary font-lighter text-dark">
						{title}
					</p>
					<p className="flex justify-center text-center font-lighter text-gray-dark">
						{subtitle}
					</p>
				</div>
				<Button onClick={onConfirm}>{button}</Button>
				{buttonCancel && (
					<button
						onClick={onCancel}
						className="w-fit m-auto mt-6 flex flex-col underline-offset-4 normal-case text-gray-dark tracking-normal font-medium"
					>
						{t('cancel_vc')}
						<div className="w-full border border-gray-dark -translate-y-1" />
					</button>
				)}
			</div>
		</Modal>
	);
};
export default ErrorView;
