import AppLogo from '../../../assets/images/AppLogo';
import ProfessionalImage from '../../../assets/images/ProfessionalImage';
import Button from '../../../components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import TrackerSdk from '../../../utils/TrackingSdk';
import { useAppointment } from '../useAppointment';
import useWidget from '../../VideoCall/useWidget';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../i18n/namespaces';

function Reporting({}: any) {
  const { id, apiKey, jwt, type } = useParams();
  const { appointment }: { appointment: any } = useAppointment();
  const { hideVideoCall, exitVideoCall } = useWidget();
  const navigate = useNavigate();
  const { t } = useTranslation(Namespace.Appointment);

  useEffect(() => {
    TrackerSdk.pageView('consultation completed pageview');
    window.parent.postMessage({ command: 'videocall_leave' }, '*');
  }, []);

  return (
    <div className="bg-background p-6 flex justify-center">
      <div className="max-w-[800px]">
        {type !== 'i' && (
          <div className="py-6 mb-6 sm:mb-0">
            <AppLogo />
          </div>
        )}
        <ProfessionalImage className="my-3" />
        <div>
          <div className="max-w-[400px] mx-auto">
            <h1 className="text-title text-2xl mb-4 text-center font-lighter">
              {t('reporting.title')}
            </h1>

            <p className="text-text text-sm text-center font-lighter mb-12">
              {t('reporting.subtitle')}
            </p>
          </div>

          <Button
            onClick={() => {
              TrackerSdk.event(
                'consultation completed see consultation summary button click',
              );
              if (type === 'i') {
                hideVideoCall(appointment?.from?.hash);
              } else {
                navigate(`/${type}/${id}/${apiKey}/${jwt}/documentation`);
              }
            }}
            className="w-full mb-4"
          >
            {t(`reporting.${type === 'i' ? 'go_to_chat' : 'see_summary'}`)}
          </Button>
          {type && ['i'].includes(type) && (
            <Button
              variant="custom"
              onClick={exitVideoCall}
              className="w-fit m-auto flex flex-col mb-4 decoration-2 underline underline-offset-4 normal-case text-gray-dark tracking-normal font-medium"
            >
              {t('reporting.go_back')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Reporting;
