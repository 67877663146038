export default (props: any) => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.12"
      d="M30.45 3.5H11.55C10.5699 3.5 10.0799 3.5 9.70552 3.69074C9.37623 3.85852 9.10852 4.12623 8.94074 4.45552C8.75 4.82986 8.75 5.31991 8.75 6.3V9.9304C8.75 10.7865 8.75 11.2145 8.84671 11.6173C8.93244 11.9744 9.07386 12.3158 9.26576 12.629C9.4822 12.9822 9.78487 13.2849 10.3902 13.8902L15.5201 19.0201C16.2131 19.7131 16.5596 20.0596 16.6895 20.4592C16.8037 20.8107 16.8037 21.1893 16.6895 21.5408C16.5596 21.9404 16.2131 22.2869 15.5201 22.9799L10.3902 28.1098C9.78487 28.7151 9.48221 29.0178 9.26576 29.371C9.07386 29.6842 8.93244 30.0256 8.84671 30.3827C8.75 30.7855 8.75 31.2135 8.75 32.0696V35.7C8.75 36.6801 8.75 37.1701 8.94074 37.5445C9.10852 37.8738 9.37623 38.1415 9.70552 38.3093C10.0799 38.5 10.5699 38.5 11.55 38.5H30.45C31.4301 38.5 31.9201 38.5 32.2945 38.3093C32.6238 38.1415 32.8915 37.8738 33.0593 37.5445C33.25 37.1701 33.25 36.6801 33.25 35.7V32.0696C33.25 31.2135 33.25 30.7855 33.1533 30.3827C33.0676 30.0256 32.9261 29.6842 32.7342 29.371C32.5178 29.0178 32.2151 28.7151 31.6098 28.1098L26.4799 22.9799C25.7869 22.2869 25.4404 21.9404 25.3105 21.5408C25.1963 21.1893 25.1963 20.8107 25.3105 20.4592C25.4404 20.0596 25.7869 19.7131 26.4799 19.0201L31.6098 13.8902C32.2151 13.2849 32.5178 12.9822 32.7342 12.629C32.9261 12.3158 33.0676 11.9744 33.1533 11.6173C33.25 11.2145 33.25 10.7865 33.25 9.9304V6.3C33.25 5.31991 33.25 4.82986 33.0593 4.45552C32.8915 4.12623 32.6238 3.85852 32.2945 3.69074C31.9201 3.5 31.4301 3.5 30.45 3.5Z"
      fill="currentColor"
    />
    <path
      d="M15.75 32.375H26.25M11.55 3.5H30.45C31.4301 3.5 31.9201 3.5 32.2945 3.69074C32.6238 3.85852 32.8915 4.12623 33.0593 4.45552C33.25 4.82986 33.25 5.31991 33.25 6.3V9.9304C33.25 10.7865 33.25 11.2145 33.1533 11.6173C33.0676 11.9744 32.9261 12.3158 32.7342 12.629C32.5178 12.9822 32.2151 13.2849 31.6098 13.8902L26.4799 19.0201C25.7869 19.7131 25.4404 20.0596 25.3105 20.4592C25.1963 20.8107 25.1963 21.1893 25.3105 21.5408C25.4404 21.9404 25.7869 22.2869 26.4799 22.9799L31.6098 28.1098C32.2151 28.7151 32.5178 29.0178 32.7342 29.371C32.9261 29.6842 33.0676 30.0256 33.1533 30.3827C33.25 30.7855 33.25 31.2135 33.25 32.0696V35.7C33.25 36.6801 33.25 37.1701 33.0593 37.5445C32.8915 37.8738 32.6238 38.1415 32.2945 38.3093C31.9201 38.5 31.4301 38.5 30.45 38.5H11.55C10.5699 38.5 10.0799 38.5 9.70552 38.3093C9.37623 38.1415 9.10852 37.8738 8.94074 37.5445C8.75 37.1701 8.75 36.6801 8.75 35.7V32.0696C8.75 31.2135 8.75 30.7855 8.84671 30.3827C8.93244 30.0256 9.07386 29.6842 9.26576 29.371C9.48221 29.0178 9.78487 28.7151 10.3902 28.1098L15.5201 22.9799C16.2131 22.2869 16.5596 21.9404 16.6895 21.5408C16.8037 21.1893 16.8037 20.8107 16.6895 20.4592C16.5596 20.0596 16.2131 19.7131 15.5201 19.0201L10.3902 13.8902C9.78487 13.2849 9.4822 12.9822 9.26576 12.629C9.07386 12.3158 8.93244 11.9744 8.84671 11.6173C8.75 11.2145 8.75 10.7865 8.75 9.9304V6.3C8.75 5.31991 8.75 4.82986 8.94074 4.45552C9.10852 4.12623 9.37623 3.85852 9.70552 3.69074C10.0799 3.5 10.5699 3.5 11.55 3.5Z"
      stroke="currentColor"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
