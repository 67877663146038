import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import ChainedBackend from 'i18next-chained-backend';

import es from './locales/es.json';
import en from './locales/en.json';
import pt from './locales/pt.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(ChainedBackend)
  .init({
    detection: {
      order: ['localStorage', 'navigator'],
    },
    resources: { en, es, pt },
    supportedLngs: ['es', 'en', 'pt'],
    fallbackLng: 'es',

    interpolation: {
      escapeValue: false,
    },

    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        { expirationTime: 7 * 24 * 60 * 60 * 1000 },
        { loadPath: `/locales/{{lng}}/{{ns}}.json` },
      ],
    },
  });

i18n.changeLanguage();

export default i18n;
