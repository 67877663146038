export default (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9699 4.96999C11.1108 4.83588 11.2982 4.76173 11.4927 4.76321C11.6872 4.76468 11.8735 4.84165 12.0124 4.97787C12.1512 5.1141 12.2317 5.29893 12.2368 5.49336C12.2419 5.68779 12.1713 5.87661 12.0399 6.01999L8.04991 11.01C7.9813 11.0839 7.8985 11.1432 7.80645 11.1844C7.71439 11.2255 7.61498 11.2477 7.51416 11.2496C7.41334 11.2514 7.31318 11.2329 7.21967 11.1952C7.12616 11.1575 7.04121 11.1013 6.96991 11.03L4.32391 8.38399C4.25023 8.31532 4.19112 8.23252 4.15013 8.14052C4.10914 8.04853 4.0871 7.94921 4.08532 7.84851C4.08355 7.74781 4.10207 7.64778 4.13979 7.55439C4.17751 7.461 4.23366 7.37617 4.30488 7.30495C4.37609 7.23373 4.46093 7.17758 4.55432 7.13986C4.6477 7.10214 4.74773 7.08362 4.84844 7.08539C4.94914 7.08717 5.04845 7.10921 5.14045 7.15021C5.23245 7.1912 5.31525 7.2503 5.38391 7.32399L7.47791 9.41699L10.9509 4.99199C10.9572 4.98429 10.9638 4.97694 10.9709 4.96999H10.9699Z"
      fill="currentColor"
    />
  </svg>
);
