import { useTranslation } from "react-i18next";
import AppLogo from "../../assets/images/AppLogo";
import { Namespace } from "../../i18n/namespaces";

const Finished = () => {
  const { t } = useTranslation(Namespace.Consultation);

  return (
    <div className="bg-background">
      <div className="relative max-h-screen h-screen px-6 grid content-center max-w-[800px] mx-auto">
        <div>
          <AppLogo />
          <h1 className="text-title text-3xl mb-4 text-center sm:text-start">
            {t('finished.title')}
          </h1>
          <p className="text-text text-center sm:text-start">
            {t('finished.subtitle')}
          </p>
        </div>
      </div>
    </div>
  )
};

export default Finished;
