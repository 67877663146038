import { FC } from 'react';
import Button from '../../Button';
import TriangleAlert from '../../../assets/icons/TriangleAlert';
import Modal from '..';

export interface Props {
  title?: string;
  text?: string;
  onConfirmText: string;
  onCancelText: string;
  backdropTransparent?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose?: () => void;
}

const Confirmation: FC<Props> = ({
  title,
  text,
  onConfirmText,
  onCancelText,
  backdropTransparent = false,
  onConfirm,
  onCancel,
  onClose,
}) => {
  const handleOnClose = (value?: unknown) =>
    value === 'confirm' ? onConfirm() : onCancel();

  return (
    <Modal
      allowOutsideClose={false}
      onClose={handleOnClose}
      width="narrow"
      backdropTransparent={backdropTransparent}
    >
      {({ onClose }: { onClose: (value: string) => void }) => (
        <>
          <Modal.Body>
            <div className="flex flex-col items-center justify-center w-full h-full text-center">
              <div className="pb-10 center">
                <TriangleAlert />
              </div>
              <p className="mb-5 text-2xl text-primary font-lighter">{title}</p>
              <p className="font-lighter">{text}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="text-xs whitespace-nowrap"
              onClick={() => onClose('confirm')}
            >
              {onConfirmText}
            </Button>
            <Button
              className="text-xs whitespace-nowrap bg-secundary text-primary"
              variant="custom"
              onClick={() => onClose('reverse')}
            >
              {onCancelText}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default Confirmation;
