import CallControls from '../../components/CallControls';
import WebCam from 'react-webcam';
import AppLogo from '../../assets/images/AppLogo';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../i18n/namespaces';

const Waiting = ({ controls }: any) => {
  const { t } = useTranslation(Namespace.Consultation);

  return (
    <div className="bg-background">
      <div className="relative max-h-screen h-screen p-6 grid content-center max-w-[800px] mx-auto">
        <div>
          <AppLogo />
          <h1 className="text-title text-3xl mb-4 text-center">
            {t('waiting.title')}
          </h1>
          <p className="text-text text-lg mb-8 text-center">
            {t('waiting.subtitle')}
          </p>
        </div>

        <div className="mb-8 relative rounded-lg overflow-hidden w-full aspect-video bg-slate-900 h-96 sm:h-auto">
          {controls.isVideoEnabled ? (
            <WebCam
              className="w-full h-full"
              videoConstraints={{ aspectRatio: 16 / 9, facingMode: 'user' }}
            />
          ) : (
            <div className="w-full aspect-video bg-slate-900"></div>
          )}
          <div className="absolute w-full bottom-0">
            <CallControls
              isAudioEnabled={controls.isAudioEnabled}
              isVideoEnabled={controls.isVideoEnabled}
              onAudioToggle={controls.toggleAudio}
              onVideoToggle={controls.toggleVideo}
            />
          </div>
        </div>

        <p className="text-text text-xs mb-8 text-center">
          {t('waiting.get_in_contact_with')}{' '}
          <a
            className="hover:underline font-medium"
            href="mailto:support@mediquo.com"
          >
            support@mediquo.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Waiting;
