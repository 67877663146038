export default (props: any) => {
  return (
    <svg
      width="10"
      height="19"
      viewBox="0 0 10 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 9.50541C0 9.72162 0.0744681 9.90541 0.234043 10.0568L8.74468 18.2838C8.88298 18.4243 9.06383 18.5 9.26596 18.5C9.68085 18.5 10 18.1865 10 17.7541C10 17.5486 9.91489 17.3649 9.79787 17.2351L1.79787 9.50541L9.79787 1.77568C9.91489 1.63514 10 1.45135 10 1.24595C10 0.824324 9.68085 0.5 9.26596 0.5C9.06383 0.5 8.88298 0.575676 8.74468 0.716216L0.234043 8.95405C0.0744681 9.1054 0 9.28919 0 9.50541Z"
        fill="#546E7A"
      />
    </svg>
  );
};
