import { useParams } from 'react-router-dom';
import { getTemplate } from '../../utils/useTheme';
import { cn } from '../../utils/cn';

export interface AppLogoProps {
  center?: boolean;
}

export default ({ center = true }: AppLogoProps) => {
  const { apiKey, id } = useParams();
  // @ts-ignore
  const { template }: { template: { logo: string } } = getTemplate({
    apiKey,
    appointmentId: id,
  });

  if (template) {
    return (
      <img
        className={cn('max-w-[90px] h-auto', center ? 'mx-auto' : 'ml-0')}
        src={template?.logo}
        alt={'logo'}
      />
    );
  }

  return <div className="max-w-[90px] h-12 sm:ml-0" />;
};
