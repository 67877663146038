import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';
import jwt_decode from 'jwt-decode';

export const hasSession = ({ id }: { id: string }) =>
  !!Cookies.get(`session_${id}`);

export const getSession = ({ id }: { id: string }) =>
  Cookies.get(`session_${id}`);

export const createSession = ({
  id,
  accessToken,
}: {
  id: string;
  accessToken: string;
}) => {
  // @ts-ignore
  const hash = jwt_decode(accessToken).sub;
  Sentry.setUser({ id: hash });
  Cookies.set(`session_${id}`, accessToken);
};
