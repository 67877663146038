import { ErrorStates } from '../../utils/states';
import ErrorNotAvailable from '../../assets/images/ErrorNotAvailable';
import ErrorGeneric from '../../assets/images/ErrorGeneric';
import AppLogo from '../../assets/images/AppLogo';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../i18n/namespaces';

const rootStyles =
  'bg-background max-h-screen h-screen p-6 grid max-w-[800px] mx-auto';

function ErrorView({ state }: any) {
  const { t } = useTranslation(Namespace.Error);

  switch (state) {
    case ErrorStates.notAvailable:
      return (
        <div className={rootStyles}>
          <AppLogo />
          <div className="relative">
            <ErrorViewContainer
              state={state}
              title={t('appointment.unavailable.title')}
              subtitle={t('appointment.unavailable.subtitle')}
            />
          </div>
        </div>
      );
    case ErrorStates.expired:
      return (
        <div className={rootStyles}>
          <AppLogo />
          <div className="relative">
            <ErrorViewContainer
              state={state}
              title={t('appointment.expired.title')}
              subtitle={t('appointment.expired.subtitle')}
            />
          </div>
        </div>
      );
    default:
      return (
        <div className={rootStyles}>
          <AppLogo />
          <div className="relative">
            <ErrorViewContainer
              state={state}
              title={t('generic.title')}
              subtitle={t('generic.subtitle')}
            />
          </div>
        </div>
      );
  }
}

const ErrorViewContainer = ({ state, title, subtitle }: any) => {
  return (
    <div className="error-view-container">
      {state === ErrorStates.notAvailable || state === ErrorStates.expired ? (
        <ErrorNotAvailable />
      ) : (
        <ErrorGeneric />
      )}
      <h1 className="text-title font-lighter text-3xl mb-4 text-center sm:text-start">
        {title}
      </h1>
      <p className="text-text font-lighter text-lg mb-6 text-center sm:text-start">
        {subtitle}
      </p>
    </div>
  );
};

export default ErrorView;
