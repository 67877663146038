import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MediQuoAPI } from '../../api';

export const useJoinWaitingRoom = ({ appointment }) => {
  const { id, apiKey, jwt } = useParams();
  const [call, setCall] = useState(null);

  useEffect(() => {
    if (appointment && appointment.from) {
      MediQuoAPI.calls
        .get({ id, apiKey, callId: appointment.call_id, jwt })
        .then((data) => {
          setCall(data?.data?.data);
        });
    }
  }, [appointment]);

  return { call };
};
