import FinishedContentView from '../../../components/FinishedContentView';
import ArrowDropdown from '../../../assets/images/ArrowDropdown';
import dayjs from 'dayjs';
import HeaderView from '../../../components/HeaderView';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import TrackerSdk from '../../../utils/TrackingSdk';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../i18n/namespaces';

function Documentation({ state, data, documents, toggleChat }) {
  const { jwt, type } = useParams();
  const [expiration, setExpiration] = useState();
  const { t } = useTranslation(Namespace.Appointment);

  useEffect(() => {
    if (jwt) {
      setExpiration(jwt_decode(jwt).exp * 1000);
    }
  }, [jwt]);

  useEffect(() => {
    TrackerSdk.pageView('consultation summary pageview');
  }, []);

  return (
    <div className="bg-background max-h-screen h-screen">
      <div className="flex flex-col max-h-screen h-screen max-w-[800px] mx-auto">
        <div className="p-6">
          <HeaderView
            className="pb-2"
            title={t('documentation.title')}
            showLogo={type !== 'i'}
            centered
          />
          <div className="finished-chat-container">
            <div className="mt-3">
              <MeetingDetail
                title={data.appointment.from.name}
                subtitle={dayjs(data.appointment.start_date).format(
                  'DD/MM/YYYY',
                )}
              />
            </div>
            {data?.isWidgetReady && (
              <div
                className="finished-ver-chat-container cursor-pointer"
                onClick={() => {
                  TrackerSdk.event(
                    'consultation summary go to chat button click',
                  );
                  toggleChat(data.appointment.from.hash);
                }}
              >
                <p className="finished-ver-chat-text">
                  {t('documentation.see_chat')}
                </p>
                <ArrowDropdown />
              </div>
            )}
          </div>
        </div>
        <FinishedContentView
          appointment={data.appointment}
          documents={documents}
          state={state}
          expiration={expiration}
          date={dayjs(expiration).format('DD/MM/YY')}
          hour={dayjs(expiration).format('HH:mm')}
        />
      </div>
    </div>
  );
}

export default Documentation;

const MeetingDetail = ({ title, subtitle }) => (
  <div className="consultation-detail-patientData-container">
    <p className="text-text font-lighter leading-2 text-xs sm:text-start">
      {subtitle ? subtitle : '-'}
    </p>
    <p className="text-dark capitalize leading-4 font-medium text-sm text-center sm:text-start">
      {title}
    </p>
  </div>
);
