import SharedDocuments from '../assets/images/SharedDocuments';
import Button from './Button';
import { AppointmentStates, DocumentationStates } from '../utils/states';
import ArrowDownLoad from '../assets/images/ArrowDownLoad';
import { Document } from '../containers/Appointment/types';
import { download } from '../containers/Appointment/Documentation/actions';
import TrackerSdk from '../utils/TrackingSdk';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../i18n/namespaces';
import { ReactNode } from 'react';

function FinishedContentView({
  documents,
  appointment,
  state,
  date,
  hour,
  expiration,
}: any) {
  const { jwt } = useParams();
  const { t } = useTranslation(Namespace.Appointment);

  switch (state) {
    case AppointmentStates.reporting:
      return (
        <div>
          <DocumentationContainer>
            <DocumentationLabel
              title={t('documentation.documentation')}
              textClassName="mt-6 ml-6"
            />
            <DocumentationEmpty title={t('documentation.reporting')} />
          </DocumentationContainer>
          <div className="mx-6 m-auto flex bottom-0 mt-5">
            <Button
              onClick={() => {
                TrackerSdk.event('consultation summary update button click');
                window.location.reload();
              }}
            >
              {t('documentation.update')}
            </Button>
          </div>
        </div>
      );
    case DocumentationStates.empty:
      return (
        <DocumentationContainer>
          <DocumentationLabel
            title={t('documentation.documentation')}
            textClassName="finished-contentview-documentation-text mt-6 ml-6"
          />
          <DocumentationEmpty title={t('documentation.empty')} />
        </DocumentationContainer>
      );
    case DocumentationStates.completed:
      return (
        <DocumentationContainer>
          <DocumentationLabel
            title={t('documentation.documentation')}
            textClassName="finished-contentview-documentation-text pb-0 mt-6 ml-6"
          />
          {expiration ? (
            <p className="mt-4 text-gray-dark font-lighter text-sm px-8 sm:text-start">
              {t('documentation.available_until', { date, hour })}
            </p>
          ) : (
            <div />
          )}

          {documents.map((document: Document, key: any) => {
            return (
              <DownLoadDocumentation
                key={key}
                title={document.name}
                className={
                  key === 0 ? 'mt-4 cursor-pointer' : 'cursor-pointer mt-2'
                }
                onClick={() => {
                  TrackerSdk.event(
                    'consultation summary download document button click',
                  );
                  download({
                    id: appointment.id,
                    url: document.url,
                    name: document.name,
                    jwt,
                  });
                }}
              />
            );
          })}
        </DocumentationContainer>
      );

    default:
      return <div></div>;
  }
}

const DocumentationContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className="bg-bg flex flex-col min-h-[356px] p-6 mx-6 rounded-lg">
      {children}
    </div>
  );
};

const DocumentationLabel = ({ title }: any) => (
  <p className="text-xs text-dark font-medium uppercase">{title}</p>
);

const DocumentationEmpty = ({ title }: any) => (
  <div className="mt-4 md:p-4 grid place-items-center h-[276px]">
    <div className="space-y-6">
      <SharedDocuments className="m-auto" />
      <p className="text-text font-lighter mt-6 text-center px-4 md:px-10">
        {title}
      </p>
    </div>
  </div>
);

const DownLoadDocumentation = ({ title, onClick, className }: any) => (
  <div className={className}>
    <div className="finished-download-item-container m-auto">
      <div className="finished-download-item-text ml-4 mt-4">{title}</div>
      <div onClick={onClick}>
        <ArrowDownLoad />
      </div>
    </div>
  </div>
);

export default FinishedContentView;
