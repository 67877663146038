import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../i18n/namespaces';
import { AppointmentStates } from '../../../utils/states';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ready, unready, join, leave } from './actions';
import { useAppointment } from '../useAppointment';
import Loading from '../../../pages/Loading';
import classnames from 'classnames';
import HeaderView from '../../../components/HeaderView';
import dayjs from 'dayjs';
import PermissionStatusView from '../../../components/PermissionStatus';
import VideoCallContainer from '../../VideoCall';
import AppLogo from '../../../assets/images/AppLogo';
import EnableVideoCamAudio from '../../../assets/illustrations/EnableVideoCamAudio';
import SubtrackIcon from '../../../assets/images/SubtrackIcon';
import AvatarView from '../../../components/AvatarView';
import IconView from '../../../components/IconView';
import DetailLabelsStack from '../../../components/DetailLabelStack';
import TrackerCall from '../../../utils/TrackingCall';
import TrackerSdk from '../../../utils/TrackingSdk';
import { usePortal } from '../../../utils/usePortal';
import ErrorView from '../../VideoCall/ErrorView';
import { useVideoCall } from '../../VideoCall/useVideocall';
import { useJoinWaitingRoom } from '../useJoin';
import useListener from '../../../utils/useListener';
import { v4 as uuid } from 'uuid';

function WaitingContainer({ state, data }) {
  const { id, apiKey, type, jwt } = useParams();
  const [isInVideoCall, setIsInVideoCall] = useState(false);
  const controls = useVideoCall();
  const {
    appointment,
    hangUp,
    cancelVideoCall,
    extendVideoCall,
    immediateExpiresAt,
  } = useAppointment();
  const { call } = useJoinWaitingRoom({ appointment });
  const [errorView, setErrorView] = useState(false);
  const [publisherId, setPublisherId] = useState(uuid());

  const professionalHash = data.appointment?.from?.hash;

  function enterVideoCall() {
    leave({ id, professionalHash, apiKey, jwt });

    TrackerCall.event('access to call');

    return setIsInVideoCall(true);
  }

  function returnWaitingRoom() {
    handleJoinWaitingRoom();

    return setIsInVideoCall(false);
  }

  useEffect(() => {
    const handleTabClose = () => leave({ id, professionalHash, apiKey });
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  useEffect(() => {
    if (controls.isVideoEnabled && navigator?.userAgent?.includes('iPhone')) {
      setPublisherId(uuid());
    }
  }, [controls.isVideoEnabled]);

  const handleJoinWaitingRoom = () => {
    join({ id, professionalHash, apiKey, jwt }).then(() => {
      if (
        havePermissions(controls) &&
        state === AppointmentStates.waitingRoom
      ) {
        ready({ id, professionalHash, apiKey, jwt });
      } else if (
        !havePermissions(controls) &&
        state === AppointmentStates.waitingRoom
      ) {
        unready({ id, professionalHash, apiKey, jwt });
      }
    });
  };

  useListener({
    listener: 'message',
    dependencies: [],
    onEvent: ({ data: { command } }) => {
      switch (command) {
        case 'flutter_reload':
          window.location.reload();
          break;
        case 'flutter_toggle_video':
          //only for android toggle video while in call
          setPublisherId(uuid());
          controls.toggleVideo();
          setTimeout(() => {
            controls.toggleVideo();
          }, 3200);

          break;
        default:
          break;
      }
    },
  });

  if (!appointment || !call) {
    return <Loading />;
  }

  switch (state) {
    case AppointmentStates.notTimeYet:
      return <NotTimeYetView appointment={appointment} type={type} />;
    case AppointmentStates.waitingRoom:
      return havePermissions(controls) ? (
        <PermissionAcceptedView
          appointment={appointment}
          isInVideoCall={isInVideoCall}
          errorView={errorView}
          setErrorView={setErrorView}
          type={type}
        >
          <VideoCall
            controls={controls}
            appointment={appointment}
            call={call}
            hangUp={hangUp}
            isInVideoCall={isInVideoCall}
            handleJoinWaitingRoom={handleJoinWaitingRoom}
            setErrorView={setErrorView}
            enterVideoCall={enterVideoCall}
            returnWaitingRoom={returnWaitingRoom}
            appointmentType={type}
            cancelVideoCall={cancelVideoCall}
            extendVideoCall={extendVideoCall}
            immediateExpiresAt={immediateExpiresAt}
            publisherId={publisherId}
          />
        </PermissionAcceptedView>
      ) : (
        <PermissionNotAcceptedView />
      );
    default:
      break;
  }
}

function NotTimeYetView({ appointment, type }) {
  TrackerSdk.pageView('it is not yet time pageview');
  const navigate = useNavigate();
  const { t } = useTranslation(Namespace.Appointment);

  useEffect(() => {
    startTimer(appointment.seconds_to_open);

    return () => {
      stopTimer();
    };
  }, []);

  function startTimer(seconds) {
    let totalTime = seconds;
    let interval = setInterval(function () {
      totalTime--;
      if (totalTime <= 0) {
        stopTimer(interval);
      }
    }, 1000);
  }

  function stopTimer(interval) {
    interval && clearInterval(interval);
    navigate(0);
  }

  return (
    <div className="bg-background">
      <div className="relative max-h-screen h-screen p-6 grid max-w-[800px] mx-auto">
        <HeaderView
          title={t('waiting.not_time_yet.title')}
          subtitle={t('waiting.not_time_yet.subtitle')}
          isWithLineSeparator={true}
          showLogo={type !== 'i'}
        />
        <div className="consultation-detail-container">
          <div className="consultation-detail-title-container mb-4">
            <p className="text-left font-normal consultation-detail-title sm:text-start">
              {t('waiting.not_time_yet.detail')}
            </p>
            <SubtrackIcon />
          </div>
          <PatientDetailView
            imgSrc={appointment.from.avatar}
            title={t('waiting.not_time_yet.doctor')}
            subtitle={appointment.from.name}
            isWithAvatar={true}
          />
          <PatientDetailView
            imgSrc="/images/calendar_icon.png"
            title={t('waiting.not_time_yet.date')}
            subtitle={dayjs(appointment.start_date).format(
              'DD/MM/YYYY - HH:mm',
            )}
            isWithAvatar={false}
          />
        </div>
      </div>
    </div>
  );
}

const PatientDetailView = ({ imgSrc, title, subtitle, isWithAvatar }) => (
  <div className="consultation-detail-patient-detail-container mt-2">
    {isWithAvatar ? (
      <AvatarView imgSrc={imgSrc} />
    ) : (
      <IconView imgSrc={imgSrc} />
    )}
    <DetailLabelsStack
      title={title}
      subtitle={subtitle}
      subTitleClassName="text-left font-normal consultation-detail-title sm:text-start"
    />
  </div>
);

function PermissionAcceptedView({
  appointment,
  isInVideoCall,
  children,
  errorView,
  setErrorView,
  type,
}) {
  const { t } = useTranslation([Namespace.Appointment, Namespace.Error]);

  TrackerSdk.pageView('waiting room pageview');
  const portal = usePortal();
  useEffect(() => {
    if (errorView) {
      TrackerSdk.pageView(
        'waiting room publisher stream created error pageview',
      );
      portal.open(
        <ErrorView
          onConfirm={() => {
            TrackerSdk.event('waiting room refresh page button click');
            window.location.reload();
          }}
          isScreenBlocked
          title={t('error:videocall.connection.title')}
          subtitle={t('error:videocall.connection.subtitle')}
          button={t('error:videocall.connection.button')}
        />,
        'videocall-error',
      );
      setErrorView(false);
    }
  }, [errorView]);

  return (
    <div className="bg-background md:grid md:place-items-center min-h-screen">
      <div
        className={classnames('container max-w-[800px] flex flex-col', {
          'p-6 mx-auto': !isInVideoCall,
        })}
      >
        <HeaderView
          title={t('appointment:waiting.room.title')}
          subtitle={
            <span className="capitalize">
              {appointment.from.name}
              <br></br>
              {dayjs(appointment.start_date).format('DD/MM/YYYY - HH:mm[h]')}
            </span>
          }
          isWithLineSeparator={false}
          showLogo={type !== 'i'}
          centered
        />
        {children}
        <div className="h-20 md:h-0" />
        {!isInVideoCall && (
          <PermissionStatusView
            classes={{
              root: 'mt-7 fixed z-50 bottom-0 inset-x-0 md:relative',
              container: 'rounded-none rounded-t-lg md:rounded-lg',
            }}
          />
        )}
      </div>
    </div>
  );
}

function VideoCall({
  call,
  controls,
  hangUp,
  appointment,
  isInVideoCall,
  handleJoinWaitingRoom,
  enterVideoCall,
  setErrorView,
  returnWaitingRoom,
  appointmentType,
  cancelVideoCall,
  extendVideoCall,
  immediateExpiresAt,
  publisherId,
}) {
  return (
    <div
      className={classnames({
        'relative p-2 sm:p-4': !isInVideoCall,
      })}
    >
      <VideoCallContainer
        session={{ id: call.session_id, token: call.session_token }}
        controls={controls}
        onJoinWaitingRoom={handleJoinWaitingRoom}
        onHangUp={hangUp}
        professionalHash={appointment?.from.hash}
        professionalName={appointment?.from.name}
        isInVideoCall={isInVideoCall}
        setErrorView={setErrorView}
        enterVideoCall={enterVideoCall}
        returnWaitingRoom={returnWaitingRoom}
        appointmentType={appointmentType}
        startedAt={appointment?.started_at}
        cancelVideoCall={cancelVideoCall}
        extendVideoCall={extendVideoCall}
        isImmediateVideoCall={appointment?.is_immediate_video_call}
        immediateExpiresAt={immediateExpiresAt}
        publisherId={publisherId}
      />
    </div>
  );
}

function PermissionNotAcceptedView() {
  const { t } = useTranslation(Namespace.Appointment);

  useEffect(() => {
    TrackerSdk.pageView('no permissions accepted pageview');
    window.parent.postMessage({ command: 'videocall_permissions' }, '*');
  }, []);

  return (
    <div className="bg-background">
      <div className="relative max-h-screen h-screen p-6 grid max-w-[800px] mx-auto">
        <AppLogo />
        <div className="permission-denied-start-container">
          <EnableVideoCamAudio />
          <h1 className="text-title text-3xl flex text-center mb-4 font-lighter sm:text-start sm:mr-auto">
            {t('permissions.title')}
          </h1>
          <p className="text-text font-lighter text-sm text-center sm:text-start">
            {t('permissions.subtitle')}
          </p>
        </div>
      </div>
    </div>
  );
}

function havePermissions(controls) {
  return controls.isVideoPermissionEnabled && controls.isAudioPermissionEnabled;
}

export { WaitingContainer, PermissionNotAcceptedView };
