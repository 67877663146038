import { MediQuoAPI } from '../../../api/index';

export const download = ({ id, url, name, jwt }) => {
  return MediQuoAPI.documentations
    .download({ id, url, jwt })
    .then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name + '.pdf');
      document.body.appendChild(link);
      link.click();
    });
};
