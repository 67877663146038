export default (props: any) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35.6698 10.5C37.5943 7.16667 42.4056 7.16667 44.3301 10.5L75.6698 64.782C77.5943 68.1154 75.1887 72.282 71.3397 72.282H8.66023C4.81123 72.282 2.4056 68.1154 4.3301 64.782L35.6698 10.5Z"
      fill="#FED5E1"
    />
    <path
      d="M40.0023 37C41.1015 37 42.0008 37.8993 42.0008 38.9984V46.9922C42.0008 48.0913 41.1015 48.9906 40.0023 48.9906C38.9032 48.9906 38.0039 48.0913 38.0039 46.9922V38.9984C38.0039 37.8993 38.9032 37 40.0023 37ZM42.0008 56.9844H38.0039V52.9875H42.0008V56.9844Z"
      fill="#DB0545"
    />
  </svg>
);
