import Lottie from 'lottie-react';
import Spinner from './spinner_grey.json';

export default function Loading() {
  const style = {
    width: 64,
    height: 64,
  };

  return (
    <div className="grid h-screen place-items-center">
      <Lottie animationData={Spinner} style={style} />
    </div>
  );
}
