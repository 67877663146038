export default (props: any) => (
  <svg
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3181 2.40412L12.2491 2.40381L12.2463 2.40382C7.55551 2.47656 3.64658 5.58078 2.55018 10.1113C1.92151 12.6889 2.40754 15.4768 3.87731 17.7633C5.34527 20.0489 7.67749 21.6477 10.286 22.1487C10.956 22.2774 11.6287 22.3425 12.288 22.3425C13.872 22.3425 15.4052 21.9639 16.8373 21.2217C16.8934 21.1974 16.9334 21.1892 16.979 21.1892C17.0127 21.1892 17.0511 21.1926 17.099 21.2011L20.4812 22.0884C21.316 22.3066 22.0741 21.5396 21.8461 20.7074L21.2807 18.6501C21.1701 18.2517 21.0618 17.8649 20.9549 17.4863C20.938 17.4076 20.9348 17.3529 20.9408 17.3065C20.9469 17.2603 20.9632 17.2131 20.9981 17.1514L20.9992 17.1492C22.5255 14.33 22.6226 11.0604 21.2751 8.17129C19.9243 5.27422 17.3346 3.23918 14.1854 2.59762L14.1838 2.5973C13.612 2.48814 12.9753 2.42312 12.3181 2.40412ZM4.72909 10.6405C5.56409 7.19005 8.46512 4.81841 12.0185 4.65391L12.2857 4.64601C12.6328 4.65645 12.981 4.68401 13.3042 4.72628C13.4598 4.74663 13.6095 4.77039 13.7507 4.79729C16.1928 5.29516 18.1933 6.86776 19.243 9.11896C20.2484 11.2747 20.2198 13.6849 19.1728 15.802L19.093 15.956L19.0358 16.0664C18.6985 16.6647 18.6173 17.3111 18.7812 18.0347L19.1686 19.4276L17.5902 19.0144L17.5817 19.0128C17.3326 18.9642 17.1567 18.947 16.979 18.947C16.5945 18.947 16.2381 19.0309 15.858 19.205L15.8521 19.2081C14.6915 19.809 13.5106 20.1003 12.288 20.1003C11.7723 20.1003 11.2412 20.0489 10.7089 19.9467C8.70982 19.5628 6.90365 18.3261 5.7637 16.5512C4.62204 14.7751 4.24718 12.6162 4.72909 10.6405Z"
      fill="currentColor"
    />
  </svg>
);
