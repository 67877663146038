import HeaderView from '../../../components/HeaderView';
import Button from '../../../components/Button';
import FemaleIcon from '../../../assets/images/FemaleIcon';
import EditIconOutline from '../../../assets/images/EditIconOutline';
import LineSeparator from '../../../components/LineSeparator';
import DetailLabelStack from '../../../components/DetailLabelStack';
import { useNavigate, useParams } from 'react-router-dom';
import MaleIcon from '../../../assets/images/MaleIcon';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import TrackerSdk from '../../../utils/TrackingSdk';
import { MediQuoAPI } from '../../../api';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../i18n/namespaces';

function ProfileDetail({ profile, appointment }: any) {
  const { id, apiKey, jwt, type } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(Namespace.Appointment);

  const prefix = profile.phone_prefix ? profile.phone_prefix : '';
  const phone = profile.phone ? profile.phone : '';

  useEffect(() => {
    TrackerSdk.pageView('hello pageview');
  }, []);

  const handleConfirm = () => {
    TrackerSdk.event('hello confirm button click');
    if (appointment.is_immediate_video_call && id && apiKey && jwt) {
      return MediQuoAPI.consultations.confirm({ id, apiKey, jwt }).then(() => {
        navigate(`/${type}/${id}/${apiKey}/${jwt}/waiting`);
        window.parent.postMessage({ command: 'videocall_waiting' }, '*');
      });
    }
    navigate(`/${type}/${id}/${apiKey}/${jwt}/waiting`);
  };

  return (
    <div className="bg-background pt-3">
      <div className="relative max-h-screen h-screen p-6 pt-0 grid max-w-[800px] mx-auto">
        <div className="flex flex-col justify-between gap-5">
          <HeaderView
            title={t('profile_details.title', { name: getFullName(profile) })}
            subtitle={t('profile_details.subtitle')}
            withLanguageSelector
            isWithLineSeparator={false}
            showLogo={type !== 'i'}
          />
          <div className="bg-bg p-6 rounded-xl">
            <div className="flex flex-row w-full justify-between">
              <NameAndGenderView
                name={getFullName(profile)}
                gender={profile.gender}
              />
              <button
                onClick={() => {
                  TrackerSdk.event('hello edit button click');
                  navigate(
                    `/${type}/${id}/${apiKey}/${jwt}/profile/${profile.hash}/verify`,
                  );
                  window.parent.postMessage(
                    { command: 'videocall_profile_form' },
                    '*',
                  );
                }}
                className="flex flex-row h-fit text-grak-dark gap-1 justify-center border border-gray-dark p-1 rounded-lg"
              >
                <EditIconOutline className="text-gray-dark w-3" />
                <p className="text-gray-dark text-[10px] leading-2.5 font-normal sm:text-start uppercase">
                  {t('profile_details.edit')}
                </p>
              </button>
            </div>
            <div className="mt-6">
              <LineSeparator />
            </div>
            <div className="mt-6">
              <DetailLabelStack
                title={t('profile_details.dni')}
                subtitle={profile.tax_id ? profile.tax_id : '-'}
              />
            </div>
            <div className="mt-4">
              <DetailLabelStack
                title={t('profile_details.birth_date')}
                subtitle={
                  profile.birth_date
                    ? dayjs(profile.birth_date).format('DD/MM/YYYY')
                    : '-'
                }
              />
            </div>
            <div className="mt-4">
              <DetailLabelStack
                title={t('profile_details.email')}
                subtitle={profile.email ? profile.email : '-'}
              />
            </div>
            <div className="mt-4">
              <DetailLabelStack
                title={t('profile_details.phone')}
                subtitle={`${prefix && '+' + prefix} ${phone}`}
              />
            </div>
          </div>
          <div className="my-6 flex-grow">
            <Button onClick={handleConfirm}>
              {t('profile_details.confirm')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function getFullName(profile: any): string {
  return `${getFirstName(profile.first_name)} ${getLastName(
    profile.last_name,
  )}`;
}

function getLastName(lastName: string): string {
  return lastName ? lastName : '';
}

function getFirstName(firstName: string): string {
  return firstName ? firstName : '';
}

const NameAndGenderView = ({ name, gender }: any) => {
  const { t } = useTranslation(Namespace.Appointment);

  return (
    <div>
      <p className="text-left font-medium text-dark sm:text-start tracking-wide">
        {name}
      </p>
      <div className="flex flex-row gap-1 text-sm center items-center">
        {gender === 'male' ? (
          <MaleIcon />
        ) : (
          <FemaleIcon className="text-dark" />
        )}
        <p className="text-left font-lighter sm:text-start">
          {t(`profile_details.${gender}`)}
        </p>
      </div>
    </div>
  );
};

export default ProfileDetail;
