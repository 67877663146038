import { useEffect, useState } from 'react';
import useWidget from './useWidget';
import TrackerCall from '../../utils/TrackingCall';
import TrackerSdk from '../../utils/TrackingSdk';

export const useVideoCall = () => {
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoPermissionEnabled, setIsVideoPermissionEnabled] =
    useState(true);
  const [isAudioPermissionEnabled, setIsAudioPermissionEnabled] =
    useState(true);
  const { isPendingMessage, toggleChat } = useWidget();

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then((stream) => {
        setTimeout(() => {
          setIsAudioPermissionEnabled(stream.active);
          setIsVideoPermissionEnabled(stream.active);
          TrackerSdk.event('microphone and camera permissions accepted');
        }, 2000);
      })
      .catch(() => {
        setIsAudioPermissionEnabled(false);
        setIsVideoPermissionEnabled(false);
        TrackerSdk.event('microphone and camera permissions declined');
      });
  }, []);

  const toggleVideo = () =>
    setIsVideoEnabled((prev) => {
      if (prev) {
        TrackerCall.event('toggle off video');
      }

      if (!prev) {
        TrackerCall.event('toggle on video');
      }

      return !prev;
    });
  const toggleAudio = () =>
    setIsAudioEnabled((prev) => {
      if (prev) {
        TrackerCall.event('toggle off audio');
      }

      if (!prev) {
        TrackerCall.event('toggle on audio');
      }

      return !prev;
    });

  return {
    isVideoPermissionEnabled,
    isAudioPermissionEnabled,
    isVideoEnabled,
    isAudioEnabled,
    isPendingMessage,
    toggleChat,
    toggleAudio,
    toggleVideo,
  };
};
