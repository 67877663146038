import { AppointmentStates } from './states';
import { useAppointment } from '../containers/Appointment/useAppointment';

export default function getAppointmentState(data: any) {
  const { error }: { error: any } = useAppointment();

  if (error) {
    const status =
      error.status === '400'
        ? 'errorNotAvailable'
        : error.status === '401'
          ? 'errorExpired'
          : 'errorGeneric';
    return AppointmentStates[status];
  }

  if (data.appointment && data.appointment?.status.includes('declined')) {
    return AppointmentStates.declined;
  }
  if (data.appointment && !data.appointment?.from) {
    return AppointmentStates.triage;
  }

  if (!data.appointment || !data.appointment.call_id) {
    return AppointmentStates.loading;
  }

  const appointment = data.appointment;

  const secondsToOpen = appointment.seconds_to_open;
  if (
    [AppointmentStates.accepted, AppointmentStates.pending].includes(
      appointment.status,
    ) &&
    secondsToOpen > 0
  ) {
    return AppointmentStates.notTimeYet;
  }

  if (
    [AppointmentStates.accepted, AppointmentStates.pending].includes(
      appointment.status,
    ) &&
    secondsToOpen === 0
  ) {
    return AppointmentStates.waitingRoom;
  }

  if (appointment.status === AppointmentStates.finished) {
    return AppointmentStates.finished;
  }

  if (appointment.status === AppointmentStates.reporting) {
    return AppointmentStates.reporting;
  }

  if (appointment.status === AppointmentStates.expired) {
    return AppointmentStates.expired;
  }

  if (appointment.status === AppointmentStates.cancelled) {
    return AppointmentStates.cancelled;
  }
  if (appointment.status === AppointmentStates.declined) {
    return AppointmentStates.declined;
  }
}
