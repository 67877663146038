const DetailLabelsStack = ({ title, subtitle }: any) => (
  <div>
    <p className="text-text text-start text-xs text-gray-dark uppercase leading-3 font-lighter text-center sm:text-start">
      {title}
    </p>
    <p className="text-left font-lighter text-sm sm:text-start">
      {subtitle ? subtitle : '-'}
    </p>
  </div>
);

export default DetailLabelsStack;
