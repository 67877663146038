export default (props: any) => (
  <svg
    width="144"
    height="139"
    viewBox="0 0 144 139"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="144" height="139" rx="11" fill="#ECEFF1" />
    <mask
      id="mask0_3688_36368"
      maskUnits="userSpaceOnUse"
      x="11"
      y="11"
      width="122"
      height="117"
    >
      <rect x="11" y="11" width="122" height="117" rx="6" fill="white" />
    </mask>
    <g mask="url(#mask0_3688_36368)">
      <rect x="11" y="11" width="122" height="117" rx="6" fill="white" />
      <line x1="22.5" y1="11" x2="22.5" y2="128" stroke="#F5F8FA" />
      <line x1="33.5" y1="11" x2="33.5" y2="128" stroke="#F5F8FA" />
      <line x1="44.5" y1="11" x2="44.5" y2="128" stroke="#F5F8FA" />
      <line x1="55.5" y1="11" x2="55.5" y2="128" stroke="#F5F8FA" />
      <line x1="66.5" y1="11" x2="66.5" y2="128" stroke="#F5F8FA" />
      <line x1="77.5" y1="11" x2="77.5" y2="128" stroke="#F5F8FA" />
      <line x1="88.5" y1="11" x2="88.5" y2="128" stroke="#F5F8FA" />
      <line x1="99.5" y1="11" x2="99.5" y2="128" stroke="#F5F8FA" />
      <line x1="110.5" y1="11" x2="110.5" y2="128" stroke="#F5F8FA" />
      <line x1="121.5" y1="11" x2="121.5" y2="128" stroke="#F5F8FA" />
      <path
        d="M9.5 77L16 32L21.5 100L29 55.5L34.5 77L38 69.5L42 77L51 32L56.5 100L63.5 57.5L66.5 69.5H135.5"
        stroke="url(#paint0_linear_3688_36368)"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <rect x="109" y="15" width="21" height="17" rx="4" fill="white" />
      <path
        d="M115.993 27.1176C117.757 27.1176 118.894 25.4908 118.894 23.57V23.5504C118.894 21.6296 117.777 20.0224 116.013 20.0224C114.249 20.0224 113.102 21.6492 113.102 23.57V23.5896C113.102 25.5104 114.219 27.1176 115.993 27.1176ZM116.013 26.412C114.729 26.412 113.906 25.0694 113.906 23.57V23.5504C113.906 22.0412 114.709 20.728 115.993 20.728C117.267 20.728 118.09 22.0706 118.09 23.57V23.5896C118.09 25.089 117.297 26.412 116.013 26.412ZM122.999 27.1176C124.763 27.1176 125.899 25.4908 125.899 23.57V23.5504C125.899 21.6296 124.782 20.0224 123.018 20.0224C121.254 20.0224 120.108 21.6492 120.108 23.57V23.5896C120.108 25.5104 121.225 27.1176 122.999 27.1176ZM123.018 26.412C121.734 26.412 120.911 25.0694 120.911 23.57V23.5504C120.911 22.0412 121.715 20.728 122.999 20.728C124.273 20.728 125.096 22.0706 125.096 23.57V23.5896C125.096 25.089 124.302 26.412 123.018 26.412Z"
        fill="#B5C2C9"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3688_36368"
        x1="72"
        y1="66"
        x2="5"
        y2="66"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#90A4AE" />
        <stop offset="1" stopColor="#90A4AE" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
