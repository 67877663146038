import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MediQuoAPI } from '../../api';
import { config } from '../../config';
import { getSession } from '../../utils/useSession';
import { useTranslation } from 'react-i18next';

export const useConsultation = () => {
  const navigate = useNavigate();
  const { id, apiKey, jwt, type } = useParams();
  const [data, setData] = useState();
  const { i18n } = useTranslation();

  const getConsultation = (accessToken) =>
    MediQuoAPI.consultations
      .get({ id, apiKey, jwt })
      .then(({ data }) => {
        setData(data.data);
        window.MediquoWidget.init({
          apiKey: apiKey,
          theme: {
            locale: i18n.language,
            launcher: 'hidden',
          },
          blockNavigation: true,
          accessToken: accessToken,
          environment:
            config.app.env === 'production' ? 'production' : 'sandbox',
        });
      })
      .catch((err) => console.log(err));

  const onMessage = ({ data }) => {
    if (
      [
        'consultation_assigned',
        'consultation_skipped',
        'consultation_finished',
      ].includes(data.command)
    ) {
      setData((prev) => ({
        ...prev,
        consultation: {
          ...prev.consultation,
          professional_hash: data.detail.professional_hash,
          room_id: data.detail.room_id,
          status: data.detail.status,
        },
      }));
    }
    if (
      ['consultation_skipped', 'consultation_finished'].includes(data.command)
    ) {
      window.MediquoWidget.destroy();
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);
  }, []);

  useEffect(() => {
    getConsultation(getSession({ id }));
  }, [id]);

  const retry = () => {
    MediQuoAPI.consultations.retry({ id, apiKey, jwt }).then(() => navigate(0));
  };
  const hangUp = () => {
    if (!'i'.includes(type)) {
      console.log('destroy');
      window.MediquoWidget.destroy();
    }
    setData({
      ...data,
      consultation: { ...data.consultation, status: 'finished' },
    });
  };

  return {
    data,
    retry,
    hangUp,
  };
};
