export default (props: any) => (
  <svg
    width="328"
    height="221"
    viewBox="0 0 328 221"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="328"
      height="219.942"
      transform="translate(0 0.529053)"
      fill="white"
    />
    <rect
      x="56.6948"
      y="110.5"
      width="151.752"
      height="151.752"
      transform="rotate(-45 56.6948 110.5)"
      fill="#ECEFF1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M194.624 59.6745L184.738 76.0466C184.31 75.7878 183.876 75.5369 183.437 75.294L192.704 58.5633C193.351 58.922 193.992 59.2925 194.624 59.6745ZM165.114 51.1804L164.762 70.3025C164.508 70.2979 164.255 70.2955 164 70.2955C163.745 70.2955 163.491 70.2979 163.238 70.3025L162.886 51.1804C163.257 51.1736 163.628 51.1702 164 51.1702C164.372 51.1702 164.743 51.1736 165.114 51.1804ZM135.296 58.5633L144.563 75.294C144.124 75.5369 143.69 75.7878 143.262 76.0466L133.376 59.6745C134.008 59.2925 134.649 58.922 135.296 58.5633ZM113.174 79.8758L129.547 89.7618C129.288 90.1904 129.037 90.624 128.794 91.0625L112.063 81.7962C112.422 81.1486 112.793 80.5083 113.174 79.8758ZM104.68 109.386C104.674 109.757 104.67 110.128 104.67 110.5C104.67 110.872 104.674 111.243 104.68 111.614L123.803 111.262C123.798 111.008 123.796 110.755 123.796 110.5C123.796 110.245 123.798 109.991 123.803 109.738L104.68 109.386ZM162.886 169.82L163.238 150.697C163.491 150.702 163.745 150.704 164 150.704C164.255 150.704 164.508 150.702 164.762 150.697L165.114 169.82C164.743 169.826 164.372 169.83 164 169.83C163.628 169.83 163.257 169.826 162.886 169.82ZM192.704 162.437L183.437 145.706C183.876 145.463 184.31 145.212 184.738 144.953L194.624 161.325C193.992 161.707 193.351 162.078 192.704 162.437ZM214.825 141.124L198.453 131.238C198.712 130.81 198.963 130.376 199.206 129.937L215.937 139.204C215.578 139.851 215.207 140.492 214.825 141.124Z"
      fill="#90A4AE"
    />
    <path
      d="M152.592 116.139C149.195 114.186 145.161 113.662 141.378 114.682C137.594 115.703 134.371 118.184 132.417 121.581L125.047 134.39C123.093 137.788 122.568 141.822 123.589 145.607C124.61 149.391 127.092 152.614 130.49 154.569C133.887 156.523 137.922 157.047 141.706 156.026C145.49 155.005 148.714 152.523 150.668 149.126L154.352 142.721"
      stroke="#B9C6CD"
      strokeWidth="6.76695"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="111.662"
      y="180.327"
      width="44.3683"
      height="11.0523"
      rx="5.52616"
      transform="rotate(-65.6676 111.662 180.327)"
      stroke="#B9C6CD"
      strokeWidth="6.69388"
    />
    <rect
      x="-0.88813"
      y="4.64922"
      width="44.3683"
      height="29.5936"
      rx="14.7968"
      transform="matrix(-0.827226 0.56187 0.56187 0.827226 171.067 83.9095)"
      stroke="#90A4AE"
      strokeWidth="6.69388"
    />
    <path
      d="M174.561 103.65C175.566 107.438 178.034 110.671 181.422 112.64C184.81 114.608 188.842 115.15 192.63 114.147L206.915 110.362C210.704 109.358 213.938 106.89 215.907 103.501C217.876 100.112 218.417 96.0792 217.413 92.2906C216.409 88.502 213.941 85.2675 210.551 83.2988C207.162 81.33 203.13 80.7882 199.341 81.7925L192.2 83.6856"
      stroke="#B9C6CD"
      strokeWidth="6.76695"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.358284"
      y="4.71971"
      width="44.3683"
      height="11.0523"
      rx="5.52616"
      transform="matrix(-0.651554 0.758602 0.758602 0.651554 228.281 52.3725)"
      stroke="#B9C6CD"
      strokeWidth="6.69388"
    />
    <path
      d="M73.6514 169.464C73.8696 168.923 74.5242 168.815 74.8515 169.356L76.5972 172.062L79.1066 170.005C79.5431 169.681 80.1977 170.005 80.1977 170.547V173.794L83.3618 173.469C83.9073 173.361 84.3437 174.011 84.0164 174.444L82.2707 177.15L85.2166 178.557C85.7621 178.774 85.653 179.531 85.2166 179.748L82.2707 181.047L83.9073 183.753C84.2346 184.186 83.7982 184.836 83.2527 184.728L80.0886 184.295L79.9795 187.434C79.9795 187.975 79.3249 188.3 78.8884 187.867L77.0336 186.243C76.7063 186.027 76.2699 186.027 76.0517 186.351L74.6333 188.408C74.306 188.841 73.6514 188.733 73.4332 188.192L72.5603 185.161L69.6144 186.46C69.0689 186.676 68.5234 186.243 68.7416 185.702L69.7235 182.671L66.5595 182.129C66.014 182.021 65.7957 181.372 66.2322 181.047L68.6325 178.99L66.2322 176.825C65.7957 176.5 66.014 175.743 66.5595 175.743L69.7235 175.31L68.8507 172.17C68.7416 171.629 69.2871 171.196 69.7235 171.413L72.6694 172.712L73.6514 169.464Z"
      fill="#ECEFF1"
    />
    <circle cx="216" cy="13" r="5" fill="#ECEFF1" />
    <path
      d="M92.1367 204.328L90.3501 209.139C90.265 209.308 90.18 209.392 90.0098 209.477L86.3517 210.49C85.9263 210.659 85.8412 211.249 86.3517 211.418L90.3501 212.938C90.5203 213.022 90.6053 213.106 90.6053 213.191L92.3068 217.918C92.477 218.34 93.1576 218.34 93.2426 217.833L94.3486 212.853C94.3486 212.684 94.5188 212.515 94.6889 212.515L98.4321 211.249C98.8575 211.081 98.8575 210.405 98.4321 210.321L94.859 209.308C94.6889 209.224 94.6038 209.139 94.5187 208.97L92.9874 204.244C92.9874 203.906 92.3068 203.906 92.1367 204.328Z"
      fill="#ECEFF1"
    />
  </svg>
);
