import BrowserContainer from '../../../containers/Appointment/Browser';
import { isSafari, isChrome, isChromium } from 'react-device-detect';
import { useEffect, useState } from 'react';
import TrackerSdk from '../../../utils/TrackingSdk';
import { useParams } from 'react-router-dom';

const Browser = ({ children }: any) => {
  const [isAcceptedContinue, setIsAcceptedContinue] = useState(false);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  useEffect(() => {
    TrackerSdk.pageView('improve your experience pageview');
  }, []);

  if (useValidatedBrowser(isAcceptedContinue)) {
    return children;
  }

  return (
    <BrowserContainer
      isIOS={isIOS}
      onClick={() => {
        TrackerSdk.event('continue in this browser button click');
        setIsAcceptedContinue(true);
      }}
    />
  );
};

function useValidatedBrowser(isAcceptedContinue: boolean): boolean {
  const { type } = useParams();
  return (
    isChrome ||
    isChromium ||
    isSafari ||
    isAcceptedContinue ||
    [type].includes('i')
  );
}

export default Browser;
