import {usePatient} from "../../../containers/Appointment/usePatient";
import Loading from "../../Loading";
import ProfileForm from "../../../containers/Appointment/ProfileForm";
import { useTranslation } from "react-i18next";
import { Namespace } from "../../../i18n/namespaces";

const ProfileVerify = () => {
  const {profile, onUpdate} = usePatient()
  const { t } = useTranslation(Namespace.Appointment)
  
  if (!profile) {
    return <Loading />;
  }

  return <ProfileForm profile={profile} onUpdate={onUpdate} title={t('profile_details.verify')}  isUpdate={true}/>
}

export default ProfileVerify;
