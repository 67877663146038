export default (props: any) => (
  <svg
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.9196 6.66663C20.0403 6.66663 21.7596 8.45749 21.7596 10.6666V12.176L23.5949 10.1824C24.5107 9.18703 26.0125 9.05753 27.079 9.84284L27.2526 9.98221C27.8255 10.483 28.1596 11.2123 28.1596 11.9855V20.0144C28.1596 21.4997 26.9623 22.6666 25.5264 22.6666C24.7965 22.6666 24.0955 22.3616 23.5949 21.8175L21.7596 19.8226V21.3333C21.7596 23.5424 20.0403 25.3333 17.9196 25.3333H6.39957C4.2788 25.3333 2.55957 23.5424 2.55957 21.3333V10.6666C2.55957 8.45749 4.2788 6.66663 6.39957 6.66663H17.9196ZM17.9196 9.33329H6.39957C5.74314 9.33329 5.20212 9.84801 5.12818 10.5111L5.11957 10.6666V21.3333C5.11957 22.0171 5.6137 22.5806 6.2503 22.6577L6.39957 22.6666H17.9196C18.576 22.6666 19.117 22.1519 19.191 21.4888L19.1996 21.3333V10.6666C19.1996 9.98284 18.7054 9.41928 18.0688 9.34226L17.9196 9.33329ZM25.5684 12.0054C25.5237 11.9931 25.4665 12.0022 25.4431 12.0276L21.7877 15.9999L25.4431 19.9724C25.4516 19.9816 25.4685 19.9908 25.4903 19.9959L25.5264 19.9999C25.5851 19.9999 25.5996 19.9859 25.5996 20.0144V12.0213L25.5684 12.0054Z"
      fill="currentColor"
    />
  </svg>
);
