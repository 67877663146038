import { useTranslation } from 'react-i18next';
import AppLogo from '../../../assets/images/AppLogo';
import BrokenChain from '../../../assets/images/BrokenChain';
import { Namespace } from '../../../i18n/namespaces';
import Button from '../../../components/Button';
import useWidget from '../../../containers/VideoCall/useWidget';
import { useParams } from 'react-router-dom';

function DeclinedView() {
  const { t } = useTranslation(Namespace.Appointment);
  const { type } = useParams();

  return (
    <div className="bg-background max-h-screen h-screen p-6 grid max-w-[800px] mx-auto">
      <div className="relative grid place-items-center">
        <DeclinedViewContainer
          includeLogo={type && !['i'].includes(type)}
          title={t('declined.title')}
          subtitle={t('declined.subtitle')}
          type={type}
        />
      </div>
    </div>
  );
}

const DeclinedViewContainer = ({ title, subtitle, type, includeLogo }: any) => {
  const { exitVideoCall } = useWidget();

  return (
    <div className="space-y-10">
      {includeLogo && <AppLogo />}
      <div className="flex flex-col gap-6 items-center">
        <BrokenChain />
        <h1 className="text-title font-lighter text-3xl mb-4 text-center">
          {title}
        </h1>
        <p className="text-text font-lighter text-lg mb-6 text-center">
          {subtitle}
        </p>

        {['i'].includes(type) && (
          <Button onClick={() => exitVideoCall()} className="w-full mb-4">
            Salir
          </Button>
        )}
      </div>
    </div>
  );
};

export default DeclinedView;
