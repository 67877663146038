import { AppointmentStates, DocumentationStates } from './states';

export default function getDocumentationState(documents: any) {
  if (!documents) {
    return AppointmentStates.loading;
  }

  if (documents.length === 0) {
    return DocumentationStates.empty;
  }

  if (documents.length !== 0) {
    return DocumentationStates.completed;
  }
}
