import {
  preloadScript,
  createSession,
  OTPublisher,
  OTSubscriber,
} from 'opentok-react';
import { config } from '../../config';
import '../../assets/styles/meet.css';
import CallControls from '../../components/CallControls';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import useTimer from './useTimer';
import TrackerCall from '../../utils/TrackingCall';
import ErrorView from './ErrorView';
import { usePortal } from '../../utils/usePortal';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../i18n/namespaces';
import '../../assets/styles/meet.css';

dayjs.extend(relativeTime);

const VideoCall = ({
  session,
  controls,
  onJoinWaitingRoom,
  onHangUp,
  professionalHash,
  professionalName,
  startedAt,
  isInVideoCall,
  setErrorView,
  enterVideoCall,
  returnWaitingRoom,
  appointmentType,
  cancelVideoCall,
  extendVideoCall,
  isImmediateVideoCall,
  immediateExpiresAt,
  publisherId,
}) => {
  const [streams, setStreams] = useState([]);
  const [opentok, setOpentok] = useState();
  const seconds = useTimer(startedAt ?? 0);
  const otPublisherRef = useRef();
  const [isStreamCreated, setStreamCreated] = useState();
  const [isPresent, setIsPresent] = useState();
  const [disconnectionView, setDisconnectionView] = useState();
  const portal = usePortal();
  const [professionalTimeout, setProfessionalTimeout] = useState(false);
  const { t } = useTranslation(Namespace.Error);

  useEffect(() => {
    const opentokSession = createSession({
      apiKey: config.services.opentok.api_key,
      sessionId: session.id,
      token: session.token,
      onStreamsUpdated: (streams) => {
        setStreams(streams);
        setIsPresent(true);
      },
    });

    const ses = opentokSession.session;
    setOpentok(ses);

    ses.on('sessionConnected', (event) => {
      onJoinWaitingRoom();
      TrackerCall.debug('session connected', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
        }),
      });
    });
    ses.on('sessionDisconnected', (event) => {
      TrackerCall.debug('session disconnected', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
        }),
      });
    });
    ses.on('sessionReconnected', (event) => {
      TrackerCall.debug('session reconnected', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
        }),
      });
    });
    ses.on('sessionReconnecting', (event) => {
      TrackerCall.debug('session reconnecting', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
        }),
      });
    });
    ses.on('streamCreated', (event) => {
      TrackerCall.debug('stream created', {
        content: JSON.stringify({
          event: event,
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
          streamHasAudio: event.stream.hasAudio,
          streamHasVideo: event.stream.hasVideo,
          streamVideoType: event.stream.videoType,
        }),
      });
    });
    ses.on('connectionDestroyed', (event) => {
      TrackerCall.debug('connection destroyed', {
        content: JSON.stringify({
          event: event,
          sessionId: event.target.sessionId,
        }),
      });

      setDisconnectionView(true);
    });
    ses.on('streamDestroyed', (event) => {
      TrackerCall.debug('stream destroyed', {
        content: JSON.stringify({
          sessionId: event.target.sessionId,
          currentState: event.target.currentState,
          previousState: event.target.previousState,
          streamReason: event.reason,
          streamHasAudio: event.stream.hasAudio,
          streamHasVideo: event.stream.hasVideo,
          streamVideoType: event.stream.videoType,
        }),
      });

      onHangUp();
      setStreams([]);
      setIsPresent(false);
    });

    return () => opentokSession.disconnect();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isStreamCreated) {
        setErrorView(true);
        clearInterval(interval);
      }
    }, 15 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isStreamCreated]);

  useEffect(() => {
    const professionalAnswer =
      isImmediateVideoCall &&
      setInterval(() => {
        const expiration = isImmediateVideoCall && dayjs(immediateExpiresAt);
        const actualTime = dayjs(new Date());
        const diff = expiration.diff(actualTime, 'second');
        if (diff < 120 && !isPresent && !professionalTimeout)
          setProfessionalTimeout(true);
      }, 30 * 1000);
    if (isPresent) {
      clearInterval(professionalAnswer);
    }

    return () => {
      clearInterval(professionalAnswer);
    };
  }, [immediateExpiresAt]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPresent) {
        enterVideoCall();
        clearInterval(interval);
        setProfessionalTimeout(false);
        portal.close('videocall-professional-unavailable');
      }
    }, 3 * 1000);

    return () => clearInterval(interval);
  }, [isPresent]);

  useEffect(() => {
    if (['i'].includes(appointmentType)) {
      if (!isInVideoCall) {
        window.parent.postMessage({ command: 'videocall_waiting' }, '*');
      } else {
        window.parent.postMessage(
          {
            command: 'videocall_joined',
            payload: { hash: professionalHash, name: professionalName },
          },
          '*',
        );
      }
    }
  }, [isInVideoCall]);

  useEffect(() => {
    if (disconnectionView) {
      TrackerCall.pageView('videocall lost connection pageview');
      portal.open(
        <ErrorView
          onConfirm={() => {
            TrackerCall.event('videocall lost connection button click');
            returnWaitingRoom();
            portal.close();
          }}
          isScreenBlocked
          title={t('videocall.professional_disconnected.title')}
          subtitle={t('videocall.professional_disconnected.subtitle')}
          button={t('videocall.professional_disconnected.button')}
        />,
        'videocall-professional-disconnection',
      );
      setDisconnectionView(false);
    }
  }, [disconnectionView]);

  useEffect(() => {
    if (!isPresent && professionalTimeout) {
      portal.open(
        <ErrorView
          onConfirm={() => {
            extendVideoCall();
            portal.close('videocall-professional-unavailable');
          }}
          isScreenBlocked
          title={t('videocall.professional_no_answer.title')}
          subtitle={t('videocall.professional_no_answer.subtitle')}
          button={t('videocall.professional_no_answer.button')}
          buttonCancel={t('videocall.professional_no_answer.buttonCancel')}
          type="waiting"
          onCancel={() => {
            portal.close('videocall-professional-unavailable');
            cancelVideoCall();
          }}
        />,
        'videocall-professional-unavailable',
      );
      setDisconnectionView(false);
      setProfessionalTimeout(false);
    }
  }, [professionalTimeout]);

  if (!opentok) {
    return null;
  }

  const hours = Math.floor(seconds / 3600);
  const timeLeft = seconds % 3600;
  let minutes = Math.floor(timeLeft / 60).toString();
  minutes = minutes.padStart(2, '0');
  let secondsLeft = (timeLeft % 60).toString();
  secondsLeft = secondsLeft.padStart(2, '0');
  const time = hours
    ? `${hours}:${minutes}:${secondsLeft}`
    : `${minutes}:${secondsLeft}`;

  const toggleChat = () => {
    if (['i'].includes(appointmentType)) {
      window.parent.postMessage({ command: 'videocall_show_chat' }, '*');
    } else {
      controls.toggleChat(professionalHash);
    }
  };

  return (
    <div
      className={classNames('aspect-auto', {
        vc_finished: streams.length === 0,
        'h-96': !isInVideoCall,
      })}
    >
      <OTPublisher
        key={publisherId}
        session={opentok}
        properties={{
          facingMode: 'user',
          audioFallbackEnabled: false,
          showControls: false,
          fitMode: 'contain',
          publishAudio: controls.isAudioEnabled,
          publishVideo: controls.isVideoEnabled,
        }}
        ref={otPublisherRef}
        eventHandlers={{
          streamCreated: () => setStreamCreated(true),
        }}
      />

      {streams.map((stream) => (
        <OTSubscriber
          key={stream.id}
          session={opentok}
          stream={stream}
          properties={{ showControls: false, fitMode: 'contain' }}
        />
      ))}
      {isInVideoCall && (
        <span className="absolute bg-black/50 w-16 h-8 flex justify-center items-center center text-[13px] rounded-xl capitalize text-white z-10 m-auto top-6 right-4">
          {time}
        </span>
      )}
      <CallControls
        isAudioEnabled={controls.isAudioEnabled}
        isVideoEnabled={controls.isVideoEnabled}
        isPendingMessage={controls.isPendingMessage}
        onAudioToggle={controls.toggleAudio}
        onVideoToggle={controls.toggleVideo}
        onChatToggle={toggleChat}
        isInVideoCall={isInVideoCall}
        isStreamReady={isStreamCreated}
      />
      {isInVideoCall && (
        <span className="absolute w-full left-0 flex justify-center capitalize text-white z-10 m-auto bottom-6">
          {professionalName}
        </span>
      )}
    </div>
  );
};

export default preloadScript(VideoCall);
