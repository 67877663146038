import Expired from '../../containers/Consultation/Expired';
import Finished from '../../containers/Consultation/Finished';
import { useConsultation } from '../../containers/Consultation/useConsultation';
import Waiting from '../../containers/Consultation/Waiting';
import VideoCall from '../../containers/VideoCall';
import { useVideoCall } from '../../containers/VideoCall/useVideocall';

function Consultation() {
  const { data, retry, hangUp } = useConsultation();
  const controls = useVideoCall();

  if (!data) {
    return null;
  }

  if (
    data.consultation.finished_at ||
    data.consultation.status === 'finished'
  ) {
    return <Finished />;
  }

  if (data.consultation.status === 'skipped') {
    return <Expired retry={retry} />;
  }

  if (data.consultation.status === 'enqueued') {
    return <Waiting controls={controls} />;
  }

  return (
    <div className="relative max-h-screen h-screen p-2 sm:p-4 bg-background">
      <VideoCall
        professionalHash={data.consultation.professional_hash}
        session={data.session}
        controls={controls}
        onHangUp={hangUp}
      />
    </div>
  );
}

export default Consultation;
