export class PatientStates {
  static loading = 'loading';
  static withoutAllData = 'withoutAllData';
  static authenticated = 'authenticated';
}

export class AppointmentStates {
  // Back States
  static loading = 'loading';
  static pending = 'pending';
  static accepted = 'accepted';
  static expired = 'expired';
  static finished = 'finished';
  static cancelled = 'cancelled';
  static declined = 'declined';
  static reporting = 'reporting';

  // Front States
  static notTimeYet = 'notTimeYet';
  static waitingRoom = 'waitingRoom';
  static reconnect = 'reconnect';
  static inCall = 'inCall';
  static triage = 'triage';

  // Error States
  static errorGeneric = 'generic';
  static errorNotAvailable = 'notAvailable';
  static errorExpired = 'expired';
}

export class DocumentationStates {
  static loading = 'loading';
  static empty = 'empty';
  static completed = 'completed';
}

export class ErrorStates {
  static notAvailable = 'notAvailable';
  static generic = 'generic';
  static expired = 'expired';
}
