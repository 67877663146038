import { useParams } from 'react-router-dom';
import Login from '../../pages/Login';
import { hasSession } from '../../utils/useSession';
const Authorized = ({ children, type }: any) => {
  const { id } = useParams();

  if (hasSession({ id: id! })) {
    return children;
  }

  return <Login type={type} />;
};

export default Authorized;
