import * as Sentry from '@sentry/browser';
import { config } from '../config';

export function startErrorLog() {
  if (config.services.sentry.dsn) {
    Sentry.init({
      environment: config.services.sentry.environment,
      dsn: config.services.sentry.dsn,
    });
  }

  window.onerror = (message, file, line, column, errorObject) => {
    console.error(message, file, line, errorObject);

    if (config.services.sentry.dsn) {
      Sentry.captureException(errorObject);
    }
  };
}
