export const config = {
  app: {
    env: import.meta.env.VITE_APP_ENV,
  },
  services: {
    mediquo: {
      sdk_api_url: import.meta.env.VITE_SDK_API_URL,
      widget_url: import.meta.env.VITE_WIDGET_URL,
      tracking_sdk_url: import.meta.env.VITE_TRACKING_SDK_URL,
      tracking_call_url: import.meta.env.VITE_TRACKING_CALL_URL,
      chat_api_url: import.meta.env.VITE_CHAT_API_URL,
    },
    opentok: {
      api_key: import.meta.env.VITE_OPENTOK_API_KEY,
    },
    sentry: {
      environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    },
  },
};
