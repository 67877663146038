export default (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8C0 3.58 3.58 0 8 0C12.42 0 16 3.58 16 8C16 12.42 12.42 16 8 16C3.58 16 0 12.42 0 8ZM7.9974 13C8.5474 13 8.9974 12.55 8.9974 12V8C8.9974 7.45 8.5474 7 7.9974 7C7.4474 7 6.9974 7.45 6.9974 8V12C6.9974 12.55 7.4474 13 7.9974 13ZM8.9974 3H6.9974V5H8.9974V3Z"
      fill="currentColor"
    />
  </svg>
);
