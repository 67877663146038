import { useParams } from 'react-router-dom';

export const useIsInApp = () => {
  const { type } = useParams();

  if (!type) {
    return false;
  }

  return ['i'].includes(type);
};
