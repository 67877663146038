import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { createSession } from '../../utils/useSession';
import { MediQuoAPI } from '../../api/index';

export const usePatient = () => {
  const { id, apiKey, jwt } = useParams();
  const [profile, setProfile] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    setError(null);
    MediQuoAPI.autoLogin({ apiKey, jwt, id }).then((response) => {
      if (response.status !== 200) {
        return setError(response);
      }
      setProfile(response?.data?.data);
      createSession({ id, accessToken: jwt });
    });
  }, []);

  function onUpdate({ formData, closure }) {
    MediQuoAPI.updateProfile({ apiKey, jwt, formData })
      .then(() => setProfile(formData))
      .then(closure);
  }

  return { profile, onUpdate, error };
};
