export default (props: any) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2355 2.70194L9.29706 0.763496C9.04406 0.525857 8.71254 0.389504 8.36556 0.380373C8.01859 0.371242 7.68036 0.489971 7.41521 0.713974L1.04808 7.08111C0.819404 7.31172 0.67702 7.61397 0.644827 7.93713L0.34062 10.8872C0.33109 10.9909 0.344535 11.0953 0.379998 11.1931C0.415461 11.291 0.472067 11.3798 0.545783 11.4532C0.611888 11.5188 0.690286 11.5707 0.776481 11.6059C0.862676 11.6411 0.954973 11.6589 1.04808 11.6584H1.11175L4.06186 11.3895C4.38502 11.3573 4.68727 11.215 4.91788 10.9863L11.285 4.61915C11.5321 4.35807 11.6657 4.00968 11.6564 3.65031C11.6471 3.29094 11.4958 2.9499 11.2355 2.70194ZM3.93451 9.97462L1.81214 10.1727L2.00315 8.05033L6.00029 4.1027L7.91043 6.01285L3.93451 9.97462ZM8.83013 5.06485L6.93414 3.16886L8.31369 1.75394L10.2451 3.6853L8.83013 5.06485Z"
        fill="#546E7A"
      />
    </svg>
  );
};
