import { useTranslation } from 'react-i18next';
import PermissionStatusAcceptedIcon from '../assets/images/PermissionStatusAcceptedIcon';
import { Namespace } from '../i18n/namespaces';
import { cn } from '../utils/cn';

export interface PermissionStatusProps {
  classes?: Partial<{
    root: string;
    container: string;
  }>;
}

const PermissionStatusView = ({ classes }: PermissionStatusProps) => {
  const { t } = useTranslation(Namespace.Appointment);

  return (
    <div className={cn('flex justify-center relative', classes?.root)}>
      <div className="h-10 w-10 absolute -top-5 bg-[#eceefd] padding-10 center flex items-center justify-center rounded-full">
        <PermissionStatusAcceptedIcon className="text-[#3C50EC]" />
      </div>
      <div
        className={cn(
          'flex text-sm bg-[#eceefd] items-center justify-center h-11 w-full rounded-lg',
          classes?.container,
        )}
      >
        <p className="text-[#3C50EC] z-20 text-center sm:text-start">
          {t('permissions.status_accepted')}
        </p>
      </div>
    </div>
  );
};

export default PermissionStatusView;
