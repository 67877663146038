import { MediQuoAPI } from '../../../api/index';

export const join = ({ id, professionalHash, apiKey, jwt }) => {
  return MediQuoAPI.waiting.joinWaitingRoom({
    id,
    professionalHash,
    apiKey,
    jwt,
  });
};
export const ready = ({ id, professionalHash, apiKey, jwt }) => {
  return MediQuoAPI.waiting.joinerReadyWaitingRoom({
    id,
    professionalHash,
    apiKey,
    jwt,
  });
};
export const unready = ({ id, professionalHash, apiKey, jwt }) => {
  return MediQuoAPI.waiting.joinerUnreadyWaitingRoom({
    id,
    professionalHash,
    apiKey,
    jwt,
  });
};
export const leave = ({ id, professionalHash, apiKey, jwt }) => {
  return MediQuoAPI.waiting.leaveWaitingRoom({
    id,
    professionalHash,
    apiKey,
    jwt,
  });
};
